import './Emulator.css';
import './Contacts.css'
import prevPointEmulator from '../images/prevPont.png'
import nextPointEmulator from '../images/nextPoint.png'
import {useEffect, useRef, useState} from "react";
import Input from "./Input";
import EmulatorButton from "./EmulatorButton";
import React from "react";
import {emulatorImage} from '../form_image.js'

function Emulator({create}) {
    let points = [
        {
            name: 'Выберите файл',
            inputs: [
                {
                    id: 'file_input',
                    style: {
                        width: 650,
                        height: 40
                    },
                    name: 'Обзор:',
                    class: 'smallInp',
                    type: 'file'
                }, {
                    id: 'gainr_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Receiver amplification (dB):',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'elevation_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Elevation:',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'gaint_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Transmitter amplification (dB):',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'frequency_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Frequency:',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'distance_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Distance:',
                    class: 'smallInp',
                    type: 'text'
                }
            ]
        },
        {
            name: '',
            inputs: [
                {
                    id: 'rate_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Symbol rate:',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'error_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Error correcting code:',
                    class: 'smallInp',
                    type: 'select',
                    options: ["Nothing", "Reed-Solomone"]
                }, {
                    id: 'code_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Modulation type:',
                    class: 'smallInp',
                    type: 'select',
                    options: ["QPSK", "BPSK", "16QAM", "GMSK"]
                }, {
                    id: 'polarization_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Polarization angle:',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'rain_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Rain rate (mm/hour):',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'transmitter_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Transmitter power:',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'bandwidth_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Bandwidth:',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'noise_input',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'System noise:',
                    class: 'smallInp',
                    type: 'text'
                }
            ]
        }
    ]

    let [currentPoint, setCurrentPoint] = useState(0)
    let [validateSuccess, setValidateSuccess] = useState(false)

    let [fileInput, setFileInput] = useState(0)
    let [gainrInput, setGainrInput] = useState("62")
    let [elevationInput, setElevationInput] = useState("30")
    let [gaintInput, setGaintInput] = useState("30")
    let [frequencyInput, setFrequencyInput] = useState("433e6")
    let [distanceInput, setDistanceInput] = useState("6e5")
    let [rateInput, setRateInput] = useState("28800")
    let [errorInput, setErrorInput] = useState("nothing")
    let [codeInput, setCodeInput] = useState("qpsk")
    let [polarizationInput, setPolarizationInput] = useState("45")
    let [rainInput, setRainInput] = useState("1")
    let [transmitterInput, setTransmitterInput] = useState("1e-3")
    let [bandwidthInput, setBandwidthInput] = useState("4e6")
    let [noiseInput, setNoiseInput] = useState("9e-8")

    let onFieldChange = (event, id) => {

        if (id != 'error_input' && id != 'code_input' && id != 'file_input')
            if (event.target.value) {
                document.querySelector(`#${id}`).classList.remove('input-bg-error')
                document.querySelector(`#Input${id}`).classList.remove('input-bg-error')
            } else {
                document.querySelector(`#${id}`).classList.add('input-bg-error')
                document.querySelector(`#Input${id}`).classList.add('input-error')
                document.querySelector(`#Input${id}`).classList.add('input-bg-error')
                document.querySelector(`#Input${id}`).style.marginBottom = '0px'

                document.querySelector(`#error_text${id}`).style.display = 'block'
            }

        if (id == "file_input") {
            if (event.target.files && event.target.files[0]) {
                setFileInput(event.target.files[0])
                //console.log(fileInput)
                document.querySelector("#Inputfile_input").classList.remove('input-error')
                document.querySelector("#Inputfile_input").classList.remove('input-bg-error')
            }
        }
        if (id == 'error_input')
            setErrorInput(event.target.value)
        if (id == 'code_input')
            setCodeInput(event.target.value)
        if (id == "gainr_input")
            setGainrInput(event.target.value)
        if (id == "elevation_input")
            setElevationInput(event.target.value)
        if (id == "gaint_input")
            setGaintInput(event.target.value)
        if (id == "frequency_input")
            setFrequencyInput(event.target.value)
        if (id == "distance_input")
            setDistanceInput(event.target.value)
        if (id == "rate_input")
            setRateInput(event.target.value)
        if (id == "polarization_input")
            setPolarizationInput(event.target.value)
        if (id == "rain_input")
            setRainInput(event.target.value)
        if (id == "transmitter_input")
            setTransmitterInput(event.target.value)
        if (id == "bandwidth_input")
            setBandwidthInput(event.target.value)
        if (id == "noise_input")
            setNoiseInput(event.target.value)

        validate()
    }

    let validate = () => {
        if (fileInput && gainrInput && elevationInput && gaintInput && frequencyInput && distanceInput && rateInput && errorInput &&
            codeInput && polarizationInput && rainInput && transmitterInput && bandwidthInput && noiseInput)
            setValidateSuccess(true)
        else
            setValidateSuccess(false)
    }

    let [circles, setCircles] = useState(['activeSwitchCircle', 'switchCircle'])

    let curCarousel = useRef()

    useEffect(() => {
        if (!currentPoint) {
            document.querySelector('.wrap').classList.add('wrap1')
            document.querySelector('.wrap').classList.remove('wrap2')
        } else {
            document.querySelector('.wrap').classList.add('wrap2')
            document.querySelector('.wrap').classList.remove('wrap1')
        }

        if (document.querySelector(`#error_texterror_input`))
            document.querySelector(`#error_texterror_input`).style.display = 'none'
        if (document.querySelector(`#error_textcode_input`))
            document.querySelector(`#error_textcode_input`).style.display = 'none'
        if (document.querySelector(`#error_textfile_input`))
            document.querySelector(`#error_textfile_input`).style.display = 'none'

        Array.from(document.getElementsByClassName('smallInputs')).map((i) => {
            document.querySelector(`#error_text${i.id}`).style.display = 'none'
            if (!(i.value)) {
                document.querySelector(`#${i.id}`).classList.add('input-bg-error')
                document.querySelector(`#Input${i.id}`).classList.add('input-error')
                document.querySelector(`#Input${i.id}`).classList.add('input-bg-error')
                document.querySelector(`#Input${i.id}`).style.marginBottom = '0px'

                document.querySelector(`#error_text${i.id}`).style.display = 'block'
            }
        });
    }, [currentPoint])

    useEffect(() => {
        validate()
        if (validateSuccess)
            emulatorImage(currentPoint, fileInput, parseFloat(gainrInput), parseFloat(elevationInput), parseFloat(gaintInput), parseFloat(frequencyInput), parseFloat(distanceInput), parseFloat(rateInput), errorInput,
                codeInput, parseFloat(polarizationInput), parseFloat(rainInput), parseFloat(transmitterInput), parseFloat(bandwidthInput), parseFloat(noiseInput))

    }, [currentPoint, fileInput, gainrInput, elevationInput, gaintInput, frequencyInput, distanceInput, rateInput, errorInput,
        codeInput, polarizationInput, rainInput, transmitterInput, bandwidthInput, noiseInput])

    let switchPoint = (i) => {
        //console.log(i, '42313241')
        let circ = ['switchCircle', 'switchCircle']
        if (i == 2) {
            circ[0] = 'activeSwitchCircle'
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0.35s'
                curCarousel.current.style.transform = 'translate(-100%, 0)'
                curCarousel.current.style.opacity = 0
            }, 0)
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0s'
                curCarousel.current.style.transform = 'translate(100%, 0)'
                setCircles(circ)
                setCurrentPoint(1)
                setCurrentPoint(0)
            }, 350)
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0.35s'
                curCarousel.current.style.transform = 'translate(0, 0)'
                curCarousel.current.style.transform = 'unset'
                curCarousel.current.style.opacity = 1
                //console.log(points[currentPoint], currentPoint)
            }, 400)
        }
        if (i == -1) {
            circ[1] = 'activeSwitchCircle'
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0.35s'
                curCarousel.current.style.transform = 'translate(100%, 0)'
                curCarousel.current.style.opacity = 0
            }, 0)
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0s'
                curCarousel.current.style.transform = 'translate(-100%, 0)'
                setCurrentPoint(1)
                setCircles(circ)
            }, 350)
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0.35s'
                curCarousel.current.style.transform = 'translate(0, 0)'
                curCarousel.current.style.transform = 'unset'
                curCarousel.current.style.opacity = 1
            }, 400)
        }
        if (i != 2 && i != -1) {
            circ[i] = 'activeSwitchCircle'
            if (i > currentPoint) {
                setTimeout(() => {
                    curCarousel.current.style.transitionDuration = '0.35s'
                    curCarousel.current.style.transform = 'translate(-100%, 0)'
                    curCarousel.current.style.opacity = 0
                }, 0)
                setTimeout(() => {
                    curCarousel.current.style.transitionDuration = '0s'
                    curCarousel.current.style.transform = 'translate(100%, 0)'
                    setCurrentPoint(i)
                    setCircles(circ)
                }, 350)
                setTimeout(() => {
                    curCarousel.current.style.transitionDuration = '0.35s'
                    curCarousel.current.style.transform = 'translate(0, 0)'
                    curCarousel.current.style.transform = 'unset'
                    curCarousel.current.style.opacity = 1
                }, 400)
            } else {
                if (i < currentPoint) {
                    setTimeout(() => {
                        curCarousel.current.style.transitionDuration = '0.35s'
                        curCarousel.current.style.transform = 'translate(100%, 0)'
                        curCarousel.current.style.opacity = 0
                    }, 0)
                    setTimeout(() => {
                        curCarousel.current.style.transitionDuration = '0s'
                        curCarousel.current.style.transform = 'translate(-100%, 0)'
                        setCurrentPoint(i)
                        setCircles(circ)
                    }, 350)
                    setTimeout(() => {
                        curCarousel.current.style.transitionDuration = '0.35s'
                        curCarousel.current.style.transform = 'translate(0, 0)'
                        curCarousel.current.style.transform = 'unset'
                        curCarousel.current.style.opacity = 1
                    }, 400)
                }
            }
        }
    }

    let swipeInfo = {
        x: '',
        y: '',
        time: ''
    }

    let onTouchStart = (e) => {
        swipeInfo.x = e.touches[0].screenX
        swipeInfo.y = e.touches[0].screenY
        swipeInfo.time = e.timeStamp
        //console.log(e)
    }

    let onTouchEnd = (e) => {
        let x = swipeInfo.x - e.changedTouches[0].screenX
        let y = Math.abs(swipeInfo.y - e.changedTouches[0].screenY) * 2
        let ti = e.timeStamp - swipeInfo.time
        //console.log(e)
        if (x > 50 && x > y && ti < 500) {
            switchPoint(currentPoint + 1)
        } else if (x < -50 && -x > y && ti < 500) {
            switchPoint(currentPoint - 1)
        }
        //console.log(x, y, ti)
    }

    return (
        <div className="Emulator">
            <p className="ContactsText1">ЭМУЛЯТОР</p>
            <p className="ContactsText2">Позволяет наглядно рассмотреть воздействие различных параметров сигнала и среды
                его распространения на изображение, а также влияние восстанавливающего кодирования на результат</p>
            <div className="carousel">
                <div className="prevPointEmulator">
                    <img onClick={() => {
                        switchPoint(currentPoint - 1)
                    }} src={prevPointEmulator} alt=""/>
                </div>
                <div onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} className="EmulatorPoint">
                    <div style={{width: '100%'}} ref={curCarousel}>
                        <p className="emulatorPointText">{points[currentPoint].name}</p>
                        <div id="wrap" className="wrap">
                            {
                                points[currentPoint].inputs.map((el, i) => (
                                    <div key={`div${el.id}`} id={`div${el.id}`} className="divInput">
                                        <Input change={onFieldChange} id={el.id}
                                               fileInput={fileInput} gainrInput={gainrInput}
                                               elevationInput={elevationInput} gaintInput={gaintInput}
                                               frequencyInput={frequencyInput} distanceInput={distanceInput}
                                               rateInput={rateInput} errorInput={errorInput}
                                               codeInput={codeInput} polarizationInput={polarizationInput}
                                               rainInput={rainInput} transmitterInput={transmitterInput}
                                               bandwidthInput={bandwidthInput} noiseInput={noiseInput}
                                               key={el.id} type={el.type} style={el.style} classN={el.class}
                                               name={el.name} options={el.options}> </Input>
                                        <p key={`p${el.id}`} id={`error_text${el.id}`} className={"error_text"}> Поле
                                            обязательно для заполнения</p>
                                    </div>
                                ))}
                        </div>
                        {validateSuccess
                            ? <EmulatorButton curr={currentPoint} create={create}
                                              switchPoint={() => switchPoint(currentPoint + 1)}> </EmulatorButton>
                            : <EmulatorButton curr={currentPoint}
                                              create={() => {
                                                  if (!(fileInput && gainrInput && elevationInput && gaintInput && frequencyInput && distanceInput)
                                                      && (rateInput && errorInput && codeInput && polarizationInput && rainInput && transmitterInput && bandwidthInput && noiseInput)) {
                                                      switchPoint(0)
                                                      setTimeout(() => {
                                                          if (fileInput) {

                                                              document.querySelector("#Inputfile_input").classList.remove('input-error')

                                                              document.querySelector("#Inputfile_input").classList.remove('input-bg-error')
                                                          } else {

                                                              document.querySelector("#Inputfile_input").classList.add('input-error')

                                                              document.querySelector("#Inputfile_input").classList.add('input-bg-error')
                                                          }
                                                      }, 500)
                                                  }
                                                  //console.log('Error, not all fields at second point are filled')
                                              }}
                                              switchPoint={() => {
                                                  if (!(rateInput && errorInput && codeInput && polarizationInput && rainInput && transmitterInput && bandwidthInput && noiseInput)
                                                      && (fileInput && gainrInput && elevationInput && gaintInput && frequencyInput && distanceInput))
                                                      switchPoint(1)
                                                  if (fileInput) {

                                                      document.querySelector("#Inputfile_input").classList.remove('input-error')

                                                      document.querySelector("#Inputfile_input").classList.remove('input-bg-error')
                                                  } else {
                                                      document.querySelector("#Inputfile_input").classList.add('input-error')

                                                      document.querySelector("#Inputfile_input").classList.add('input-bg-error')
                                                  }
                                                  //console.log('Error, not all fields st first point are filled')
                                              }}></EmulatorButton>
                        }
                    </div>
                    <div className="switchCirclesEmulator">
                        <div onClick={() => switchPoint(0)} key={0} className={circles[0]}></div>
                        <div onClick={() => switchPoint(1)} key={1} className={circles[1]}></div>
                    </div>
                </div>
                <div className="nextPointEmulator">
                    <img onClick={() => switchPoint(currentPoint + 1)} src={nextPointEmulator} alt=""/>
                </div>
            </div>
        </div>
    );
}

export default Emulator;
