import loader from './images/loader.gif'

export let emulatorImage = (curr, file_input, gainr_input, elevation_input, gaint_input, frequency_input, distance_input, rate_input, error_input,
    code_input, polarization_input, rain_input, transmitter_input, bandwidth_input, noise_input
) => {
    const { InformationPackage } = require('../../static/js/proto/inverter_pb.js')
    const { InverterClient } = require('../../static/js/proto/inverter_grpc_web_pb.js');
    const { isNull, now, floor, times } = require('lodash');

    let Inverter = new InverterClient('/api/emulator/')

    if (curr == 1) {
        document.getElementById("b64").onclick = function () {
            var file = file_input;
		if (file && file_input && gainr_input && elevation_input && gaint_input && frequency_input && distance_input && rate_input && error_input &&  code_input && polarization_input && rain_input && transmitter_input && bandwidth_input && noise_input
) {
            var reader = new FileReader();

	    
            var image1 = document.getElementById('image1');
	    
            image1.className = 'gif_img'

            image1.setAttribute("src", loader);
            setTimeout(() => {
	    image1.style.display = 'none'
            image1.className = 'modal-image'

	    image1.setAttribute("src", URL.createObjectURL(file));
            setTimeout(() => {
                image1.style.display = 'block'
            }, 1)
            }, 1)
            
            //console.log(URL.createObjectURL(file))
	    var image2 = document.getElementById('image2');

            image2.className = 'gif_img'
	    
            image2.setAttribute("src", loader);
            if (error_input == "Nothing") error_input = "nothing";
            else if (error_input == "Reed-Solomone") error_input = "reed-solomon";
            ///////////////////////////////Input//////////////////////////////////////////

            // callback for readAsDataURL
            reader.onload = function (encodedFile) {
                var format = encodedFile.srcElement.result.split(",")[0];
                //console.log(format);
                var base64Image = encodedFile.srcElement.result.split(",")[1];
                let sentImageToServer = new InformationPackage();
                //console.log(encodedFile.srcElement.result)

                //////////////////////////////To Server///////////////////////////////////////
                sentImageToServer.setValue(base64Image);
                sentImageToServer.setFrequency(frequency_input + 0.0);

                sentImageToServer.setBandwidth(bandwidth_input + 0.0);
                sentImageToServer.setSystemNoise(noise_input);
                sentImageToServer.setSymbolRate(rate_input);
                sentImageToServer.setReceiverGain(gainr_input);
                sentImageToServer.setTransmitterGain(gaint_input);
                sentImageToServer.setTransmitterPower(transmitter_input);
                sentImageToServer.setRainRate(rain_input);
                sentImageToServer.setElevation(elevation_input);
                sentImageToServer.setDistance(distance_input);
                sentImageToServer.setPolarizationAngle(polarization_input);

                sentImageToServer.setCode(code_input);
                sentImageToServer.setErrorCorrectingCode(error_input);
                //////////////////////////////To Server///////////////////////////////////////
                
                /*console.log(
                    {
                        "freq" : sentImageToServer.getFrequency(),
                        "bw" : sentImageToServer.getBandwidth(),
                        "sn" : sentImageToServer.getSystemNoise(),
                        "sr" : sentImageToServer.getSymbolRate(),
                        "rg" : sentImageToServer.getReceiverGain(),
                        "tg" : sentImageToServer.getTransmitterGain(),
                        "tp" : sentImageToServer.getTransmitterPower(),
                        "rr" : sentImageToServer.getRainRate(),
                        "e" : sentImageToServer.getElevation(),
                        "d" : sentImageToServer.getDistance(),
                        "pa" : sentImageToServer.getPolarizationAngle(),
                        "c" : sentImageToServer.getCode(),
                        "ecc" : sentImageToServer.getErrorCorrectingCode()
                    }

                );*/


                Inverter.invert(sentImageToServer, {}, function (err, response) {
                    if (err) { console.error(err); return; }
                    let image_res = response.getValue();
                    showImage(format + "," + image_res);
                    // DATA_TO_DECODE = encodedFile.srcElement.result.split("data:image/jpeg;base64,")[0]+"data:image/jpeg;base64,"+image_res;
                });


            };
            reader.readAsDataURL(file);
        };
	}

    //decoder
    function b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    function showImage(ImageURL) {
        var image2 = document.getElementById('image2');
        image2.style.display = 'none'
        image2.className = 'modal-image'
        image2.setAttribute("src", ImageURL);
        setTimeout(() => {
            image2.style.display = 'block'
        }, 1)
    }
}
}
