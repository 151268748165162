import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

var THREE = window.THREE = require('three');
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import * as dat from 'dat.gui'

export let startupForVsvs = () => {

    let earthTexture = require('../../static/3d/textures/earth albedo.jpg')
    let hullGltf = require('../../static/3d/hull.gltf')
    let hull2Gltf = require('../../static/3d/SXC-SSS-03_1663093610.gltf')
    let skyboxTexture1 = require('../../static/3d/textures/skybox_true2ft.jpeg')
    let skyboxTexture2 = require('../../static/3d/textures/skybox_true2bk.jpeg')
    let skyboxTexture3 = require('../../static/3d/textures/skybox_true2up.jpeg')
    let skyboxTexture4 = require('../../static/3d/textures/skybox_true2dn.jpeg')
    let skyboxTexture5 = require('../../static/3d/textures/skybox_true2rt.jpeg')
    let skyboxTexture6 = require('../../static/3d/textures/skybox_true2lf.jpeg')
//console.log(earthTexture, hullGltf)
    let check2 = true
    var exitProgress = true

    var wasLoadedSpace = false


    class Queue {
        //some function that returns an array of n frames, 480, 10, 100, 1, 240, 120
        constructor(frame_source_func, basic_n, basic_dt, dt_max, dt_min, soft_limit, hard_limit) {
            this.frame_source_func = frame_source_func;
            this.basic_n = basic_n;
            this.n = this.basic_n;
            this.basic_dt = basic_dt;
            this.dt = basic_dt;
            this.dt_max = dt_max;
            this.dt_min = dt_min;
            this.soft_limit = soft_limit;
            this.hard_limit = hard_limit;
            this.at_limit = false;
            this.frames = [];
            this.flag = true;
            /*try {
                this.frames = frame_source_func(basic_n);
            } catch (error) {
                this.frames = null;
                throw error;
            }*/

        }

        check() {
            if (this.at_limit) return false;
            if (this.frames.length < this.hard_limit) {
                this.at_limit = true;
                this.n += this.dt;
                this.soft_limit += this.dt;
                this.dt *= 2;
                if (this.dt > this.dt_max) this.dt = this.dt_max;
                return false;
            }
            return this.frames.length >= this.soft_limit;
        }

        async _fetch() {
            let data = await this.frame_source_func(this.n)
            //.then(data => {
            this.frames.push(...data);
            this.flag = true;
            //console.log('закончен fetching')
            if (!this.at_limit) {
                this.n -= this.dt;
                this.soft_limit -= this.dt;
                this.dt /= 2;
                if (this.dt < this.dt_min) this.dt = this.dt_min;
            }
            this.at_limit = false;
            //})
            //.catch(err => console.error(err));
            //this.frames.push(...this.frame_source_func(this.n));

            return this.frames
        }

        async get() {
            if (!this.check()) {
                if (this.flag) {
                    this.flag = false;
                    if (this.frames.length > 0)
                        this._fetch();
                    else {
                        await this._fetch();
                    }
                }
            }
            if (this.frames.length == 1) {
                return this.frames[0];
            }
            //console.log(' flag ', this.flag, ' n ', this.n, ' dt ', this.dt, ' dt_max ', this.dt_max, ' dt_min ', this.dt_min, ' soft_limit ', this.soft_limit, ' hard_limit ', this.hard_limit, ' at_limit ', this.at_limit, ' frames.length ', this.frames.length)
            return this.frames.shift();
        }

    }


    const {Configuration} = require(/*! ./proto/assembly_pb.js */ "../../static/js/proto/assembly_pb.js")

    const {SGPConstructRequest} = require('../../static/js/proto/sgp_service_pb.js');

    const {AdaptableQueue} = require('../../static/js/adaptable_queue.js');

    const {
        Parameters,
        ComputationRequest
    } = require('../../static/js/proto/aggregator_pb.js');
    const {AggregatorServiceClient} = require('../../static/js/proto/aggregator_grpc_web_pb.js');
//const {get_config} = require("./vsvs_config_react.js");

    const {isNull, now, floor, times, toInteger} = require('lodash');

    class GEO {
        constructor(lat, lon, alt, time) {
            this.lat = lat;
            this.lon = lon;
            this.alt = alt;
            this.time = time;
        }

        toString() {
            return "COORDS:\tLatitude: " + (this.lat * 180 / Math.PI).toString()
                + ";\t Longitude: " + (this.lon * 180 / Math.PI).toString()
                + "\tAltitude: " + this.alt.toString();
        }
    }

    class Vector {
        constructor(x, y, z) {
            this.x = x;
            this.y = y;
            this.z = z;
        }

        toString() {
            return "X: " + this.x.toString() + "\tY: " + this.y.toString()
                + "\tZ: " + this.z.toString();
        }
    }

    class ECI {
        constructor(pos, vel, time) {
            this.pos = pos;
            this.time = time;
            this.vel = vel;
        }

        toString() {
            return "COORDS:\n\tPOSITION: " + this.pos.toString()
                + "\n\tVELOCITY: " + this.vel.toString()
                + "\n\tTTIME: " + this.time.toString();
        }
    }

    let AggregatorService = new AggregatorServiceClient('/api/aggregator/')
    let id = null;

    var load_percent = 0;
    window.PROGRESS = load_percent
    var is_loaded = () => (load_percent == 230);
    var get_load_percent = () => load_percent;

    /**
     *
     * Let us assert the following:
     * 1) Everything is loaded at load_percent==100
     * 2) Getting id counts for 10
     * 3) Getting initial frames - another 10
     * 4) Resource loading is the other 80
     */

    function requestConstruction(first_line, second_line, start_time, dt, config, code, callback) {
        let sgp_request = new SGPConstructRequest();
        //console.log(config)
        if (document.querySelector('.vsvsTables').innerText != "0\n1\n2\n3\n4\n\n\n\n\n\n5\n6\n7\n8\n9\n\n\n\n\n\n10\n11\n12\n13\n14\n\n\n\n\n")
            sgp_request.setTitle(config.getName());
        else
            sgp_request.setTitle('');
        sgp_request.setFirst(first_line);
        sgp_request.setSecond(second_line);
        let request = new Parameters();
        request.setOrbit(sgp_request);
        request.setStartTime(start_time);
        request.setDt(dt);
        //console.log(config);
        request.setConfig(config);
        request.setCode(code);
        //console.log(request)
        AggregatorService.construct(request, {}, function (err, response) {
            //...
            //console.log(response);
            if (err) {
                //console.error(err);
                //
                return;
            }
            if (response.getCompilationReport() != "OK") {
                document.getElementById("report").innerHTML = response.getCompilationReport();
                return;
            }
            //console.warn("Computational id is: " + response.getComputationalId());
            id = response.getComputationalId();
            load_percent += 10;
            funcPlusProgress()
            callback(response.getComputationalId());
        });
    }


    const TicksPerDay = 86400000000;
    const TicksPerHour = 3600000000;
    const TicksPerMinute = 60000000;
    const TicksPerSecond = 1000000;
    const TicksPerMillisecond = 1000;
    const TicksPerMicrosecond = 1;

    const UnixEpoch = 62135596800000000;

    const MaxValueTicks = 315537897599999999;

// 1582-Oct-15
    const GregorianStart = 49916304000000000;

    const EarthRadius = 6378.135;

    function Now() {
        return UnixEpoch + (new Date()).getTime() * TicksPerMillisecond;
    }

    function time2uint64(time) {
        return UnixEpoch + time.getTime() * TicksPerMillisecond;
    }


    function Mod(x, y) {
        if (y == 0.0) return x;
        return x - y * floor(x / y);
    }

    function WrapTwoPi(val) {
        return Mod(val, 2 * Math.PI);
    }

    function ToGreenwichMeanSiderealTime(time) {
        let julian = 1721425.5 + time / TicksPerDay;
        let jd0 = Math.floor(julian + 0.5) - 0.5;
        let t = (jd0 - 2451545.0) / 36525.0;
        let jdf = julian - jd0;

        let gt = 24110.54841 + t * (8640184.812866 + t * (0.093104 - t * 6.2E-6));
        gt += jdf * 1.00273790935 * 86400.0;

        // 360.0 / 86400.0 = 1.0 / 240.0
        return WrapTwoPi(Math.PI / 180 * (gt / 240.0));
    }

    function ToLocalMeanSiderealTime(time, longitude) {
        return WrapTwoPi(ToGreenwichMeanSiderealTime(time) + lon);
    }


    function decodeTime(encoded) {
        return new Date((encoded - UnixEpoch) / TicksPerMillisecond);
    }

    const zip = (a, b) => a.map((k, i) => [k, b[i]]);

    function requestComputation(id, n, callback) {
        let request = new ComputationRequest();
        request.setFrameAmount(toInteger(n));
        request.setComputationalId(id + "");
        //console.log(id + " " + n);
        AggregatorService.compute(request, {}, (err, response) => {
            //...
            //console.log(response);
            if (err) {
                //console.log(err);
                return;
            }

            callback(response.getFramesList());

        });

    }

//assuming callback to be void(void)
    function generate_fetcher(id) {
        /*return (n, fetcher_callback) => {
            let times = [];

            let starting = Now();

            requestComputation(id, n, (frames) => {
                //console.log("updated");
                times = [];
                for (let frame of frames) {
                    times.push(frame.getTime());
                }
                fetcher_callback(times, frames);
                //callback();
            });
        }*/

        return (n) => {
            let times = [];

            async function getFrames() {
                let promise = new Promise((resolve, reject) => {
                    requestComputation(id, n, (frames) => {
                        //console.log("updated");
                        times = [];
                        for (let frame of frames) {
                            times.push(frame.getTime());
                        }
                        resolve(frames)

                    });

                })
                let result = await promise
                return result
            }

            return getFrames()
        }

    }

    let local_dict = {
        "power_supply_system": "Система энергопитания",
        "transceiver": "Приёмопередатчик",
        "magnetometer": "Магнитометр",
        "sun_sensor": "Датчик Солнца",
        "avs": "ДУС",
        "onboard_computer": "Бортовой компьютер",
        "magnetic_coils": "Магнитные катушки",
        "und": "und",
        "sth": "sth",
        "flywheel": "Маховик",
        "camera": "Камера"
    }

    let sensors_dict = {
        'magnetometer': ['measurement'],
        'avs': ['measurement']
    }

    let code = "";


    document.querySelector('.vsvsForm1 .Button').addEventListener("click", () => {
        let numNonValidInp = 0
        Array.prototype.slice.call(document.querySelectorAll('input')).slice(0, 2).forEach((el) => {
            if (el.id != 'time' && el.id != 'time2' && el.id != 'time3') {
                if (!el.value) {
                    numNonValidInp += 1
                    el.parentNode.classList.add('non-valid-input')
                    el.parentNode.children[2].style.display = 'block'
                    el.style.background = '#FFE7E7'
                } else {
                    el.parentNode.classList.remove('non-valid-input')
                    el.parentNode.children[2].style.display = 'none'
                    el.style.background = '#EAEDF0'
                }
            }
        })
        let yel = document.querySelector('.SpacecraftName>input')
        if (!yel.value) {
            numNonValidInp += 1
            yel.parentNode.classList.add('non-valid-input')
            yel.parentNode.children[2].style.display = 'block'
            yel.style.background = '#FFE7E7'
            yel.parentNode.style.background = '#FFE7E7'
        } else {
            yel.parentNode.classList.remove('non-valid-input')
            yel.parentNode.children[2].style.display = 'none'
            yel.style.background = '#EAEDF0'
            yel.parentNode.style.background = '#EAEDF0'

        }
        //console.log(numNonValidInp)
        if (numNonValidInp == 0) {
            document.querySelector('.wrapProgress').style.display = 'block'
            exitProgress = false
            setTimeout(() => {
                let timeField = document.querySelector('#time')
                if (timeField.value != '') {
                    let config = null;
                    //if (document.querySelector('.vsvsTables').innerText != '0\n1\n2\n3\n4\n\n\n\n\n\n5\n6\n7\n8\n9\n\n\n\n\n\n10\n11\n12\n13\n14\n\n\n\n\n')
                    config = window.OUT_CONF//get_config();
                    //console.log(config)
                    let start_time = new Date(document.querySelector("#time").value);
                    let tle_first = document.getElementById("TLEfirst").value;
                    let tle_second = document.getElementById("TLEsecond").value;
                    let dt = 1.0 / 120.0;

                    /*if(document.querySelector('.timeWarn'))
                        document.querySelector('.inputData_collumns').children[0].removeChild(document.querySelector('.timeWarn'))
                    */
                    // Debug

                    if (wasLoadedSpace == false) {


                        var gui = new dat.GUI()

// Canvas
                        var canvas = document.querySelector('#renderCanvas')

// Scene
                        window.scene = new THREE.Scene()

// Objects
                        var geometry = new THREE.SphereGeometry(6, 128, 128);

// Materials
                        var loader3 = new THREE.TextureLoader().load(earthTexture.default);
                        var material = new THREE.MeshBasicMaterial({map: loader3});
// load a resource
                        window.sphere = new THREE.Mesh(geometry, material);
                        load_percent += 10;
                        funcPlusProgress()
                        //console.log(sphere)
                        //console.log(loader3)

                        let closeControls = document.querySelector('.dg')
                        if (closeControls)
                            document.body.removeChild(closeControls)

//Satellite
                        window.group = new THREE.Group();

                        let loader2 = new GLTFLoader()
                        loader2.load(hullGltf.default, function (gltf) {
                            var satellite = gltf.scene.children[0]
                            satellite.scale.set(0.0006, 0.0006, 0.0006)
                            satellite.position.x = 0
                            satellite.position.y = 0
                            satellite.position.z = 0

                            group.add(gltf.scene)
                            load_percent += 10;
                            funcPlusProgress()
                        })

                        let physInfoPanels = [[0, 0.03, -0.03, 0, 'x'], [Math.PI, 0.03, -0.03, 0.2, 'x'],
                            [Math.PI / 2, 0.03, 0, 0.17, 'x'], [Math.PI / 2, 0.03, 0, 0.1, 'x'], [Math.PI / 2, 0.03, 0, 0.03, 'x'],
                            [-Math.PI / 2, 0.03, -0.06, 0.03, 'x'], [-Math.PI / 2, 0.03, -0.06, 0.1, 'x'], [-Math.PI / 2, 0.03, -0.06, 0.17, 'x'],
                            [Math.PI / 2, 0, -0.03, 0.17, 'y'], [Math.PI / 2, 0, -0.03, 0.1, 'y'], [Math.PI / 2, 0, -0.03, 0.03, 'y'],
                            [-Math.PI / 2, 0.06, -0.03, 0.03, 'y'], [-Math.PI / 2, 0.06, -0.03, 0.1, 'y'], [-Math.PI / 2, 0.06, -0.03, 0.17, 'y']]

                        for (let i = 0; i < 14; i++) {
                            loader2.load(hull2Gltf.default, function (gltf) {
                                var satellite = gltf.scene.children[0]
                                satellite.scale.set(0.0006, 0.0006, 0.0006)
                                if (physInfoPanels[i][4] == 'x')
                                    satellite.rotation.x = physInfoPanels[i][0]
                                else
                                    satellite.rotation.y = physInfoPanels[i][0]
                                satellite.position.x = physInfoPanels[i][1]
                                satellite.position.y = physInfoPanels[i][2]
                                satellite.position.z = physInfoPanels[i][3]

                                group.add(gltf.scene)
                                load_percent += 10;
                                funcPlusProgress()
                            })
                        }

                        //console.log(tle_first, "\n", tle_second, "\n", time2uint64(start_time), "\n", dt, "\n", config, "\n", code, "\n")


                        scene.add(group)

                        scene.add(sphere);

// Skybox

                        let texture_ft = new THREE.TextureLoader().load(skyboxTexture1.default);
                        let texture_bk = new THREE.TextureLoader().load(skyboxTexture2.default);
                        let texture_up = new THREE.TextureLoader().load(skyboxTexture3.default);
                        let texture_dn = new THREE.TextureLoader().load(skyboxTexture4.default);
                        let texture_rt = new THREE.TextureLoader().load(skyboxTexture5.default);
                        let texture_lf = new THREE.TextureLoader().load(skyboxTexture6.default);
                        load_percent += 10;
                        funcPlusProgress()
                        let materialArray = [];
                        materialArray.push(new THREE.MeshBasicMaterial({map: texture_ft}));
                        materialArray.push(new THREE.MeshBasicMaterial({map: texture_bk}));
                        materialArray.push(new THREE.MeshBasicMaterial({map: texture_up}));
                        materialArray.push(new THREE.MeshBasicMaterial({map: texture_dn}));
                        materialArray.push(new THREE.MeshBasicMaterial({map: texture_rt}));
                        materialArray.push(new THREE.MeshBasicMaterial({map: texture_lf}));

                        for (let i = 0; i < 6; i++)
                            materialArray[i].side = THREE.BackSide;

                        let skyboxGeo = new THREE.BoxGeometry(10000, 10000, 10000);
                        let skybox = new THREE.Mesh(skyboxGeo, materialArray);
                        scene.add(skybox);
                        load_percent += 10;
                        funcPlusProgress()
                        // Lights

                        var pointLight = new THREE.PointLight(0xffffff, 3)
                        pointLight.position.x = 2
                        pointLight.position.y = 3
                        pointLight.position.z = 4
                        scene.add(pointLight)

                        var pointLight = new THREE.PointLight(0xffffff, 3)
                        pointLight.position.x = -2
                        pointLight.position.y = 3
                        pointLight.position.z = 4
                        scene.add(pointLight)
                        load_percent += 10;
                        funcPlusProgress()
                        /**
                         * Sizes
                         */
                        window.sizes = {
                            width: document.querySelector('.cosmos').offsetWidth - 6,
                            height: document.querySelector('.cosmos').offsetHeight - 6
                        }

                        window.addEventListener('resize', () => {
                            // Update sizes
                            window.sizes.width = document.querySelector('.cosmos').offsetWidth - 6
                            window.sizes.height = document.querySelector('.cosmos').offsetHeight - 6

                            // Update camera
                            window.camera.aspect = window.sizes.width / window.sizes.height
                            window.camera.updateProjectionMatrix()

                            // Update renderer
                            /*renderer.setSize(sizes.width, sizes.height)
                            renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))*/
                            //console.log(sizes)
                            window.renderer.setSize(window.sizes.width, window.sizes.height)
                        })


                        /**
                         * Camera
                         */
// Base camera
                        window.camera = new THREE.PerspectiveCamera(75, window.sizes.width / window.sizes.height, 0.1, 30000)
                        scene.add(camera)
                        load_percent += 10;
                        funcPlusProgress()
                        //let camera = new THREE.PerspectiveCamera(55,window.innerWidth/window.innerHeight,45,30000);
//camera.position.set(-900,-200,-900);


                        /**
                         * Renderer
                         */
                        window.renderer = new THREE.WebGLRenderer({
                            canvas: canvas,
                            antialias: true
                        })
                        window.renderer.setSize(window.sizes.width, window.sizes.height)
                        window.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

// Controls
                        window.controls = new OrbitControls(window.camera, window.renderer.domElement)
                        controls.enableDamping = true
                        controls.target = group.position
                        controls.maxDistance = 1000
                        controls.minDistance = 0.2

                        wasLoadedSpace = true
                        /**
                         * Animate
                         */

                        var clock = new THREE.Clock()

                        sphere.position.x = 0
                        sphere.position.y = 0
                        sphere.position.z = 0
                        let takt = 0
                        load_percent += 10; //
                        funcPlusProgress()
                    } else {
                        for (let h = 0; h < 21; h++)
                            funcPlusProgress()
                    }
                    let data_0 = []
                    let beg = 0
                    let tick
                    let cancelTick
                    let timeout1
                    requestConstruction(tle_first, tle_second, time2uint64(start_time), dt, config, code,
                        (computational_id) => {
                            let id = computational_id;
                            let fetcher = generate_fetcher(id);
                            let q = new Queue(fetcher, 480, 10, 100, 1, 240, 240);
                            //теперь fetcher - наш getter, возвращающий промисс с массивом кадров
                            funcPlusProgress()
                            timeout1 = setTimeout(() => {
                                /*progress0()
                                document.querySelector('.wrapProgress').style.display = 'none'
                                        */console.log(data_0)
                                tick = () => {
                                    /*let data = */
                                    q.get().then((data) => {
					console.log(data)    
                                        exitProgress = true
                                        let coord = data.getCoord();
                                        let coord_geo = data.getCoordGeo();
                                        let time = data.getTime();
                                        let state_orient = data.getState();
                                        let logs = data.getMessagesList();
                                        let geomagnetic_field = data.getGeomagneticField();
                                        if (sphere) {
                                            sphere.rotation.x = 0
                                            sphere.rotation.y = -ToGreenwichMeanSiderealTime(time) + 40 / 180.0 * Math.PI
                                            sphere.rotation.z = 0
                                        }

                                        load_percent = 0
                                        document.getElementById('emulation_x').innerHTML = "X = " + coord.getPosition().getX().toFixed(2);
                                        document.getElementById('emulation_y').innerHTML = "Y = " + coord.getPosition().getY().toFixed(2);
                                        document.getElementById('emulation_z').innerHTML = "Z = " + coord.getPosition().getZ().toFixed(2);
                                        ;

                                        document.getElementById('emulation_x_vel').innerHTML = "X Velocity = " + coord.getVelocity().getX().toFixed(2);
                                        ;
                                        document.getElementById('emulation_y_vel').innerHTML = "Y Velocity = " + coord.getVelocity().getY().toFixed(2);
                                        ;
                                        document.getElementById('emulation_z_vel').innerHTML = "Z Velocity = " + coord.getVelocity().getZ().toFixed(2);
                                        ;

                                        document.getElementById('emulation_lat').innerHTML = "lat = " + coord_geo.getLat().toFixed(2);
                                        ;
                                        document.getElementById('emulation_lon').innerHTML = "lon = " + coord_geo.getLon().toFixed(2);
                                        ;
                                        document.getElementById('emulation_alt').innerHTML = "alt = " + coord_geo.getAlt().toFixed(2);
                                        ;

                                        document.getElementById('emulation_mag_x').innerHTML = "Mag_x = " + (geomagnetic_field.getHasX() ? geomagnetic_field.getX().toFixed(2) : "0.0");
                                        document.getElementById('emulation_mag_y').innerHTML = "Mag_y = " + geomagnetic_field.getY().toFixed(2);
                                        ;
                                        document.getElementById('emulation_mag_z').innerHTML = "Mag_z = " + geomagnetic_field.getZ().toFixed(2);
                                        ;

                                        document.getElementById('emulation_orient_x').innerHTML = "Orient_x = " + state_orient.getOrientation().getX().toFixed(2);
                                        ;
                                        document.getElementById('emulation_orient_y').innerHTML = "Orient_y = " + state_orient.getOrientation().getY().toFixed(2);
                                        ;
                                        document.getElementById('emulation_orient_z').innerHTML = "Orient_z = " + state_orient.getOrientation().getZ().toFixed(2);
                                        ;

                                        document.getElementById('emulation_rotation_x').innerHTML = "Rotation_x = " + state_orient.getRotationSpeed().getX().toFixed(2);
                                        ;
                                        document.getElementById('emulation_rotation_y').innerHTML = "Rotation_y = " + state_orient.getRotationSpeed().getY().toFixed(2);
                                        ;
                                        document.getElementById('emulation_rotation_z').innerHTML = "Rotation_z = " + state_orient.getRotationSpeed().getZ().toFixed(2);
                                        ;

                                        /*

                                        for (let log of logs) {
                                                  let s = "[T" +decodeTime(log.getDtime()).toLocaleString() + "    " + log.getFrameNumber() + "] " + log.getLevel() + " (" +
                                                          log.getOrigin() + "): " + log.getText();
                                                  if (log.getLevel() == "warning") {
                                                    s = "<font color=\"orange\">" + s + "</font>"
                                                  } else if (log.getLevel() == "error") {
                                                    s = "<font color=\"red\">" + s + "</font>"
                                                  }
                                          log_arr.push(s + "<br>");
                                                  log_arr.shift();

                                                }
                                                document.getElementById('emulation_log').innerHTML = "";
                                                for (let log of log_arr) {
                                                  document.getElementById('emulation_log').innerHTML += log;
                                                }
                            */
                                        //console.log(data.getState().getOutModuleStatesList())
                                        document.getElementById('point_result').innerHTML = "";
                                        data.getState().getOutModuleStatesList().forEach((el) => {
                                            let name
                                            OUT_CONF.getIncludedModList().forEach((el2) => {
                                                if (el2.getKey() == el.getSlot()) name = el2.getValue().getName()
                                                //console.log(el2)
                                            })
                                            //console.log(name)
                                            if (sensors_dict.hasOwnProperty(name)) {
                                                document.getElementById('point_result').innerHTML += local_dict[name] + ':' + '<br/><br/>'
                                                el.getStateDictList().forEach((el2) => {
                                                    //console.log(el2, name)
                                                    if (sensors_dict[name].includes(el2.getName())) {
                                                        document.getElementById('point_result').innerHTML += el2.getName() + ' X = ' + el2.getValVector().getX().toFixed(2) + '<br/>'
                                                        document.getElementById('point_result').innerHTML += el2.getName() + ' Y = ' + el2.getValVector().getY().toFixed(2) + '<br/>'
                                                        document.getElementById('point_result').innerHTML += el2.getName() + ' Z = ' + el2.getValVector().getZ().toFixed(2) + '<br/>'
                                                    }
                                                })
                                            }
                                        })
                                        group.position.z = -coord.getPosition().getZ() / 1000.0
                                        group.position.y = coord.getPosition().getY() / 1000.0
                                        group.position.x = coord.getPosition().getX() / 1000.0
                                        group.rotation.x = state_orient.getOrientation().getX()
                                        group.rotation.y = state_orient.getOrientation().getY()
                                        group.rotation.z = state_orient.getOrientation().getZ()
                                        if (beg == 0) {
                                            beg = 1;
                                            window.camera.position.x = coord.getPosition().getX() / 1000.0 * 1.05
                                            window.camera.position.y = coord.getPosition().getY() / 1000.0 * 1.05
                                            window.camera.position.z = -coord.getPosition().getZ() / 1000.0 * 1.05
                                        }

                                        controls.update();

                                        //if (data_0.length < 120) {
                                        //    fetcher(120, (t, d) => data_0.push(...d));
                                        //}
                                        controls.update();

                                        /*   if (group) {
                                               takt += 0.0002
                                               group.rotation.y = .05 * takt*100;
                                               group.rotation.x = .05 * takt*100;
                                               group.position.x = Math.cos(-takt) * 6.5
                                               group.position.y = 0
                                               group.position.z = Math.sin(-takt) * 6.5
                                           };
                                       */
                                        // Render
                                        window.renderer.render(scene, window.camera)
                                        // Call tick again on the next frame
                                        cancelTick = window.requestAnimationFrame(tick)
                                    })
                                }
                                let intervalCheckTick = setInterval(() => {
                                    if (is_loaded) {
                                        tick()
                                        let intervalCheckProgress = setInterval(() => {
                                            if (exitProgress) {

                                                document.querySelector('.wrapProgress').style.display = 'none'
                                                progress0()
                                                clearInterval(intervalCheckProgress)
                                            }
                                        }, 100)
                                        clearInterval(intervalCheckTick)
                                    }
                                }, 100)
                            }, 1000)
                        })

                    document.querySelector('.buttonCloseModalForVsvs').onclick = () => {
                        window.cancelAnimationFrame(cancelTick);
                        tick = null;
                        progress0()
                        document.querySelector('.wrapProgress').style.display = 'none'
                        document.querySelector('#point_result').innerHTML = ''
                        document.querySelectorAll('.indicator_scroll>div').forEach((el) => {
                            el.textContent = ''
                        })
                        document.querySelector('.buttonCloseModalForVsvs').onclick = () => {}
                        document.onkeydown = () => {}
			document.querySelector('wrapProgress').style.display = 'none'
			document.querySelector('appWrapper').classList.remove('blurForVsvs')    
                        clearTimeout(timeout1)
                    }

                    document.onkeydown = function (evt) {
                        evt = evt || window.event;
                        var isEscape = false;
                        if ("key" in evt) {
                            isEscape = (evt.key === "Escape" || evt.key === "Esc");
                        } else {
                            isEscape = (evt.keyCode === 27);
                        }
                        if (isEscape) {
                            window.cancelAnimationFrame(cancelTick);
                            tick = null;
                            document.querySelector('.buttonCloseModalForVsvs').onclick = () => {}
                            document.onkeydown = () => {}
                            document.querySelector('.visualizeVsvs').style.display = 'none';
                            document.querySelector('#point_result').innerHTML = ''
                            document.querySelectorAll('.indicator_scroll>div').forEach((el) => {
                                el.textContent = ''
                            })
			    progress0()	
                            document.querySelector('.wrapProgress').style.display = 'none'
                            document.querySelector('.appWrapper').classList.remove('blurForVsvs');
                            clearTimeout(timeout1)
                        }
                    };


                    //console.log(data_0)

                } else {
                    let timeWarn = document.createElement('p')
                    document.querySelector('#time').after(timeWarn)
                    timeWarn.innerText = 'Вы не ввели время'
                    timeWarn.classList.add('heading_red', 'timeWarn')
                }
            }, 200)
        }
    })


    document.querySelector("#code_file").addEventListener("change", (event) => {
        let FR = new FileReader();
        FR.onload = (event) => {
            //console.log(event.target.result)
            code = event.target.result;
        }
        FR.readAsDataURL(event.target.files[0]);
    }, false);
}

