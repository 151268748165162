import './Vsvs.css';
import Input from "./Input";
import Hint from "./Hint";
import {useRef, useState, useEffect} from "react";
import React from "react";
import {startupForVsvs} from '../startup.js'
import {funcConf} from '../vsvs_config_react.js'
import infoCircleImg from "../images/infoCircle.svg"

function Vsvs({refOnCosmos/*, script*/}) {

    let hint = useRef()
    let numConfigRow = useRef()
    let ConfigPoint = useRef()
    let letsgovsvs = useRef()
    let addConfig = useRef()
    useEffect(() => {
        /*console.log(script)
        let scr = document.createElement("script");
        scr.src = script.src
        document.head.appendChild(scr)*/
        if (document.body.clientWidth >= 768) {
            startupForVsvs()
            funcConf()
        }
    }, [])

    let [displayNone, setDisplayNone] = useState(
        (() => {
            if (document.body.clientWidth < 768) return 0
            else return 1
        })()
    )

    let onFieldChange = (event, id) => {
        if (event.target.files && event.target.files[0]) {
            //console.log(event.target.files)
        }
    }

    let refTle = useRef()
    let refTle2 = useRef()
    let refIss = useRef()


    return (
        <div className="vsvs">
            {displayNone ? (
                <>
                    <p className="ContactsText1">ВКВС</p>
                    <p className="ContactsText2">Веб-приложение для моделирования различных аспектов проектирования
                        космического
                        аппарата, в соответствии с международным стандартом форм-фактора cubesat габаритами до 3U, и
                        космического полета. </p>
                    <div className="vsvsForm">
                        <p style={{textAlign: 'center'}} className="vsvsFromMainText">Ввод исходных данных</p>
                        <div className="vsvsFormInputs">
                            <div className="vsvsForm1">
                                <p className="vsvsTextBeforeInput">TLE первая строка:</p>
                                <Input style={{height: '30px'}} ref={refTle} id='TLEfirst' name="" type="text"
                                       classN="TLEfirst"></Input>
                                <p className="vsvsTextBeforeInput">TLE вторая строка:</p>
                                <Input style={{height: '30px'}} ref={refTle2} id='TLEsecond' name="" type="text"
                                       classN="TLEsecond"></Input>
                                <p className="vsvsTextBeforeInput">Дата и время:</p>
                                <Input style={{height: '30px'}} id='time' name="" type="datetime-local"
                                       classN="smallInp"></Input>
                                <p className="vsvsTextBeforeInput">Код:</p>
                                <div style={{position: 'relative'}}>
                                    <Input change={onFieldChange}
                                           style={{height: '30px', lineHeight: '30px', display: 'inline-block'}}
                                           name="Обзор"
                                           type="file"
                                           id="code_file" classN="smallInp"></Input>
                                    <div onMouseEnter={() => {
                                        hint.current.style.display = 'block'
                                    }}
                                         onMouseLeave={() => {
                                             hint.current.style.display = 'none'
                                         }}
                                         style={{background: `url('${infoCircleImg}')`}}
                                         className="infoCircle"></div>
                                    <Hint ref={hint}></Hint>
                                </div>
                                <div onClick={() => {
                                    let numNonValidInp = 0
                                    if (!document.querySelector('.SpacecraftName>input').value) {
                                        numNonValidInp += 1
                                    }
                                    if (!document.querySelector('.TLEfirst>input').value) {
                                        numNonValidInp += 1
                                    }
                                    if (!document.querySelector('.TLEsecond>input').value) {
                                        numNonValidInp += 1
                                    }
                                    //console.log(numNonValidInp)
                                    if (numNonValidInp == 0) {
                                        document.querySelector('.appWrapper').classList.add('blurForVsvs');
                                        refOnCosmos.current.style.display = 'flex'
                                    }
                                }} onMouseMove={(e) => {
                                    if (e.target == letsgovsvs.current)
                                        letsgovsvs.current.style.background = `linear-gradient(90deg, #005AAB ${e.nativeEvent.offsetX / letsgovsvs.current.offsetWidth * 100 - 15}%, #81BFF8 ${e.nativeEvent.offsetX / letsgovsvs.current.offsetWidth * 100}%, #005AAB ${e.nativeEvent.offsetX / letsgovsvs.current.offsetWidth * 100 + 10}%)`
                                    else {
                                        letsgovsvs.current.style.background = `linear-gradient(90deg, #005AAB ${(e.nativeEvent.offsetX + (letsgovsvs.current.offsetWidth - e.target.offsetWidth) / 2) / letsgovsvs.current.offsetWidth * 100 - 15}%, #81BFF8 ${(e.nativeEvent.offsetX + (letsgovsvs.current.offsetWidth - e.target.offsetWidth) / 2) / letsgovsvs.current.offsetWidth * 100}%, #005AAB ${(e.nativeEvent.offsetX + (letsgovsvs.current.offsetWidth - e.target.offsetWidth) / 2) / letsgovsvs.current.offsetWidth * 100 + 10}%)`
                                    }
                                }} ref={letsgovsvs} onMouseDown={() => {
                                    letsgovsvs.current.style.background = '#084479'
                                }} onMouseUp={() => {
                                    letsgovsvs.current.style.background = '#005AAB'
                                }} onMouseLeave={() => {
                                    letsgovsvs.current.style.background = `#005AAB`
                                }} id="#time!" className="Button">
                                    <div className="ButtonText">ЗАПУСТИТЬ ВКВС</div>
                                </div>
                            </div>
                            <div className="vsvsForm2">
                                <p className="vsvsTextBeforeInput">Название аппарата:</p>
                                <Input style={{height: '30px'}} ref={refIss} name="" type="text"
                                       classN="SpacecraftName"></Input>
                                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '25px'}}>
                                    <div className="dropdown" style={{width: '31%'}}
                                         onMouseLeave={() => document.querySelector('.dropdown-content1').style.display = 'none'}>
                                        <div id="select1" style={{width: 'calc(100% - 20px)'}}
                                             className="dropbtn dropbtn1"
                                             onMouseEnter={() => document.querySelector('.dropdown-content1').style.display = 'block'}>u3
                                        </div>
                                        <div style={{width: '100%'}} className="dropdown-content dropdown-content1"
                                             onClick={() => document.querySelector('.dropdown-content1').style.display = 'none'}>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn1').textContent = document.querySelectorAll('.dropdown-content1>div')[0].textContent}>
                                                <div>u3</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn1').textContent = document.querySelectorAll('.dropdown-content1>div')[1].textContent}>
                                                <div>u2</div>
                                            </div>
                                            <div style={{
                                                width: 'calc(100% - 20px)',
                                                borderBottomLeftRadius: '10px',
                                                borderBottomRightRadius: '10px'
                                            }}
                                                 onClick={() => document.querySelector('.dropbtn1').textContent = document.querySelectorAll('.dropdown-content1>div')[2].textContent}>
                                                <div>u1</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown" ref={numConfigRow} style={{width: '31%'}}
                                         onMouseLeave={() => document.querySelector('.dropdown-content2').style.display = 'none'}>
                                        <div id="select2" style={{width: 'calc(100% - 20px)'}}
                                             className="dropbtn dropbtn2"
                                             onMouseEnter={() => document.querySelector('.dropdown-content2').style.display = 'block'}>0
                                        </div>
                                        <div style={{width: '100%'}} className="dropdown-content dropdown-content2"
                                             onClick={() => document.querySelector('.dropdown-content2').style.display = 'none'}>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[0].textContent}>
                                                <div>0</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[1].textContent}>
                                                <div>1</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[2].textContent}>
                                                <div>2</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[3].textContent}>
                                                <div>3</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[4].textContent}>
                                                <div>4</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[5].textContent}>
                                                <div>5</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[6].textContent}>
                                                <div>6</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[7].textContent}>
                                                <div>7</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[8].textContent}>
                                                <div>8</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[9].textContent}>
                                                <div>9</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[10].textContent}>
                                                <div>10</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[11].textContent}>
                                                <div>11</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[12].textContent}>
                                                <div>12</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[13].textContent}>
                                                <div>13</div>
                                            </div>
                                            <div style={{
                                                width: 'calc(100% - 20px)',
                                                borderBottomLeftRadius: '10px',
                                                borderBottomRightRadius: '10px'
                                            }}
                                                 onClick={() => document.querySelector('.dropbtn2').textContent = document.querySelectorAll('.dropdown-content2>div')[14].textContent}>
                                                <div>14</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="dropdown" ref={ConfigPoint} style={{width: '33%'}}
                                         onMouseLeave={() => document.querySelector('.dropdown-content3').style.display = 'none'}>
                                        <div id="select3" style={{width: 'calc(100% - 20px)'}}
                                             className="dropbtn dropbtn3"
                                             onMouseEnter={() => document.querySelector('.dropdown-content3').style.display = 'block'}>Удалить
                                            модуль
                                        </div>
                                        <div
                                            onClick={() => document.querySelector('.dropdown-content3').style.display = 'none'}
                                            style={{width: '100%'}} className="dropdown-content dropdown-content3">
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[0].textContent}>
                                                <div>Удалить модуль</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[1].textContent}>
                                                <div>Система энергопитания</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[2].textContent}>
                                                <div>Приёмопередатчик</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[3].textContent}>
                                                <div>Магнитометр</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[4].textContent}>
                                                <div>Датчик Солнца</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[5].textContent}>
                                                <div>ДУС</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[6].textContent}>
                                                <div>Бортовой компьютер</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[7].textContent}>
                                                <div>Магнитные катушки</div>
                                            </div>
                                            <div style={{width: 'calc(100% - 20px)'}}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[8].textContent}>
                                                <div>Маховик</div>
                                            </div>
                                            <div style={{
                                                width: 'calc(100% - 20px)',
                                                borderBottomLeftRadius: '10px',
                                                borderBottomRightRadius: '10px'
                                            }}
                                                 onClick={() => document.querySelector('.dropbtn3').textContent = document.querySelectorAll('.dropdown-content3>div')[9].textContent}>
                                                <div>Камера</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div onMouseDown={() => {
                                    addConfig.current.style.background = '#084479'
                                }} onMouseUp={() => {
                                    addConfig.current.style.background = '#005AAB'
                                }} ref={addConfig} onMouseMove={(e) => {
                                    if (e.target == addConfig.current)
                                        addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100}%, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 + 10}%)`
                                    else {
                                        addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100}%, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 + 10}%)`
                                    }
                                }} onMouseLeave={() => {
                                    addConfig.current.style.background = `#005AAB`
                                }} style={{marginBottom: '30px'}}
                                     className="Button">
                                    <div className="ButtonText">ДОБАВИТЬ</div>
                                </div>
                                <div className="vsvsTables">
                                    <div className="vsvsTableUnit">
                                        <div className="vsvsTable">
                                            <table cellSpacing={1}>
                                                <tr>
                                                    <td id="config0"></td>
                                                    <div className="numericConfigTable">0</div>
                                                </tr>
                                                <tr>
                                                    <td id="config1"></td>
                                                    <div className="numericConfigTable">1</div>
                                                </tr>
                                                <tr>
                                                    <td id="config2"></td>
                                                    <div className="numericConfigTable">2</div>
                                                </tr>
                                                <tr>
                                                    <td id="config3"></td>
                                                    <div className="numericConfigTable">3</div>
                                                </tr>
                                                <tr>
                                                    <td id="config4"></td>
                                                    <div className="numericConfigTable">4</div>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="vsvsTableUnit">
                                        <div className="vsvsTable">
                                            <table cellSpacing={1}>
                                                <tr>
                                                    <td id="config5"></td>
                                                    <div className="numericConfigTable">5</div>
                                                </tr>
                                                <tr>
                                                    <td id="config6"></td>
                                                    <div className="numericConfigTable">6</div>
                                                </tr>
                                                <tr>
                                                    <td id="config7"></td>
                                                    <div className="numericConfigTable">7</div>
                                                </tr>
                                                <tr>
                                                    <td id="config8"></td>
                                                    <div className="numericConfigTable">8</div>
                                                </tr>
                                                <tr>
                                                    <td id="config9"></td>
                                                    <div className="numericConfigTable">9</div>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="vsvsTableUnit">
                                        <div className="vsvsTable">
                                            <table cellSpacing={1}>
                                                <tr>
                                                    <td id="config10"></td>
                                                    <div className="numericConfigTable">10</div>
                                                </tr>
                                                <tr>
                                                    <td id="config11"></td>
                                                    <div className="numericConfigTable">11</div>
                                                </tr>
                                                <tr>
                                                    <td id="config12"></td>
                                                    <div className="numericConfigTable">12</div>
                                                </tr>
                                                <tr>
                                                    <td id="config13"></td>
                                                    <div className="numericConfigTable">13</div>
                                                </tr>
                                                <tr>
                                                    <td id="config14"></td>
                                                    <div className="numericConfigTable">14</div>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>) : (<div style={{
                fontWeight: 300,
                fontSize: '25px',
                lineHeight: '33px',
                textAlign: 'center',
                color: '#FFFFFF',
                marginTop: '180px'
            }}>Данная страница временно не доступна для мобильных устройств</div>)}
        </div>
    );
}

export default Vsvs;
