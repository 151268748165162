import ContactsExitButton from './ContactsExitButton'
import ContactsSuccess from '../images/ContactsSuccess.png'
import ContactsError from '../images/ContactsError.png'
import './ModalContacts.css'
import React, {useRef} from "react";

let ModalContacts = React.forwardRef(({exit, error}, ref) => {

    if (!error)
        return (
            <div ref={ref} className='modal-main-contacts'>
                <div className="modal-img">
                    <img src={ContactsSuccess} className='modal-image-contacts'></img>
                </div>

                <h2 className="modal-title-contacts">Успешно!</h2>
                <div className="modal-body-contacts">
                    <h2 className="modal-text-contacts">Ваше сообщение было отправлено</h2>
                </div>
                <ContactsExitButton exit={exit} error={error}></ContactsExitButton>
            </div>
        );
    else
        return (
            <div ref={ref} className='modal-main-contacts'>

                <div className="modal-img">
                    <img src={ContactsError} className='modal-image-contacts'></img>
                </div>

                <h2 className="modal-title-contacts">Ошибка!</h2>
                <div className="modal-body-contacts">
                    <h2 className="modal-text-contacts">Ваше сообщение не доставлено</h2>
                </div>
                <ContactsExitButton exit={exit} error={error}></ContactsExitButton>
            </div>
        );
})

export default ModalContacts;