import './NavElement.css';
import {observer} from "../index";
import {Link} from "react-router-dom";
import React from "react";

function NavElement({name, updateCurrentMenu, iter, updateNavLine}) {

    let changeMenu = () => {
        updateCurrentMenu(iter)
        updateNavLine()
        observer()
    }

    let link = (name) => {
        switch (name) {
            case 'Главная':
                return ''
            case 'ВКВС':
                return 'vsvs'
            case 'Эмулятор':
                return 'emulator'
            case 'Расчёты':
                return 'igrf'
            case 'Контакты':
                return 'contacts'
            case 'AIS-ADSB':
                return 'ais_adsb/'
        }
    }

    return (
        <div className="NavElement">
            <Link onClick={changeMenu} to={`/${link(name)}`} className="NavElementLink">{name}</Link>
        </div>
    );
}

export default NavElement;
