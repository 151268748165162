import './ModalForVsvs.css';
import React, {useState, useRef} from "react";
import exit from '../images/Close.svg'
import exitHover from '../images/CloseHover.svg'
import {ProgressBar} from "react-bootstrap";
import openFullscreen from '../images/opn_flscrn.svg'
import openFullscreenHover from '../images/opn_flscrn_hover.svg'
import closeFullscreen from '../images/cls_flscrn.svg'
import closeFullscreenHover from '../images/cls_flscrn_hover.svg'

let ModalForVsvs = React.forwardRef(({}, ref) => {

    let [exitState, setExitState] = useState(exit)
    let [fullscreenState, setFullscreenState] = useState(openFullscreen)
    let [progress, setProgress] = useState(0)
    let cosmosRef = useRef()
    window.funcPlusProgress = () => {
        setProgress(progress += 10); /*console.log(progress)*/
    }
    window.progress0 = () => {
        setProgress(0)
    }
    let fullscreenFunc = () => {
        if (fullscreenState == openFullscreenHover) {
            document.querySelector('.indicators').style.top = document.querySelector('.indicators').offsetTop + 'px'
            document.querySelector('.indicators').style.position = 'fixed'
            cosmosRef.current.style.position = 'fixed'
            cosmosRef.current.style.top = '20px';
            cosmosRef.current.style.left = '20px';
            cosmosRef.current.style.width = 'calc(100% - 96px)';
            cosmosRef.current.style.height = 'calc(100% - 40px)';
            cosmosRef.current.style.zIndex = '100';
            cosmosRef.current.style.marginTop = '0';
            setFullscreenState(closeFullscreen);
        } else {
            document.querySelector('.indicators').style.position = 'static'
            //document.querySelector('.indicators').style.top = document.querySelector('.indicators').offsetTop + 'px'
            cosmosRef.current.style.position = 'relative'
            cosmosRef.current.style.top = '0';
            cosmosRef.current.style.left = '0';
            if (window.innerWidth > 1268) {
                cosmosRef.current.style.width = 'calc(100% - 340px)'
                cosmosRef.current.style.height = '50vh';
                cosmosRef.current.style.marginTop = '100px';
            } else {
                cosmosRef.current.style.width = 'calc(100% - 120px)'
                cosmosRef.current.style.marginTop = '50px'
                cosmosRef.current.style.height = 'calc(50vh + 50px)'
            }
            cosmosRef.current.style.zIndex = '100';
            setFullscreenState(openFullscreenHover);
        }
        window.sizes.width = document.querySelector('.cosmos').offsetWidth + 24
        window.sizes.height = document.querySelector('.cosmos').offsetHeight - 6
        window.camera.aspect = window.sizes.width / window.sizes.height
        window.camera.updateProjectionMatrix()
        window.renderer.setSize(window.sizes.width, window.sizes.height)
    }

    return (
        <div ref={ref} className="visualizeVsvs">
            <div ref={cosmosRef} className="cosmos">
                <div style={{width: '100%', height: '100%', borderRadius: '30px', overflow: 'hidden'}}>
                    <canvas id="renderCanvas" style={{
                        touchAction: 'none',
                        width: '100%',
                        height: '640px',
                        borderRadius: '30px'
                    }}></canvas>
                </div>
                <div onMouseEnter={() => setExitState(exitHover)} onMouseLeave={() => setExitState(exit)}
                     onClick={() => {
                         ref.current.style.display = 'none';
                         document.querySelector('.appWrapper').classList.remove('blurForVsvs');
                        /* if (fullscreenState == closeFullscreen) {
                             document.querySelector('.indicators').style.position = 'static'
                             //document.querySelector('.indicators').style.top = document.querySelector('.indicators').offsetTop + 'px'
                             cosmosRef.current.style.position = 'relative'
                             cosmosRef.current.style.top = '0';
                             cosmosRef.current.style.left = '0';
                             cosmosRef.current.style.width = 'calc(100% - 340px)'
                             cosmosRef.current.style.height = '50vh';
                             cosmosRef.current.style.zIndex = '100';
                             cosmosRef.current.style.marginTop = '100px';
                             setFullscreenState(openFullscreen);
                             window.renderer.setSize(0, 0)
                         }*/
                     }} className="buttonCloseModalForVsvs" /*style={{background: `url("${exitState}")`}}*/>
                    <img src={exitState} style={{width: '100%', height: '100%'}} alt=""/>
                </div>
                <div onMouseEnter={() => {
                    if (fullscreenState == openFullscreen)
                        setFullscreenState(openFullscreenHover)
                    else
                        setFullscreenState(closeFullscreenHover)
                }} onMouseLeave={() => {
                    if (fullscreenState == openFullscreenHover)
                        setFullscreenState(openFullscreen)
                    else
                        setFullscreenState(closeFullscreen)
                }}
                     onClick={fullscreenFunc} className="buttonFullscreen">
                    <img src={fullscreenState} style={{width: '100%', height: '100%'}} alt=""/>
                </div>
                <div className="wrapProgress" style={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    background: '#0B0B1E',
                    width: '100%',
                    height: '100%',
                    borderRadius: '30px'
                }}>
                    <div style={{
                        position: 'absolute',
                        left: '50%',
                        top: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '40px'
                    }} className="progressBar">
                        <ProgressBar animated now={progress * 100 / 230}/>
                    </div>
                </div>
            </div>
            <div className="indicators">
                <div className="indicator">
                    <p>СОСТОЯНИЕ СИСТЕМЫ</p>
                    <div className="indicator_scroll">
                        <div id="emulation_x"></div>
                        <div id="emulation_y"></div>
                        <div id="emulation_z"></div>

                        <div id="emulation_x_vel"></div>
                        <div id="emulation_y_vel"></div>
                        <div id="emulation_z_vel"></div>

                        <div id="emulation_lat"></div>
                        <div id="emulation_lon"></div>
                        <div id="emulation_alt"></div>

                        <div id="emulation_mag_x"></div>
                        <div id="emulation_mag_y"></div>
                        <div id="emulation_mag_z"></div>

                        <div id="emulation_orient_x"></div>
                        <div id="emulation_orient_y"></div>
                        <div id="emulation_orient_z"></div>
                        <div id="emulation_rotation_x"></div>
                        <div id="emulation_rotation_y"></div>
                        <div id="emulation_rotation_z"></div>

                        <div id="emulation_log"></div>
                    </div>

                </div>
                <div className="indicator">
                    <p>ПОКАЗАНИЯ ДАТЧИКОВ</p>
                    <div style={{paddingLeft: '15px'}} id="point_result"></div>
                </div>
            </div>
        </div>
    )
})

export default ModalForVsvs;
