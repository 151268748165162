import './Igrf.css';
import './Contacts.css'
import prevPoint from '../images/prevPont.png'
import nextPoint from '../images/nextPoint.png'
import {useEffect, useRef, useState} from "react";
import Input from "./Input";
import Button from "./Button";
import React from "react";

function Igrf() {

    let points = [
        {
            name: 'Расчёт поля в точке',
            inputs: [
                {
                    id: 'latitude',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Широта:',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'longitude',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Долгота:',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'height',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Высота:',
                    class: 'smallInp',
                    type: 'text'
                }, {
                    id: 'time',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Ваше сообщение:',
                    class: 'smallInp',
                    type: 'datetime-local'
                }
            ]
        },
        {
            name: 'Расчёт поля по TLE в текущий момент времени',
            inputs: [
                {
                    id: 'TLE1',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'TLE first line:',
                    class: 'smallInp',
                    type: 'text'
                },
                {
                    id: 'TLE2',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'TLE second line:',
                    class: 'smallInp',
                    type: 'text'
                }
            ]
        },
        {
            name: 'Расчёт поля по TLE в заданный момент времени',
            inputs: [
                {
                    id: 'TLEtime1',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'TLE first line:',
                    class: 'smallInp',
                    type: 'text'
                },
                {
                    id: 'TLEtime2',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'TLE second line:',
                    class: 'smallInp',
                    type: 'text'
                },
                {
                    id: 'time2',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Ваше сообщение:',
                    class: 'smallInp',
                    type: 'datetime-local'
                }
            ]
        },
        {
            name: 'Расчёт координат по SGP в заданный момент времени',
            inputs: [
                {
                    id: 'TLEsgp1',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'TLE first line:',
                    class: 'smallInp',
                    type: 'text'
                },
                {
                    id: 'TLEsgp2',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'TLE second line:',
                    class: 'smallInp',
                    type: 'text'
                },
                {
                    id: 'time3',
                    style: {
                        width: 650,
                        height: 50
                    },
                    name: 'Ваше сообщение:',
                    class: 'smallInp',
                    type: 'datetime-local'
                }
            ]
        }
    ]

    let [currentPoint, setCurrentPoint] = useState(0)

    let [circles, setCircles] = useState(['activeSwitchCircle', 'switchCircle', 'switchCircle', 'switchCircle'])

    let curCarousel = useRef()
    /*
        useEffect(() => {
            pointOnclick(currentPoint)
        }, [currentPoint])
    */
    let switchPoint = (i) => {
        //console.log(i, '42313241')
        let circ = ['switchCircle', 'switchCircle', 'switchCircle', 'switchCircle']
        if (i == 4) {
            circ[0] = 'activeSwitchCircle'
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0.35s'
                curCarousel.current.style.transform = 'translate(-100%, 0)'
                curCarousel.current.style.opacity = 0
            }, 0)
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0s'
                curCarousel.current.style.transform = 'translate(100%, 0)'
                setCircles(circ)
                setCurrentPoint(1)
                setCurrentPoint(0)
            }, 350)
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0.35s'
                curCarousel.current.style.transform = 'translate(0, 0)'
                curCarousel.current.style.opacity = 1
                //console.log(points[currentPoint], currentPoint)
            }, 400)
        }
        if (i == -1) {
            circ[3] = 'activeSwitchCircle'
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0.35s'
                curCarousel.current.style.transform = 'translate(100%, 0)'
                curCarousel.current.style.opacity = 0
            }, 0)
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0s'
                curCarousel.current.style.transform = 'translate(-100%, 0)'
                setCurrentPoint(3)
                setCircles(circ)
            }, 350)
            setTimeout(() => {
                curCarousel.current.style.transitionDuration = '0.35s'
                curCarousel.current.style.transform = 'translate(0, 0)'
                curCarousel.current.style.opacity = 1
            }, 400)
        }
        if (i != 4 && i != -1) {
            circ[i] = 'activeSwitchCircle'
            if (i > currentPoint) {
                setTimeout(() => {
                    curCarousel.current.style.transitionDuration = '0.35s'
                    curCarousel.current.style.transform = 'translate(-100%, 0)'
                    curCarousel.current.style.opacity = 0
                }, 0)
                setTimeout(() => {
                    curCarousel.current.style.transitionDuration = '0s'
                    curCarousel.current.style.transform = 'translate(100%, 0)'
                    setCurrentPoint(i)
                    setCircles(circ)
                }, 350)
                setTimeout(() => {
                    curCarousel.current.style.transitionDuration = '0.35s'
                    curCarousel.current.style.transform = 'translate(0, 0)'
                    curCarousel.current.style.opacity = 1
                }, 400)
            } else {
                if (i < currentPoint) {
                    setTimeout(() => {
                        curCarousel.current.style.transitionDuration = '0.35s'
                        curCarousel.current.style.transform = 'translate(100%, 0)'
                        curCarousel.current.style.opacity = 0
                    }, 0)
                    setTimeout(() => {
                        curCarousel.current.style.transitionDuration = '0s'
                        curCarousel.current.style.transform = 'translate(-100%, 0)'
                        setCurrentPoint(i)
                        setCircles(circ)
                    }, 350)
                    setTimeout(() => {
                        curCarousel.current.style.transitionDuration = '0.35s'
                        curCarousel.current.style.transform = 'translate(0, 0)'
                        curCarousel.current.style.opacity = 1
                    }, 400)
                }
            }
        }
    }

    let swipeInfo = {
        x: '',
        y: '',
        time: ''
    }

    let onTouchStart = (e) => {
        swipeInfo.x = e.touches[0].screenX
        swipeInfo.y = e.touches[0].screenY
        swipeInfo.time = e.timeStamp
        //console.log(e)
    }

    let onTouchEnd = (e) => {
        let x = swipeInfo.x - e.changedTouches[0].screenX
        let y = Math.abs(swipeInfo.y - e.changedTouches[0].screenY) * 2
        let ti = e.timeStamp - swipeInfo.time
        //console.log(e)
        if (x > 50 && x > y && ti < 500) {
            switchPoint(currentPoint + 1)
        } else if (x < -50 && -x > y && ti < 500) {
            switchPoint(currentPoint - 1)
        }
        //console.log(x, y, ti)
    }

    const UnixEpoch = 62135596800000000;
    const TicksPerMillisecond = 1000;

    function Now() {
        return UnixEpoch + (new Date()).getTime() * TicksPerMillisecond;
    }

    let rest = () => {
        let data
        let url
        let numNonValidInp = 0
        document.querySelectorAll('input').forEach((el) => {
            if (el.id != 'time' && el.id != 'time2' && el.id != 'time3') {
                if (!el.value) {
                    numNonValidInp += 1
                    el.parentNode.classList.add('non-valid-input')
                    el.parentNode.children[2].style.display = 'block'
                    el.style.background = '#FFE7E7'
                    el.parentNode.style.background = '#FFE7E7'
                } else {
                    el.parentNode.classList.remove('non-valid-input')
                    el.parentNode.children[2].style.display = 'none'
                    el.style.background = '#EAEDF0'
                    el.parentNode.style.background = '#EAEDF0'
                }
            }
        })
        if (numNonValidInp == 0) {
            if (currentPoint == 0) {
                data = {
                    "coord": [{
                        "lat": Number(latitude.value),
                        "lon": Number(longitude.value),
                        "alt": Number(height.value),
                        "encodedTime": UnixEpoch + new Date(document.querySelector('input.smallInp').value).getTime() * TicksPerMillisecond
                    }
                    ],
                    "addNoiseToIgrf": false
                }
                url = 'https://space.miem.hse.ru/IGRF.IGRFService/computeForPoint'
                //console.log('rest работает')
                fetch(url, {
                    /*mode: 'no-cors',*/
                    storigin: '*',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                }).then(text => {
                    return text.text()
                }).then((resp) => {
                    resp = JSON.parse(resp)
                    //console.log(resp)
                    document.querySelector('.igrfModal').style.display = 'flex'
                    document.querySelector('.appWrapper').classList.add('blurForVsvs');
                    document.querySelector('.igrfModal .x').textContent = 'x = ' + resp.result[0].x.toFixed(2)
                    document.querySelector('.igrfModal .y').textContent = 'y = ' + resp.result[0].y.toFixed(2)
                    document.querySelector('.igrfModal .z').textContent = 'z = ' + resp.result[0].z.toFixed(2)
                    document.onkeydown = function (evt) {
                        evt = evt || window.event;
                        var isEscape = false;
                        if ("key" in evt) {
                            isEscape = (evt.key === "Escape" || evt.key === "Esc");
                        } else {
                            isEscape = (evt.keyCode === 27);
                        }
                        if (isEscape) {
                            document.querySelector('.appWrapper').classList.remove('blurForVsvs');
                            document.querySelector('.igrfModal').style.display = 'none'
                        }
                        document.onkeydown = () => {
                        }
                    }

                })
            }
            if (currentPoint == 1 && TLE1.value != '' && TLE2.value != '') {
                fetch('https://space.miem.hse.ru/IGRF.IGRFService/construct', {
                    /*mode: 'no-cors',*/
                    origin: '*',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({
                        "first": TLE1.value,
                        "second": TLE2.value,
                        "title": 'title',
                        "observerLocations": []
                    })
                }).then(text => {
                    return text.text()
                }).then((dat) => {
                    data = {
                        "computationalId": JSON.parse(dat).computationalId,
                        "encodedTime": [Now()],
                        "addNoiseToSgp": false,
                        "addNoiseToIgrf": false
                    }
                    url = 'https://space.miem.hse.ru/IGRF.IGRFService/computeTLE'
                    //console.log('rest работает')
                    fetch(url, {
                        /*mode: 'no-cors',*/
                        origin: '*',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(data)
                    }).then(text => {
                        return text.text()
                    }).then((resp) => {
                        resp = JSON.parse(resp)
                        //console.log(resp)
                        if (resp.results == null) {
                            document.querySelector('.igrfModal').style.display = 'flex'
                            document.querySelector('.appWrapper').classList.add('blurForVsvs');
                            document.querySelector('.igrfModal .x').textContent = 'Введены некорректные данные'
                            document.querySelector('.igrfModal .y').textContent = ''
                            document.querySelector('.igrfModal .z').textContent = ''
                        } else {
                            document.querySelector('.igrfModal').style.display = 'flex'
                            document.querySelector('.appWrapper').classList.add('blurForVsvs');
                            document.querySelector('.igrfModal .x').textContent = 'x = ' + resp.results.result[0].x.toFixed(2)
                            document.querySelector('.igrfModal .y').textContent = 'y = ' + resp.results.result[0].y.toFixed(2)
                            document.querySelector('.igrfModal .z').textContent = 'z = ' + resp.results.result[0].z.toFixed(2)
                        }
                        document.onkeydown = function (evt) {
                            evt = evt || window.event;
                            var isEscape = false;
                            if ("key" in evt) {
                                isEscape = (evt.key === "Escape" || evt.key === "Esc");
                            } else {
                                isEscape = (evt.keyCode === 27);
                            }
                            if (isEscape) {
                                document.querySelector('.appWrapper').classList.remove('blurForVsvs');
                                document.querySelector('.igrfModal').style.display = 'none'
                            }
                            document.onkeydown = () => {
                            }
                        }

                    })
                })
            }
            if (currentPoint == 2 && TLEtime1.value != '' && TLEtime2.value != '') {
                fetch('https://space.miem.hse.ru/IGRF.IGRFService/construct', {
                    /*mode: 'no-cors',*/
                    origin: '*',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({
                        "first": TLEtime1.value,
                        "second": TLEtime2.value,
                        "title": 'title',
                        "observerLocations": []
                    })
                }).then(text => {
                    return text.text()
                }).then((dat) => {
                    data = {
                        "computationalId": JSON.parse(dat).computationalId,
                        "encodedTime": [UnixEpoch + new Date(document.querySelector('input.smallInp').value).getTime() * TicksPerMillisecond],
                        "addNoiseToSgp": false,
                        "addNoiseToIgrf": false
                    }
                    url = 'https://space.miem.hse.ru/IGRF.IGRFService/computeTLE'
                    //console.log('rest работает')
                    fetch(url, {
                        /*mode: 'no-cors',*/
                        origin: '*',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(data)
                    }).then(text => {
                        return text.text()
                    }).then((resp) => {
                        resp = JSON.parse(resp)
                        //console.log(resp)
                        if (resp.results == null) {
                            document.querySelector('.igrfModal').style.display = 'flex'
                            document.querySelector('.appWrapper').classList.add('blurForVsvs');
                            document.querySelector('.igrfModal .x').textContent = 'Введены некорректные данные'
                            document.querySelector('.igrfModal .y').textContent = ''
                            document.querySelector('.igrfModal .z').textContent = ''
                        } else {
                            document.querySelector('.igrfModal').style.display = 'flex'
                            document.querySelector('.appWrapper').classList.add('blurForVsvs');
                            document.querySelector('.igrfModal .x').textContent = 'x = ' + resp.results.result[0].x.toFixed(2)
                            document.querySelector('.igrfModal .y').textContent = 'y = ' + resp.results.result[0].y.toFixed(2)
                            document.querySelector('.igrfModal .z').textContent = 'z = ' + resp.results.result[0].z.toFixed(2)
                        }
                        document.onkeydown = function (evt) {
                            evt = evt || window.event;
                            var isEscape = false;
                            if ("key" in evt) {
                                isEscape = (evt.key === "Escape" || evt.key === "Esc");
                            } else {
                                isEscape = (evt.keyCode === 27);
                            }
                            if (isEscape) {
                                document.querySelector('.appWrapper').classList.remove('blurForVsvs');
                                document.querySelector('.igrfModal').style.display = 'none'
                            }
                            document.onkeydown = () => {
                            }
                        }
                    })
                })
            }
            if (currentPoint == 3 && TLEsgp1.value != '' && TLEsgp2.value != '') {
                fetch('https://space.miem.hse.ru/SGP.SGPService/SGPConstruct', {
                    /*mode: 'no-cors',*/
                    origin: '*',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify({
                        "first": TLEsgp1.value,
                        "second": TLEsgp2.value,
                        "title": 'title',
                        "observerLocations": []
                    })
                }).then(text => {
                    return text.text()
                }).then((dat) => {
                    data = {
                        "computationalId": JSON.parse(dat).computationalId,
                        "encodedTime": [UnixEpoch + new Date(document.querySelector('input.smallInp').value).getTime() * TicksPerMillisecond],
                        "coordType": "ECI",
                        "useNoise": false
                    }
                    url = 'https://space.miem.hse.ru/SGP.SGPService/SGPCompute'
                    //console.log('rest работает')
                    fetch(url, {
                        /*mode: 'no-cors',*/
                        origin: '*',
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(data)
                    }).then(text => {
                        return text.text()
                    }).then((resp) => {
                        resp = JSON.parse(resp)
                        //console.log(resp)
                        if (resp.code == 3) {
                            document.querySelector('.igrfModal').style.display = 'flex'
                            document.querySelector('.appWrapper').classList.add('blurForVsvs');
                            document.querySelector('.igrfModal .x').textContent = 'Введены некорректные данные'
                            document.querySelector('.igrfModal .y').textContent = ''
                            document.querySelector('.igrfModal .z').textContent = ''
                        } else {
                            document.querySelector('.igrfModal').style.display = 'flex'
                            document.querySelector('.appWrapper').classList.add('blurForVsvs');
                            document.querySelector('.igrfModal .x').textContent = 'x = ' + resp.eci[0].position.x.toFixed(2)
                            document.querySelector('.igrfModal .y').textContent = 'y = ' + resp.eci[0].position.y.toFixed(2)
                            document.querySelector('.igrfModal .z').textContent = 'z = ' + resp.eci[0].position.z.toFixed(2)
                        }

                        document.onkeydown = function (evt) {
                            evt = evt || window.event;
                            var isEscape = false;
                            if ("key" in evt) {
                                isEscape = (evt.key === "Escape" || evt.key === "Esc");
                            } else {
                                isEscape = (evt.keyCode === 27);
                            }
                            if (isEscape) {
                                document.querySelector('.appWrapper').classList.remove('blurForVsvs');
                                document.querySelector('.igrfModal').style.display = 'none'
                            }
                            document.onkeydown = () => {
                            }
                        }
                    })
                })
            }
        }
    }

    return (
        <div className="Igrf">
            <p className="ContactsText1">РАССЧЕТЫ</p>
            <p className="ContactsText2">Предоставляем расчеты магнитного поля и положения космического аппарата</p>
            <div className="carousel">
                <div className="prevPoint">
                    <img onClick={() => {
                        switchPoint(currentPoint - 1)
                    }} src={prevPoint} alt=""/>
                    <span onClick={() => {
                        switchPoint(currentPoint - 1)
                    }} className="prevText">{points[(currentPoint + 3) % 4].name}</span>
                </div>
                <div onTouchStart={onTouchStart} onTouchEnd={onTouchEnd} className="mainPoint">
                    <div style={{width: '100%'}} ref={curCarousel}>
                        <p className="mainPointText">{points[currentPoint].name}</p>
                        {points[currentPoint].inputs.map((el, i) => (
                            <Input id={el.id} key={i + el.name + currentPoint} type={el.type} style={el.style}
                                   classN={el.class} name={el.name}> </Input>
                        ))}
                        <Button rest={rest} id={'point_onclick'} name="РАСЧИТАТЬ"> </Button>
                    </div>
                    <div className="switchCircles">
                        <div onClick={() => switchPoint(0)} key={0} className={circles[0]}></div>
                        <div onClick={() => switchPoint(1)} key={1} className={circles[1]}></div>
                        <div onClick={() => switchPoint(2)} key={2} className={circles[2]}></div>
                        <div onClick={() => switchPoint(3)} key={3} className={circles[3]}></div>
                    </div>
                </div>
                <div className="nextPoint">
                    <span onClick={() => switchPoint(currentPoint + 1)}
                          className="nextText">{points[(currentPoint + 1) % 4].name}</span>
                    <img onClick={() => switchPoint(currentPoint + 1)} src={nextPoint} alt=""/>
                </div>
            </div>
            <div className="IgrfLastText">
                <p style={{color: 'white'}}>Далее: <br/>{points[(currentPoint + 1) % 4].name}</p>
                <p style={{color: 'rgba(255, 255, 255, 0.65)'}}>Назад к: <br/>{points[(currentPoint + 3) % 4].name}
                </p>
            </div>
        </div>
    );
}

export default Igrf;
