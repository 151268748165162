import './ContactsExitButton.css';
import React, {useRef} from "react";


function ContactsExitButton({exit, error}) {

    let addConfig = useRef()

    if (!error)
        return (
            <div ref={addConfig} onMouseDown={() => {
                addConfig.current.style.background = '#127C65'
            }} onMouseUp={() => {
                addConfig.current.style.background = '#13C39C'
            }} onMouseMove={(e) => {
                if (e.target == addConfig.current)
                    addConfig.current.style.background = `linear-gradient(90deg, #13C39C ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 - 15}%, #97DDCD ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100}%, #13C39C ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 + 10}%)`
                else {
                    addConfig.current.style.background = `linear-gradient(90deg, #13C39C ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 - 15}%, #97DDCD ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100}%, #13C39C ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 + 10}%)`
                }
            }} onMouseLeave={() => {
                addConfig.current.style.background = `#13C39C`
            }} id="success_exit" onClick={exit} className="ContactsExitButton ContactsSuccess">
                <div className="ButtonText">
                    Закрыть
                </div>
            </div>
        )
    else
        return (
            <div id="error_exit" ref={addConfig} onMouseDown={() => {
                addConfig.current.style.background = '#923131'
            }} onMouseUp={() => {
                addConfig.current.style.background = '#FB4B4B'
            }} onMouseMove={(e) => {
                if (e.target == addConfig.current)
                    addConfig.current.style.background = `linear-gradient(90deg, #FB4B4B ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 - 15}%, #FFD0D0 ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100}%, #FF0000 ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 + 10}%)`
                else {
                    addConfig.current.style.background = `linear-gradient(90deg, #FB4B4B ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 - 15}%, #FFD0D0 ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100}%, #FF0000 ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 + 10}%)`
                }
            }} onMouseLeave={() => {
                addConfig.current.style.background = `#FB4B4B`
            }} onClick={exit} className="ContactsExitButton ContactsError">
                <div className="ButtonText">
                    Попробовать снова
                </div>
            </div>
        );
}


export default ContactsExitButton;
