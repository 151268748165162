const {
    Empty,
    ModUnit,
    Pair,
    Configuration,
    ConfigurationName,
    ArchitecturesAndModules
} = require('../../static/js/proto/assembly_pb.js')
const {AssemblyServiceClient} = require('../../static/js//proto/assembly_grpc_web_pb.js');
const {isNull, now, floor, times} = require('lodash');

let OUT_CONF


export let funcConf = () => {

    let AssemblyService = new AssemblyServiceClient('/api/assembly/')
    let out_config = new Configuration();
    window.OUT_CONF = out_config
    out_config.setName('ISS')
    out_config.setArchitecture('u3')
    var slots = ["und", "und", "und", "und", "und", "und", "und", "und", "und", "und", "und", "und", "und", "und", "und"];
    let localization_dict = {};
    let localization_dict2 = {};
    let reverse_localization_dict = {};
    let req = new Empty();
    AssemblyService.returnArchitecturesAndModules(req, {}, (err, res) => {
        let modules = res.getAllModulesList();

        let architectures = res.getAllArchitecturesList();
        let modstring = "<option>Remove module</option>";
        for (let mod of modules) {
            //console.log(mod);
            modstring += "<option>" + mod.getName().replaceAll('_', ' ') + "</option>";
            localization_dict[mod.getName().replaceAll('_', ' ')] = [mod.getName(), mod.getSize()];
            reverse_localization_dict[mod.getName()] = [mod.getName().replaceAll('_', ' '), mod.getSize()];
        }
        //console.log(localization_dict, reverse_localization_dict)
        let archstring = "";
        for (let arch in architectures) {
            archstring += "<option>" + arch + "</option>";
        }
        localization_dict2 = {
            "Система энергопитания": ["power_supply_system", 1],
            "Приёмопередатчик": ["transceiver", 1],
            "Магнитометр": ["magnetometer", 1],
            "Датчик Солнца": ["sun_sensor", 1],
            "ДУС": ["avs", 1],
            "Бортовой компьютер": ["onboard_computer", 2],
            "Магнитные катушки": ["magnetic_coils", 2],
            "und": ["und", 0],
            "sth": ["sth", 0],
            "Маховик": ["flywheel", 3],
            "Камера": ["camera", 5]
        }

        //document.getElementById("select3").innerHTML = modstring;
        //console.log(localization_dict, reverse_localization_dict)
    });

    /* localization_dict = {
      "Система энергопитания" : ["power_supply_system", 1],
      "Приёмопередатчик" : ["transceiver", 1],
      "Магнитометр" : ["magnetometer", 1],
      "Датчик Солнца" : ["sun_sensor", 1],
      "ДУС" : ["avs", 1],
      "Бортовой компьютер" : ["onboard_computer", 2],
      "Магнитные катушки" : ["magnetic_coils", 2],
      "und" : ["und", 0],
      "sth" : ["sth", 0],
      "Маховик" : ["flywheel", 3],
      "Камера" : ["camera", 5]
    };*/

    var link_vsvs = document.querySelector('.vsvsForm2 .Button');
    link_vsvs.onclick = function () {
////////////////////////////Input and work with forms///////////////////////////
        var config_name = (document.querySelector(".SpacecraftName input").value);
        var architecture_name = (document.getElementById("select1").textContent);
        var column1 = parseInt(document.getElementById('select2').textContent);
        var column2 = document.getElementById('select3').textContent;
        var check = 0;

        let n_slots = (architecture_name == "u3" ? 15 : (architecture_name == "u2" ? 10 : 5));
        //console.log(n_slots);
        //process set
        let valid_set = false;
        if (column1 >= 0 && column1 < n_slots && localization_dict2.hasOwnProperty(column2)
            && column1 < n_slots + 1 - localization_dict2[column2][1]) {
            valid_set = true;
            for (let i = 0; i < localization_dict2[column2][1]; ++i) valid_set = valid_set && (slots[column1 + i] == "und");
            if (valid_set) {
                slots[column1] = localization_dict2[column2][0];
                for (let i = 1; i < localization_dict2[column2][1]; ++i) slots[column1 + i] = 'sth';
            }
        }

        //process clear
        let valid_clear = false;
        if (column1 >= 0 && column1 < n_slots && column2 == "Удалить модуль"
            && (reverse_localization_dict.hasOwnProperty(slots[column1]) || slots[column1] == 'sth')) {
            valid_clear = true;
            while (!reverse_localization_dict.hasOwnProperty(slots[column1])) column1 -= 1
            let val = reverse_localization_dict[slots[column1]][1];
            //console.log("clearing");
            for (let i = 0; i < reverse_localization_dict[slots[column1]][1]; ++i)
                document.getElementById('config' + Number(Number(column1) + i)).innerHTML = '';
            document.getElementById('config' + column1).style.fontWeight = '400'

            for (let i = 0; i < val; ++i) slots[column1 + i] = "und";
        }

        if (valid_set) {
            document.getElementById('config' + column1).style.fontWeight = '800'
            for (let i = 0; i < localization_dict2[column2][1]; ++i) {
                document.getElementById('config' + Number(Number(column1) + i)).innerHTML = column2;
            }
        }

        /*
          if (!valid_set && !valid_clear) {
            document.getElementById('history').innerHTML += column1 + " - " + column2 + " Action incorrect<br> ";
          } else {
            document.getElementById('history').innerHTML += column1 + " - " + column2 + " <br> ";
          }
        */

        ////////////////////////////Filling send message//////////////////////////////////////
        let sentToServer = new Configuration();
        sentToServer.setName(config_name);
        sentToServer.setArchitecture(architecture_name);

        for (let i = 0; i < 15; i++) {
            if (slots[i] != "und" && slots[i] != "sth") {
                let module_config = new ModUnit();
                module_config.setName(slots[i]);
                let pair = new Pair();
                pair.setKey(String(i));
                pair.setValue(module_config);
                sentToServer.addIncludedMod(pair);
            }
        }

        /////////////////////////////Sending to server////////////////////////////////////////
        AssemblyService.checkingConfiguration(sentToServer, {}, function (err, response) {
            if (err) {
                console.error(err);
                // document.getElementById("error_msg").innerHTML = err;
                out_config = {};
                return;
            }
            //document.getElementById("error_msg").innerHTML = "OK";
            out_config = new Configuration();
            out_config.setName(sentToServer.getName());
            out_config.setArchitecture(sentToServer.getArchitecture());
            //console.log(out_config)
            window.OUT_CONF = out_config

            for (let p of sentToServer.getIncludedModList()) {
                let module_config = new ModUnit();
                module_config.setName(p.getValue().getName());
                let pair = new Pair();
                pair.setKey(p.getKey());
                pair.setValue(module_config);
                out_config.addIncludedMod(pair);
            }
            //let config_res = response.getValue();
        });
        //console.log(slots);
    }

}
