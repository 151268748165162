// source: igrf_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var sgp_service_pb = require('./sgp_service_pb.js');
goog.object.extend(proto, sgp_service_pb);
goog.exportSymbol('proto.IGRF.EndRequest', null, global);
goog.exportSymbol('proto.IGRF.EndResponse', null, global);
goog.exportSymbol('proto.IGRF.Point', null, global);
goog.exportSymbol('proto.IGRF.PointResult', null, global);
goog.exportSymbol('proto.IGRF.TLEComputeRequest', null, global);
goog.exportSymbol('proto.IGRF.TLEComputeResponse', null, global);
goog.exportSymbol('proto.IGRF.igrf_computation_result', null, global);
goog.exportSymbol('proto.IGRF.igrf_computation_secular_variance', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IGRF.EndRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IGRF.EndRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IGRF.EndRequest.displayName = 'proto.IGRF.EndRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IGRF.EndResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IGRF.EndResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IGRF.EndResponse.displayName = 'proto.IGRF.EndResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IGRF.Point = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IGRF.Point.repeatedFields_, null);
};
goog.inherits(proto.IGRF.Point, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IGRF.Point.displayName = 'proto.IGRF.Point';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IGRF.PointResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IGRF.PointResult.repeatedFields_, null);
};
goog.inherits(proto.IGRF.PointResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IGRF.PointResult.displayName = 'proto.IGRF.PointResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IGRF.TLEComputeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.IGRF.TLEComputeRequest.repeatedFields_, null);
};
goog.inherits(proto.IGRF.TLEComputeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IGRF.TLEComputeRequest.displayName = 'proto.IGRF.TLEComputeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IGRF.TLEComputeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IGRF.TLEComputeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IGRF.TLEComputeResponse.displayName = 'proto.IGRF.TLEComputeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IGRF.igrf_computation_result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IGRF.igrf_computation_result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IGRF.igrf_computation_result.displayName = 'proto.IGRF.igrf_computation_result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.IGRF.igrf_computation_secular_variance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.IGRF.igrf_computation_secular_variance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.IGRF.igrf_computation_secular_variance.displayName = 'proto.IGRF.igrf_computation_secular_variance';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IGRF.EndRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.IGRF.EndRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IGRF.EndRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.EndRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    computationalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IGRF.EndRequest}
 */
proto.IGRF.EndRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IGRF.EndRequest;
  return proto.IGRF.EndRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IGRF.EndRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IGRF.EndRequest}
 */
proto.IGRF.EndRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComputationalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IGRF.EndRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IGRF.EndRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IGRF.EndRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.EndRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComputationalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string computational_id = 1;
 * @return {string}
 */
proto.IGRF.EndRequest.prototype.getComputationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IGRF.EndRequest} returns this
 */
proto.IGRF.EndRequest.prototype.setComputationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IGRF.EndResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.IGRF.EndResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IGRF.EndResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.EndResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IGRF.EndResponse}
 */
proto.IGRF.EndResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IGRF.EndResponse;
  return proto.IGRF.EndResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IGRF.EndResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IGRF.EndResponse}
 */
proto.IGRF.EndResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IGRF.EndResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IGRF.EndResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IGRF.EndResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.EndResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IGRF.Point.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IGRF.Point.prototype.toObject = function(opt_includeInstance) {
  return proto.IGRF.Point.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IGRF.Point} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.Point.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordList: jspb.Message.toObjectList(msg.getCoordList(),
    sgp_service_pb.CoordGeodetic.toObject, includeInstance),
    addNoiseToIgrf: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IGRF.Point}
 */
proto.IGRF.Point.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IGRF.Point;
  return proto.IGRF.Point.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IGRF.Point} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IGRF.Point}
 */
proto.IGRF.Point.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgp_service_pb.CoordGeodetic;
      reader.readMessage(value,sgp_service_pb.CoordGeodetic.deserializeBinaryFromReader);
      msg.addCoord(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddNoiseToIgrf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IGRF.Point.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IGRF.Point.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IGRF.Point} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.Point.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      sgp_service_pb.CoordGeodetic.serializeBinaryToWriter
    );
  }
  f = message.getAddNoiseToIgrf();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * repeated SGP.CoordGeodetic coord = 1;
 * @return {!Array<!proto.SGP.CoordGeodetic>}
 */
proto.IGRF.Point.prototype.getCoordList = function() {
  return /** @type{!Array<!proto.SGP.CoordGeodetic>} */ (
    jspb.Message.getRepeatedWrapperField(this, sgp_service_pb.CoordGeodetic, 1));
};


/**
 * @param {!Array<!proto.SGP.CoordGeodetic>} value
 * @return {!proto.IGRF.Point} returns this
*/
proto.IGRF.Point.prototype.setCoordList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.SGP.CoordGeodetic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SGP.CoordGeodetic}
 */
proto.IGRF.Point.prototype.addCoord = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.SGP.CoordGeodetic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IGRF.Point} returns this
 */
proto.IGRF.Point.prototype.clearCoordList = function() {
  return this.setCoordList([]);
};


/**
 * optional bool add_noise_to_igrf = 4;
 * @return {boolean}
 */
proto.IGRF.Point.prototype.getAddNoiseToIgrf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IGRF.Point} returns this
 */
proto.IGRF.Point.prototype.setAddNoiseToIgrf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IGRF.PointResult.repeatedFields_ = [1,2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IGRF.PointResult.prototype.toObject = function(opt_includeInstance) {
  return proto.IGRF.PointResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IGRF.PointResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.PointResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultList: jspb.Message.toObjectList(msg.getResultList(),
    proto.IGRF.igrf_computation_result.toObject, includeInstance),
    varianceList: jspb.Message.toObjectList(msg.getVarianceList(),
    proto.IGRF.igrf_computation_secular_variance.toObject, includeInstance),
    errorCodeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IGRF.PointResult}
 */
proto.IGRF.PointResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IGRF.PointResult;
  return proto.IGRF.PointResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IGRF.PointResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IGRF.PointResult}
 */
proto.IGRF.PointResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IGRF.igrf_computation_result;
      reader.readMessage(value,proto.IGRF.igrf_computation_result.deserializeBinaryFromReader);
      msg.addResult(value);
      break;
    case 2:
      var value = new proto.IGRF.igrf_computation_secular_variance;
      reader.readMessage(value,proto.IGRF.igrf_computation_secular_variance.deserializeBinaryFromReader);
      msg.addVariance(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedInt32() : [reader.readInt32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addErrorCode(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IGRF.PointResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IGRF.PointResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IGRF.PointResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.PointResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.IGRF.igrf_computation_result.serializeBinaryToWriter
    );
  }
  f = message.getVarianceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.IGRF.igrf_computation_secular_variance.serializeBinaryToWriter
    );
  }
  f = message.getErrorCodeList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
};


/**
 * repeated igrf_computation_result result = 1;
 * @return {!Array<!proto.IGRF.igrf_computation_result>}
 */
proto.IGRF.PointResult.prototype.getResultList = function() {
  return /** @type{!Array<!proto.IGRF.igrf_computation_result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IGRF.igrf_computation_result, 1));
};


/**
 * @param {!Array<!proto.IGRF.igrf_computation_result>} value
 * @return {!proto.IGRF.PointResult} returns this
*/
proto.IGRF.PointResult.prototype.setResultList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.IGRF.igrf_computation_result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IGRF.igrf_computation_result}
 */
proto.IGRF.PointResult.prototype.addResult = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.IGRF.igrf_computation_result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IGRF.PointResult} returns this
 */
proto.IGRF.PointResult.prototype.clearResultList = function() {
  return this.setResultList([]);
};


/**
 * repeated igrf_computation_secular_variance variance = 2;
 * @return {!Array<!proto.IGRF.igrf_computation_secular_variance>}
 */
proto.IGRF.PointResult.prototype.getVarianceList = function() {
  return /** @type{!Array<!proto.IGRF.igrf_computation_secular_variance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.IGRF.igrf_computation_secular_variance, 2));
};


/**
 * @param {!Array<!proto.IGRF.igrf_computation_secular_variance>} value
 * @return {!proto.IGRF.PointResult} returns this
*/
proto.IGRF.PointResult.prototype.setVarianceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.IGRF.igrf_computation_secular_variance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.IGRF.igrf_computation_secular_variance}
 */
proto.IGRF.PointResult.prototype.addVariance = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.IGRF.igrf_computation_secular_variance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IGRF.PointResult} returns this
 */
proto.IGRF.PointResult.prototype.clearVarianceList = function() {
  return this.setVarianceList([]);
};


/**
 * repeated int32 error_code = 3;
 * @return {!Array<number>}
 */
proto.IGRF.PointResult.prototype.getErrorCodeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.IGRF.PointResult} returns this
 */
proto.IGRF.PointResult.prototype.setErrorCodeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.IGRF.PointResult} returns this
 */
proto.IGRF.PointResult.prototype.addErrorCode = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IGRF.PointResult} returns this
 */
proto.IGRF.PointResult.prototype.clearErrorCodeList = function() {
  return this.setErrorCodeList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.IGRF.TLEComputeRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IGRF.TLEComputeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.IGRF.TLEComputeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IGRF.TLEComputeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.TLEComputeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    computationalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    encodedTimeList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    addNoiseToSgp: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    addNoiseToIgrf: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IGRF.TLEComputeRequest}
 */
proto.IGRF.TLEComputeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IGRF.TLEComputeRequest;
  return proto.IGRF.TLEComputeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IGRF.TLEComputeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IGRF.TLEComputeRequest}
 */
proto.IGRF.TLEComputeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComputationalId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEncodedTime(values[i]);
      }
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddNoiseToSgp(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAddNoiseToIgrf(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IGRF.TLEComputeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IGRF.TLEComputeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IGRF.TLEComputeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.TLEComputeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComputationalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEncodedTimeList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getAddNoiseToSgp();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getAddNoiseToIgrf();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string computational_id = 1;
 * @return {string}
 */
proto.IGRF.TLEComputeRequest.prototype.getComputationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.IGRF.TLEComputeRequest} returns this
 */
proto.IGRF.TLEComputeRequest.prototype.setComputationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated uint64 encoded_time = 2;
 * @return {!Array<number>}
 */
proto.IGRF.TLEComputeRequest.prototype.getEncodedTimeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.IGRF.TLEComputeRequest} returns this
 */
proto.IGRF.TLEComputeRequest.prototype.setEncodedTimeList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.IGRF.TLEComputeRequest} returns this
 */
proto.IGRF.TLEComputeRequest.prototype.addEncodedTime = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.IGRF.TLEComputeRequest} returns this
 */
proto.IGRF.TLEComputeRequest.prototype.clearEncodedTimeList = function() {
  return this.setEncodedTimeList([]);
};


/**
 * optional bool add_noise_to_sgp = 3;
 * @return {boolean}
 */
proto.IGRF.TLEComputeRequest.prototype.getAddNoiseToSgp = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IGRF.TLEComputeRequest} returns this
 */
proto.IGRF.TLEComputeRequest.prototype.setAddNoiseToSgp = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool add_noise_to_igrf = 4;
 * @return {boolean}
 */
proto.IGRF.TLEComputeRequest.prototype.getAddNoiseToIgrf = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IGRF.TLEComputeRequest} returns this
 */
proto.IGRF.TLEComputeRequest.prototype.setAddNoiseToIgrf = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IGRF.TLEComputeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.IGRF.TLEComputeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IGRF.TLEComputeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.TLEComputeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    results: (f = msg.getResults()) && proto.IGRF.PointResult.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IGRF.TLEComputeResponse}
 */
proto.IGRF.TLEComputeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IGRF.TLEComputeResponse;
  return proto.IGRF.TLEComputeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IGRF.TLEComputeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IGRF.TLEComputeResponse}
 */
proto.IGRF.TLEComputeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.IGRF.PointResult;
      reader.readMessage(value,proto.IGRF.PointResult.deserializeBinaryFromReader);
      msg.setResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IGRF.TLEComputeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IGRF.TLEComputeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IGRF.TLEComputeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.TLEComputeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResults();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.IGRF.PointResult.serializeBinaryToWriter
    );
  }
};


/**
 * optional PointResult results = 1;
 * @return {?proto.IGRF.PointResult}
 */
proto.IGRF.TLEComputeResponse.prototype.getResults = function() {
  return /** @type{?proto.IGRF.PointResult} */ (
    jspb.Message.getWrapperField(this, proto.IGRF.PointResult, 1));
};


/**
 * @param {?proto.IGRF.PointResult|undefined} value
 * @return {!proto.IGRF.TLEComputeResponse} returns this
*/
proto.IGRF.TLEComputeResponse.prototype.setResults = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.IGRF.TLEComputeResponse} returns this
 */
proto.IGRF.TLEComputeResponse.prototype.clearResults = function() {
  return this.setResults(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.IGRF.TLEComputeResponse.prototype.hasResults = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IGRF.igrf_computation_result.prototype.toObject = function(opt_includeInstance) {
  return proto.IGRF.igrf_computation_result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IGRF.igrf_computation_result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.igrf_computation_result.toObject = function(includeInstance, msg) {
  var f, obj = {
    sdate: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    declination: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    inclination: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    horizontalIntensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalIntensity: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    hasX: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    hasDeclination: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IGRF.igrf_computation_result}
 */
proto.IGRF.igrf_computation_result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IGRF.igrf_computation_result;
  return proto.IGRF.igrf_computation_result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IGRF.igrf_computation_result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IGRF.igrf_computation_result}
 */
proto.IGRF.igrf_computation_result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSdate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeclination(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInclination(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHorizontalIntensity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setZ(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalIntensity(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasX(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDeclination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IGRF.igrf_computation_result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IGRF.igrf_computation_result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IGRF.igrf_computation_result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.igrf_computation_result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSdate();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDeclination();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getInclination();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHorizontalIntensity();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalIntensity();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getHasX();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getHasDeclination();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional double sdate = 1;
 * @return {number}
 */
proto.IGRF.igrf_computation_result.prototype.getSdate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setSdate = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double declination = 2;
 * @return {number}
 */
proto.IGRF.igrf_computation_result.prototype.getDeclination = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setDeclination = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double inclination = 3;
 * @return {number}
 */
proto.IGRF.igrf_computation_result.prototype.getInclination = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setInclination = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double horizontal_intensity = 4;
 * @return {number}
 */
proto.IGRF.igrf_computation_result.prototype.getHorizontalIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setHorizontalIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double x = 5;
 * @return {number}
 */
proto.IGRF.igrf_computation_result.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double y = 6;
 * @return {number}
 */
proto.IGRF.igrf_computation_result.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double z = 7;
 * @return {number}
 */
proto.IGRF.igrf_computation_result.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double total_intensity = 8;
 * @return {number}
 */
proto.IGRF.igrf_computation_result.prototype.getTotalIntensity = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setTotalIntensity = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool has_x = 9;
 * @return {boolean}
 */
proto.IGRF.igrf_computation_result.prototype.getHasX = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setHasX = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool has_declination = 10;
 * @return {boolean}
 */
proto.IGRF.igrf_computation_result.prototype.getHasDeclination = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IGRF.igrf_computation_result} returns this
 */
proto.IGRF.igrf_computation_result.prototype.setHasDeclination = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.toObject = function(opt_includeInstance) {
  return proto.IGRF.igrf_computation_secular_variance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.IGRF.igrf_computation_secular_variance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.igrf_computation_secular_variance.toObject = function(includeInstance, msg) {
  var f, obj = {
    sdate: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    declinationDot: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    inclinationDot: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    horizontalIntensityDot: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    xDot: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    yDot: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    zDot: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    totalIntensityDot: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    hasX: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    hasDeclination: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.IGRF.igrf_computation_secular_variance}
 */
proto.IGRF.igrf_computation_secular_variance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.IGRF.igrf_computation_secular_variance;
  return proto.IGRF.igrf_computation_secular_variance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.IGRF.igrf_computation_secular_variance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.IGRF.igrf_computation_secular_variance}
 */
proto.IGRF.igrf_computation_secular_variance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSdate(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDeclinationDot(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setInclinationDot(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setHorizontalIntensityDot(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setXDot(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setYDot(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setZDot(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTotalIntensityDot(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasX(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasDeclination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.IGRF.igrf_computation_secular_variance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.IGRF.igrf_computation_secular_variance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.IGRF.igrf_computation_secular_variance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSdate();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getDeclinationDot();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getInclinationDot();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getHorizontalIntensityDot();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getXDot();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getYDot();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getZDot();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getTotalIntensityDot();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getHasX();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getHasDeclination();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional double sdate = 1;
 * @return {number}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getSdate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setSdate = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double declination_dot = 2;
 * @return {number}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getDeclinationDot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setDeclinationDot = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double inclination_dot = 3;
 * @return {number}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getInclinationDot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setInclinationDot = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double horizontal_intensity_dot = 4;
 * @return {number}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getHorizontalIntensityDot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setHorizontalIntensityDot = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double x_dot = 5;
 * @return {number}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getXDot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setXDot = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double y_dot = 6;
 * @return {number}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getYDot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setYDot = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double z_dot = 7;
 * @return {number}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getZDot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setZDot = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double total_intensity_dot = 8;
 * @return {number}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getTotalIntensityDot = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setTotalIntensityDot = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional bool has_x = 9;
 * @return {boolean}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getHasX = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setHasX = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional bool has_declination = 10;
 * @return {boolean}
 */
proto.IGRF.igrf_computation_secular_variance.prototype.getHasDeclination = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.IGRF.igrf_computation_secular_variance} returns this
 */
proto.IGRF.igrf_computation_secular_variance.prototype.setHasDeclination = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


goog.object.extend(exports, proto.IGRF);
