import './SideMenuPoint.css';
import {Link} from "react-router-dom";
import React from "react";

function SideMenuPoint({name, img, click, pathName, cur, num}) {

    let isActive = () => (
        num == cur ? 'SideMenuActive' : ''
    )
    //console.log(isActive(), num, cur)
    return (
        <Link to={`/${pathName}`}>
            <div onClick={() => {
                click(num)
            }} className={`SideMenuPoint SideMenuLink ${isActive()}`}>
                <img className="SideMenuPointImg" src={img} alt=""/>
                <span style={{textDecoration: 'none'}} className="SideMenuPointText">{name}</span>
            </div>
        </Link>
    );
}

export default SideMenuPoint;
