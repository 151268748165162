import './Face.css';
import DoubleButton from "./DoubleButton";
import faceSecondImg from '../images/FaceSecond.png'
import faceSecondImg2 from '../images/FaceSecond2.png'
import moon7 from '../images/Moon7.png'
import faceThirdImg from '../images/FaceThird.png'
import cosmonavt from '../images/cosmonavt3.png'
import Logo from "./Logo";
import Nav from "./Nav";
import {observer} from "../index";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import React from "react";
import jumpDownImg from "../images/jumpDown1.svg"
import jumpDownHoverImg from "../images/jumpDownHover.svg"
import MoonImg from "../images/Moon.png"
import jumpUpImg from "../images/jumpUp.svg"
import jumpUpHoverImg from "../images/jumpUpHover.png"

function Face({store, oneLine, currentMenu, updateCurrentMenu, scrollFunc}) {

    let scrollUpDown = (top) => {
        window.scroll({
            top: top,
            behavior: 'smooth'
        })
    }

    let upJump = 1
    let [jumpUpImgState, setJumpUpImgState] = useState(jumpUpImg)

    let windowScroll = () => {
        let jU = document.querySelector('.jumpUp')
        let fS = document.querySelector('.FaceSecond')
        if (upJump == 1)
            jU.style.top = 500 + 'px'
        if (upJump == 1) {
            if (window.scrollY > fS.offsetTop) {
                upJump = 2
                jU.classList.add('jumpUpFixed')
                jU.style.top = jU.offsetTop + 'px'
                jU.style.left = fS.offsetLeft + fS.offsetWidth - 80 + 'px'
                //console.log('dfsjfdsk')
            }
        }
        if (upJump == 2) {
            if (window.scrollY < fS.offsetTop) {
                jU.classList.remove('jumpUpFixed')
                jU.style.top = 500 + 'px'
                jU.style.left = fS.offsetWidth - 80 + 'px'
                upJump = 1
                //console.log('dfsjfdsk')
            }
        }
    }

    let moonRef = useRef()
    let jumpDownRef = useRef()
    let [jumpDownImgState, setJumpDownImgState] = useState(jumpDownImg)

    scrollFunc(windowScroll)

    let textMore = useRef()
    let imgMore = useRef()
    let FaceSecondText = useRef()
    let readMoreButton = useRef()

    let readMore = () => {
        imgMore.current.style.display = 'block'
        FaceSecondText.current.className = ('classForMoreText')
        let FaceSecondTextChildren = Array(...FaceSecondText.current.children)
        //console.log(FaceSecondTextChildren)
        FaceSecondTextChildren[0].style.display = 'none'
        document.querySelector('.width768_continue').children[1].style.display = 'none'
        FaceSecondTextChildren[2].style.display = 'inline'
        FaceSecondTextChildren[5].style.display = 'inline'
        readMoreButton.current.style.display = 'none'
    }

    useEffect(() => {
        document.querySelector('.jumpUp').style.left = document.querySelector('.FaceSecond').offsetWidth - 80 + 'px'
        window.addEventListener('scroll', windowScroll)
        //console.log('добавили событие скролла')
        return () => {
            window.removeEventListener('scroll', windowScroll)
        }
    }, [])

    return (
        <div className="Face">
            <div ref={moonRef} className="FaceFirst">
                <div style={{background: `url('${MoonImg}')`}} className="Moon"></div>
                <div style={{zIndex: 15, position: 'relative'}} className="FaceFirstP1">
                    посмотри на космос <br/> <span className="FaceFirstP2">С<span
                    className="FaceFirstP1Bold"> другого</span> ракурса</span>
                </div>
                <div style={{zIndex: 15, position: 'relative'}} className="FaceFirstP3">
                    Виртуально запусти свой малый космический аппарат на орбиту прямо сейчас!
                    <br/>
                    <a target="_blank" href="https://miem.hse.ru/uvpmiem/lab/ka/satteliteais?__t=7194897&_r=19082801669718425.93581&__r=OK">Данные АИС-мониторинга</a>
                </div>
                <div className="DB1" style={{zIndex: 15}}>
                    <Link onClick={() => {
                        updateCurrentMenu(1);
                        observer()
                    }} to="/vsvs"><DoubleButton name="перейти в вквс"></DoubleButton></Link>
                </div>
            </div>
            <div ref={jumpDownRef}
                 onClick={() => {
                     scrollUpDown(document.querySelector('.FaceSecond').offsetTop)
                 }}
                 onMouseEnter={() => {
                     setJumpDownImgState(jumpDownHoverImg)
                 }}
                 onMouseLeave={() => {
                     setJumpDownImgState(jumpDownImg)
                 }}
                 className="jumpDown">
                <img src={jumpDownImgState} style={{width: '100%', height: '100%'}} alt=""/>
            </div>
            <div className="FaceSecond">
                <div className="FaceSecondMain">о лаборатории</div>
                <div className="FaceSecondFlex">
                    <div ref={FaceSecondText} className="FaceSecondText">
                        УИЛ ФБКАиС на протяжении многих лет ведёт деятельность в области разработки программного
                        обеспечения, разработки методических рекомендаций к различным курсам, связанным с
                        космической тематикой, разработки полезных нагрузок на космические аппараты. Сотрудники
                        лаборатории являются авторами<span className="width768">...</span><span
                        className="width768_continue"> множества публикаций в областях инновационных материалов,
                безопасности систем космических аппаратов, космического воздействия, приборостроения и
                разработки программного обеспечения <a className="hrefFromFaceSecond"
                                                       href="https://miem.hse.ru/uvpmiem/lab/ka/publication">[ссылка]</a>. Одной
                из областей деятельности лаборатории является дополнительное образование. В стенах
                лаборатории проводятся курсы в рамках программ Инженерный класс в Московской школе и ИТ
                класс в Московской школе, а также семинары и мастер-классы по тематики космических
                технологий и<span className="width1440">...</span></span><span className="width1440_continue">проектной деятельности в области спутникостроения и геоинформационных систем.
                Также результатом работы лаборатории является проведение школьных олимпиад и конкурсов,
                таких как Московская предпрофессиональная олимпиада и конкурс Спутникостроение и
                геоинформационные технологии: Terra Notum. Сотудники УИЛ ФБКАиС в 2019 году приняли участие
                в образовательной смене в образовательном центре “Сириус”, где совместно со школьниками
                провели эксперименты с аппаратом CUBE_SX_SIRIUS_HSE.</span><br/><br/>
                        <p style={{display: 'none'}} ref={textMore}>Среди партнеров лаборатории ведущие
                            Российские
                            космические компании, специализирующиеся в области разработки и запуска спутников, а
                            также в области спутниковых данных информации с них.
                            В 2016 году сотрудники УИЛ ФБКАиС начали разработку собственного спутника, совместно
                            с
                            партнерами ООО «Спутникс», заложив начало деятельности лаборатории, связанной с
                            разработкой программных и аппаратных составляющих спутников, проведения
                            экспериментов а
                            также образовательных программ, посвящённым им.
                            Летом 2019 инженерный прототип спутника CubeSX-HSE стал частью конкурса «Большие
                            вызовы»
                            образовательного центра “Сириус”, в рамках проекта разработки спутниковой системы,
                            работу над которым вели одаренные школьники со все России, а также сотрудники и
                            партнеры
                            лаборатории.
                            22 марта 2021 года в 9:07 по московскому времени был осуществлен успешный запуск
                            ракеты-носителя «Союз-2.1а» с разгонным блоком «Фрегат». На солнечно-синхронные
                            орбиты
                            были выведены 38 космических аппаратов из 18 стран, среди которых – спутники НИУ ВШЭ
                            CubeSX-HSE и CubeSX-Sirius-HSE.
                        </p>
                    </div>
                    <div className="FaceSecondImages">
                        <img src={faceSecondImg} alt="" className="FaceSecondImg"/>
                        <img ref={imgMore} src={faceSecondImg2} alt="" style={{display: 'none'}}
                             className="FaceSecondImg FaceSecondImg2"/>
                    </div>
                </div>
                <div ref={readMoreButton} onClick={readMore} className="DB2"
                     style={{margin: '0 auto', height: '100px', width: '540px'}}>
                    <DoubleButton name="читать дальше"></DoubleButton>
                </div>
                <div onClick={() => {
                    scrollUpDown(0)
                }} onMouseEnter={() => {
                    setJumpUpImgState(jumpUpHoverImg)
                }}
                     onMouseLeave={() => {
                         setJumpUpImgState(jumpUpImg)
                     }}
                     style={{background: `url('${jumpUpImgState}')`}} className="jumpUp"></div>
            </div>
            <img src={moon7} alt="" className="Moon7"/>
            <div className="FaceThird">
                <div className="FaceThirdMain">разработка</div>
                <div className="FaceThirdFlex">
                    <img src={faceThirdImg} alt="" className="FaceThirdImg"/>
                    <div className="FaceThirdText">
                        <p className="FaceThirdP1">В настоящее время сотрудники УИЛ ФБКАиС стремятся сделать
                            космос
                            ближе для вас, позволив каждому примерить на себя роль разработчика космического
                            аппарата. Для этого мы разрабатываем Виртуальный Космос Виртуальный Спутник
                            (ВКВС).</p>
                        <p className="FaceThirdP2"><span className="FaceThirdSpan">ВКВС</span> – это полноценный
                            учебный курс, опирающийся на симулятор космического пространства, разработанный
                            студентами НИУ ВШЭ и сотрудниками УИЛ ФБКАиС.</p>
                        <p className="FaceThirdP3">Данный продукт позволяет наглядно и близко познакомиться с
                            процессом проектирования и программирования космического аппарата, а также увидеть
                            его
                            движение в невесомости орбитального полета.</p>
                    </div>
                </div>
                <div className="FaceThirdFlex2">
                    <div style={{width: '100%'}}>
                        <div className="FaceThirdText2">
                            не упускай возможность. запусти свой малый космический аппарат на орбиту прямо
                            сейчас!
                        </div>
                        <div className="DB3" style={{width: '540px', height: '80px'}}>
                            <Link onClick={() => {
                                updateCurrentMenu(1);
                                observer()
                            }} to="/vsvs"><DoubleButton name="перейти в вквс"></DoubleButton></Link>
                        </div>
                    </div>
                    <div>
                        <img src={cosmonavt} alt="" className="FaceThirdImg2"/>
                    </div>
                </div>
            </div>
            <div className="Footer">
                <div className="FooterLine"></div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px',
                    justifyContent: 'center',
                    width: 'calc(100% - 20px)'
                }}>
                    <Logo updateCurrentMenu={updateCurrentMenu}></Logo>
                    <Nav flag={0} store={store} oneLine={oneLine} currentMenu={currentMenu}
                         updateCurrentMenu={updateCurrentMenu}></Nav>
                </div>
            </div>
        </div>
    )
}

export default Face;
