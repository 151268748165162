import './Button.css';
import suc from '../images/successContact.png'
import React, {useRef} from "react";

function Button({name, id, rest, validate, validateError, create}) {
    let refs
    let addConfig = useRef()
    let postRequest = () => {
        if (id == 'contact_button') {
            if (validate) {
                const data = new URLSearchParams();

	        data.append("name", document.getElementById("fio_contacts").value)
		data.append("email", document.getElementById("email_contacts").value)
	        data.append("text", document.getElementById("Inputmessage_contacts").value)

                for (var key of data.entries()) {
        console.log(key[0] + ', ' + key[1]);
    }
                fetch('https://space.miem.hse.ru/api/feedback', {
                    /*mode: 'no-cors',*/
                    origin: '*',
                    method: 'POST',
		    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: data
                }).then(text => {
                    return text
                }).then((resp) => {
                    //console.log(resp)
                    create(0)
                }).catch((error) => {
                    //console.log(error)
                    create(1)
                });
            } else validateError()

        }
        if (id == 'point_onclick') {
            rest()
        }
    }

	if (id == 'contact_button' && validate) {
    return (
	    <div id="requestDiv" className="RequestDiv">
        <input onClick={e => { e.preventDefault(); postRequest() }} type="submit" onMouseDown={() => {
            addConfig.current.style.background = '#084479'
        }} onMouseUp={() => {
            addConfig.current.style.background = '#005AAB'
        }} ref={addConfig} onMouseMove={(e) => {
            if (e.target == addConfig.current)
                addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100}%, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 + 10}%)`
            else {
                addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100}%, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 + 10}%)`
            }
        }} onMouseLeave={() => {
            addConfig.current.style.background = `#005AAB`
        }} id={id} className="Button" value="ОТПРАВИТЬ">
        </input>
	    </div>
    );
	}
	else {
    return (
        <div onMouseDown={() => {
            addConfig.current.style.background = '#084479'
        }} onMouseUp={() => {
            addConfig.current.style.background = '#005AAB'
        }} ref={addConfig} onMouseMove={(e) => {
            if (e.target == addConfig.current)
                addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100}%, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 + 10}%)`
            else {
                addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100}%, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 + 10}%)`
            }
        }} onMouseLeave={() => {
            addConfig.current.style.background = `#005AAB`
        }} id={id} onClick={postRequest} className="Button">
            <div className="ButtonText">
                {name}
            </div>
        </div>
    );
        }

}

export default Button;
