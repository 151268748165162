import './App.css';
import Navbar from "./Components/Navbar";
import Contacts from "./Components/Contacts";
import Face from "./Components/Face";
import Emulator from "./Components/Emulator";
import Igrf from "./Components/Igrf";
import SideMenu from "./Components/SideMenu";
import ModalForVsvs from "./Components/ModalForVsvs";
import Modal from "./Components/Modal";
import ModalContacts from "./Components/ModalContacts";
import {Switch, Route, BrowserRouter as Router} from "react-router-dom";
import {observer} from "./index";
import {useRef, useState, useEffect} from "react";
import Vsvs from "./Components/Vsvs";
import React from "react";
import exit from './images/Close.svg'
import exitHover from './images/CloseHover.svg'
import Ais_adsb from './Components/Ais_adsb'

function App({store}) {

    let [contentLoaded, setContentLoaded] = useState(false)

    let [exitState, setExitState] = useState(exit)

    window.addEventListener('load', () => {
        setContentLoaded(true)
    })

    let [scriptVSVS, setScriptVSVS] = useState(null)

    useEffect(() => {
        let lastScript = document.head.querySelectorAll('script')[1]
        setScriptVSVS(lastScript)
        //document.head.removeChild(lastScript)
    }, [])

    let PageNotFound = () => {
        return (
            <div className="PageNotFound">Error 404</div>
        )
    }

    let [currentMenu, setCurrentMenu] = useState()
    let [contactsError, setContactsError] = useState()

    let scrollFunc
    let scrollFunc2 = (e) => {
        scrollFunc = e
    }

    let refSideMenu = useRef()
    let refModalVsvs = useRef()
    let refModal = useRef()
    let refModalContacts = useRef()

    let sideMenuFunc = () => {
        observer()
        refSideMenu.current.style.pointerEvents = 'all'
        refSideMenu.current.style.opacity = '0'
        refSideMenu.current.style.opacity = '1'
        document.querySelector('.appWrapper').className = 'blur'
        document.querySelector('.App').onclick = (e) => {
            const withinBoundaries = e.composedPath().includes(document.querySelector('.SideMenu'));
            if (!withinBoundaries) {
                sideMenuExit()
            }
        }
        window.removeEventListener('scroll', scrollFunc)
    }

    let sideMenuExit = (i) => {
        setCurrentMenu(i)
        observer()
        window.scroll({
            top: 0
        })
        refSideMenu.current.style.pointerEvents = 'none'
        refSideMenu.current.style.opacity = '0'
        document.querySelector('.blur').className = 'appWrapper'
        document.querySelector('.App').onclick = () => {
        }
    }

    let modalFunc = () => {
        observer()
        refModal.current.style.display = 'flex'
        refModal.current.style.pointerEvents = 'all'
        refModal.current.style.opacity = '0'
        refModal.current.style.opacity = '1'
        document.querySelector('.appWrapper').className = 'blur'
        document.querySelector('.App').onclick = (e) => {
            const withinBoundaries = e.composedPath().includes(document.querySelector('.modal-main'));
            if (!withinBoundaries) {
                modalExit()
            }
        }
        document.body.style.overflow = 'hidden'
        window.removeEventListener('scroll', scrollFunc)
   }

   let modalExit = () => {
        observer()
        window.scroll({
            top: 0
        })
        refModal.current.style.display = 'none'
        refModal.current.style.pointerEvents = 'none'
        refModal.current.style.opacity = '0'
        document.querySelector('.App').onclick = (e) => {
        }
        document.body.style.overflow = 'auto'
        document.querySelector('.blur').className = 'appWrapper'
    }

    let modalContactsFunc = (error) => {
        setContactsError(error)
        observer()
        refModalContacts.current.style.display = 'flex'
        refModalContacts.current.style.pointerEvents = 'all'
        refModalContacts.current.style.opacity = '0'
        refModalContacts.current.style.opacity = '1'
        document.querySelector('.appWrapper').className = 'blur'
        document.querySelector('.App').onclick = (e) => {
            const withinBoundaries = e.composedPath().includes(document.querySelector('.modal-main-contacts'));
            if (!withinBoundaries) {
                modalContactsExit()
            }
        }
        document.body.style.overflow = 'hidden'
        window.removeEventListener('scroll', scrollFunc)
    }


    let modalContactsExit = () => {
        observer()
        window.scroll({top: 0})
        refModalContacts.current.style.display = 'none'
        refModalContacts.current.style.pointerEvents = 'none'
        refModalContacts.current.style.opacity = '0'
        document.querySelector('.App').onclick = (e) => {
        }
        document.body.style.overflow = 'auto'
        document.querySelector('.blur').className = 'appWrapper'
    }

    return (
        <div style={{minHeight: window.innerHeight - 1}} className="App">
            <Router>
                <div style={{minHeight: window.innerHeight - 1}} className="appWrapper">
                    <Navbar burgerMenu={sideMenuFunc} store={store.NavElements} currentMenu={currentMenu}
                            updateCurrentMenu={setCurrentMenu}></Navbar>
                    <Switch>
                        <Route exact path="/">
                            {contentLoaded ?
                                <Face scrollFunc={scrollFunc2} burgerMenu={sideMenuFunc} store={store.NavElements}
                                      currentMenu={currentMenu} updateCurrentMenu={setCurrentMenu}></Face> :
                                <div style={{color: 'white', fontSize: '50px'}}></div>
                            }
                        </Route>
                        <Route path="/emulator">
                            <Emulator create={modalFunc}></Emulator>
                        </Route>
                        <Route path="/vsvs">
                            <Vsvs script={scriptVSVS} refOnCosmos={refModalVsvs}></Vsvs>
                        </Route>
                        <Route path="/igrf">
                            <Igrf></Igrf>
                        </Route>
                        <Route path="/contacts">
                            <Contacts create={modalContactsFunc}></Contacts>
                        </Route>
                        <Route>
                            <PageNotFound></PageNotFound>
                        </Route>
                    </Switch>
                </div>
                <SideMenu ref={refSideMenu} currentMenu={currentMenu} exit={sideMenuExit}></SideMenu>
                <ModalForVsvs ref={refModalVsvs}></ModalForVsvs>
                <Modal exit={modalExit} title="ПОЛУЧЕННОЕ ФОТО С СЕРВЕРА" first="Исходное" second="Конечное"
                       ref={refModal}></Modal>
                <div style={{position: 'absolute', margin: 'auto auto', display: 'none'}} className="igrfModal">
                    <div onClick={() => {
                        document.querySelector('.appWrapper').classList.remove('blurForVsvs');
                        document.querySelector('.igrfModal').style.display = 'none'
                    }
                    } onMouseEnter={() => {
                        setExitState(exitHover)
                    }}
                         onMouseLeave={() => {
                             setExitState(exit)
                         }}
                         className="exitIgrfModal" style={{
                        width: '30px',
                        height: '30px',
                        top: '0',
                        right: '-50px',
                        position: 'absolute',
                        cursor: 'pointer'
                    }}>
                        <img src={exitState} style={{width: '100%', height: '100%'}} alt=""/>
                    </div>
                    <div className="x"></div>
                    <div className="y"></div>
                    <div className="z"></div>
                </div>

                <ModalContacts exit={modalContactsExit} error={contactsError} ref={refModalContacts}></ModalContacts>
            </Router>
        </div>
    );
}

export default App;
