// source: aggregator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var sgp_service_pb = require('./sgp_service_pb.js');
goog.object.extend(proto, sgp_service_pb);
var igrf_service_pb = require('./igrf_service_pb.js');
goog.object.extend(proto, igrf_service_pb);
var assembly_pb = require('./assembly_pb.js');
goog.object.extend(proto, assembly_pb);
goog.exportSymbol('proto.Aggregator.ComputationRequest', null, global);
goog.exportSymbol('proto.Aggregator.ConstructResponse', null, global);
goog.exportSymbol('proto.Aggregator.EndRequest', null, global);
goog.exportSymbol('proto.Aggregator.EndResponse', null, global);
goog.exportSymbol('proto.Aggregator.Frame', null, global);
goog.exportSymbol('proto.Aggregator.Frames', null, global);
goog.exportSymbol('proto.Aggregator.KV', null, global);
goog.exportSymbol('proto.Aggregator.LogMessage', null, global);
goog.exportSymbol('proto.Aggregator.Matrix', null, global);
goog.exportSymbol('proto.Aggregator.ModuleState', null, global);
goog.exportSymbol('proto.Aggregator.Parameters', null, global);
goog.exportSymbol('proto.Aggregator.SatelliteState', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.ConstructResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Aggregator.ConstructResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.ConstructResponse.displayName = 'proto.Aggregator.ConstructResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.Parameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Aggregator.Parameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.Parameters.displayName = 'proto.Aggregator.Parameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.ComputationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Aggregator.ComputationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.ComputationRequest.displayName = 'proto.Aggregator.ComputationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.LogMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Aggregator.LogMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.LogMessage.displayName = 'proto.Aggregator.LogMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.Matrix = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Aggregator.Matrix, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.Matrix.displayName = 'proto.Aggregator.Matrix';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.KV = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Aggregator.KV, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.KV.displayName = 'proto.Aggregator.KV';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.ModuleState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Aggregator.ModuleState.repeatedFields_, null);
};
goog.inherits(proto.Aggregator.ModuleState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.ModuleState.displayName = 'proto.Aggregator.ModuleState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.SatelliteState = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Aggregator.SatelliteState.repeatedFields_, null);
};
goog.inherits(proto.Aggregator.SatelliteState, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.SatelliteState.displayName = 'proto.Aggregator.SatelliteState';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.Frame = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Aggregator.Frame.repeatedFields_, null);
};
goog.inherits(proto.Aggregator.Frame, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.Frame.displayName = 'proto.Aggregator.Frame';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.Frames = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.Aggregator.Frames.repeatedFields_, null);
};
goog.inherits(proto.Aggregator.Frames, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.Frames.displayName = 'proto.Aggregator.Frames';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.EndRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Aggregator.EndRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.EndRequest.displayName = 'proto.Aggregator.EndRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Aggregator.EndResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Aggregator.EndResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Aggregator.EndResponse.displayName = 'proto.Aggregator.EndResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.ConstructResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.ConstructResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.ConstructResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.ConstructResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    computationalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    compilationReport: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.ConstructResponse}
 */
proto.Aggregator.ConstructResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.ConstructResponse;
  return proto.Aggregator.ConstructResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.ConstructResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.ConstructResponse}
 */
proto.Aggregator.ConstructResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComputationalId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompilationReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.ConstructResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.ConstructResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.ConstructResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.ConstructResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComputationalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompilationReport();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string computational_id = 1;
 * @return {string}
 */
proto.Aggregator.ConstructResponse.prototype.getComputationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.ConstructResponse} returns this
 */
proto.Aggregator.ConstructResponse.prototype.setComputationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string compilation_report = 2;
 * @return {string}
 */
proto.Aggregator.ConstructResponse.prototype.getCompilationReport = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.ConstructResponse} returns this
 */
proto.Aggregator.ConstructResponse.prototype.setCompilationReport = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.Parameters.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.Parameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.Parameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.Parameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    orbit: (f = msg.getOrbit()) && sgp_service_pb.SGPConstructRequest.toObject(includeInstance, f),
    startTime: jspb.Message.getFieldWithDefault(msg, 2, 0),
    dt: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    config: (f = msg.getConfig()) && assembly_pb.Configuration.toObject(includeInstance, f),
    code: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.Parameters}
 */
proto.Aggregator.Parameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.Parameters;
  return proto.Aggregator.Parameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.Parameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.Parameters}
 */
proto.Aggregator.Parameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgp_service_pb.SGPConstructRequest;
      reader.readMessage(value,sgp_service_pb.SGPConstructRequest.deserializeBinaryFromReader);
      msg.setOrbit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setStartTime(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDt(value);
      break;
    case 4:
      var value = new assembly_pb.Configuration;
      reader.readMessage(value,assembly_pb.Configuration.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.Parameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.Parameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.Parameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.Parameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrbit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgp_service_pb.SGPConstructRequest.serializeBinaryToWriter
    );
  }
  f = message.getStartTime();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getDt();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      assembly_pb.Configuration.serializeBinaryToWriter
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional SGP.SGPConstructRequest orbit = 1;
 * @return {?proto.SGP.SGPConstructRequest}
 */
proto.Aggregator.Parameters.prototype.getOrbit = function() {
  return /** @type{?proto.SGP.SGPConstructRequest} */ (
    jspb.Message.getWrapperField(this, sgp_service_pb.SGPConstructRequest, 1));
};


/**
 * @param {?proto.SGP.SGPConstructRequest|undefined} value
 * @return {!proto.Aggregator.Parameters} returns this
*/
proto.Aggregator.Parameters.prototype.setOrbit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.Parameters} returns this
 */
proto.Aggregator.Parameters.prototype.clearOrbit = function() {
  return this.setOrbit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.Parameters.prototype.hasOrbit = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 start_time = 2;
 * @return {number}
 */
proto.Aggregator.Parameters.prototype.getStartTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Aggregator.Parameters} returns this
 */
proto.Aggregator.Parameters.prototype.setStartTime = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional double dt = 3;
 * @return {number}
 */
proto.Aggregator.Parameters.prototype.getDt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Aggregator.Parameters} returns this
 */
proto.Aggregator.Parameters.prototype.setDt = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional Assembly.Configuration config = 4;
 * @return {?proto.Assembly.Configuration}
 */
proto.Aggregator.Parameters.prototype.getConfig = function() {
  return /** @type{?proto.Assembly.Configuration} */ (
    jspb.Message.getWrapperField(this, assembly_pb.Configuration, 4));
};


/**
 * @param {?proto.Assembly.Configuration|undefined} value
 * @return {!proto.Aggregator.Parameters} returns this
*/
proto.Aggregator.Parameters.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.Parameters} returns this
 */
proto.Aggregator.Parameters.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.Parameters.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string code = 5;
 * @return {string}
 */
proto.Aggregator.Parameters.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.Parameters} returns this
 */
proto.Aggregator.Parameters.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.ComputationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.ComputationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.ComputationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.ComputationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    computationalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    frameAmount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.ComputationRequest}
 */
proto.Aggregator.ComputationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.ComputationRequest;
  return proto.Aggregator.ComputationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.ComputationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.ComputationRequest}
 */
proto.Aggregator.ComputationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComputationalId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameAmount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.ComputationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.ComputationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.ComputationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.ComputationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComputationalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrameAmount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional string computational_id = 1;
 * @return {string}
 */
proto.Aggregator.ComputationRequest.prototype.getComputationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.ComputationRequest} returns this
 */
proto.Aggregator.ComputationRequest.prototype.setComputationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint64 frame_amount = 2;
 * @return {number}
 */
proto.Aggregator.ComputationRequest.prototype.getFrameAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Aggregator.ComputationRequest} returns this
 */
proto.Aggregator.ComputationRequest.prototype.setFrameAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.LogMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.LogMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.LogMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.LogMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    dtime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    frameNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    level: jspb.Message.getFieldWithDefault(msg, 3, ""),
    origin: jspb.Message.getFieldWithDefault(msg, 4, ""),
    text: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.LogMessage}
 */
proto.Aggregator.LogMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.LogMessage;
  return proto.Aggregator.LogMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.LogMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.LogMessage}
 */
proto.Aggregator.LogMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDtime(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setFrameNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLevel(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrigin(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.LogMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.LogMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.LogMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.LogMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDtime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getFrameNumber();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getLevel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrigin();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional uint64 dtime = 1;
 * @return {number}
 */
proto.Aggregator.LogMessage.prototype.getDtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.Aggregator.LogMessage} returns this
 */
proto.Aggregator.LogMessage.prototype.setDtime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 frame_number = 2;
 * @return {number}
 */
proto.Aggregator.LogMessage.prototype.getFrameNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.Aggregator.LogMessage} returns this
 */
proto.Aggregator.LogMessage.prototype.setFrameNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string level = 3;
 * @return {string}
 */
proto.Aggregator.LogMessage.prototype.getLevel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.LogMessage} returns this
 */
proto.Aggregator.LogMessage.prototype.setLevel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string origin = 4;
 * @return {string}
 */
proto.Aggregator.LogMessage.prototype.getOrigin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.LogMessage} returns this
 */
proto.Aggregator.LogMessage.prototype.setOrigin = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string text = 5;
 * @return {string}
 */
proto.Aggregator.LogMessage.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.LogMessage} returns this
 */
proto.Aggregator.LogMessage.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.Matrix.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.Matrix.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.Matrix} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.Matrix.toObject = function(includeInstance, msg) {
  var f, obj = {
    a: (f = msg.getA()) && sgp_service_pb.Vector.toObject(includeInstance, f),
    b: (f = msg.getB()) && sgp_service_pb.Vector.toObject(includeInstance, f),
    c: (f = msg.getC()) && sgp_service_pb.Vector.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.Matrix}
 */
proto.Aggregator.Matrix.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.Matrix;
  return proto.Aggregator.Matrix.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.Matrix} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.Matrix}
 */
proto.Aggregator.Matrix.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgp_service_pb.Vector;
      reader.readMessage(value,sgp_service_pb.Vector.deserializeBinaryFromReader);
      msg.setA(value);
      break;
    case 2:
      var value = new sgp_service_pb.Vector;
      reader.readMessage(value,sgp_service_pb.Vector.deserializeBinaryFromReader);
      msg.setB(value);
      break;
    case 3:
      var value = new sgp_service_pb.Vector;
      reader.readMessage(value,sgp_service_pb.Vector.deserializeBinaryFromReader);
      msg.setC(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.Matrix.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.Matrix.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.Matrix} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.Matrix.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getA();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgp_service_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getB();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sgp_service_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getC();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sgp_service_pb.Vector.serializeBinaryToWriter
    );
  }
};


/**
 * optional SGP.Vector a = 1;
 * @return {?proto.SGP.Vector}
 */
proto.Aggregator.Matrix.prototype.getA = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, sgp_service_pb.Vector, 1));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.Aggregator.Matrix} returns this
*/
proto.Aggregator.Matrix.prototype.setA = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.Matrix} returns this
 */
proto.Aggregator.Matrix.prototype.clearA = function() {
  return this.setA(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.Matrix.prototype.hasA = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SGP.Vector b = 2;
 * @return {?proto.SGP.Vector}
 */
proto.Aggregator.Matrix.prototype.getB = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, sgp_service_pb.Vector, 2));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.Aggregator.Matrix} returns this
*/
proto.Aggregator.Matrix.prototype.setB = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.Matrix} returns this
 */
proto.Aggregator.Matrix.prototype.clearB = function() {
  return this.setB(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.Matrix.prototype.hasB = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional SGP.Vector c = 3;
 * @return {?proto.SGP.Vector}
 */
proto.Aggregator.Matrix.prototype.getC = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, sgp_service_pb.Vector, 3));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.Aggregator.Matrix} returns this
*/
proto.Aggregator.Matrix.prototype.setC = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.Matrix} returns this
 */
proto.Aggregator.Matrix.prototype.clearC = function() {
  return this.setC(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.Matrix.prototype.hasC = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.KV.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.KV.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.KV} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.KV.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    valDouble: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    valVector: (f = msg.getValVector()) && sgp_service_pb.Vector.toObject(includeInstance, f),
    valMatrix: (f = msg.getValMatrix()) && proto.Aggregator.Matrix.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.KV}
 */
proto.Aggregator.KV.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.KV;
  return proto.Aggregator.KV.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.KV} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.KV}
 */
proto.Aggregator.KV.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setValDouble(value);
      break;
    case 3:
      var value = new sgp_service_pb.Vector;
      reader.readMessage(value,sgp_service_pb.Vector.deserializeBinaryFromReader);
      msg.setValVector(value);
      break;
    case 4:
      var value = new proto.Aggregator.Matrix;
      reader.readMessage(value,proto.Aggregator.Matrix.deserializeBinaryFromReader);
      msg.setValMatrix(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.KV.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.KV.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.KV} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.KV.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getValDouble();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getValVector();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      sgp_service_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getValMatrix();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Aggregator.Matrix.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.Aggregator.KV.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.KV} returns this
 */
proto.Aggregator.KV.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double val_double = 2;
 * @return {number}
 */
proto.Aggregator.KV.prototype.getValDouble = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.Aggregator.KV} returns this
 */
proto.Aggregator.KV.prototype.setValDouble = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional SGP.Vector val_vector = 3;
 * @return {?proto.SGP.Vector}
 */
proto.Aggregator.KV.prototype.getValVector = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, sgp_service_pb.Vector, 3));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.Aggregator.KV} returns this
*/
proto.Aggregator.KV.prototype.setValVector = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.KV} returns this
 */
proto.Aggregator.KV.prototype.clearValVector = function() {
  return this.setValVector(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.KV.prototype.hasValVector = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Matrix val_matrix = 4;
 * @return {?proto.Aggregator.Matrix}
 */
proto.Aggregator.KV.prototype.getValMatrix = function() {
  return /** @type{?proto.Aggregator.Matrix} */ (
    jspb.Message.getWrapperField(this, proto.Aggregator.Matrix, 4));
};


/**
 * @param {?proto.Aggregator.Matrix|undefined} value
 * @return {!proto.Aggregator.KV} returns this
*/
proto.Aggregator.KV.prototype.setValMatrix = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.KV} returns this
 */
proto.Aggregator.KV.prototype.clearValMatrix = function() {
  return this.setValMatrix(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.KV.prototype.hasValMatrix = function() {
  return jspb.Message.getField(this, 4) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Aggregator.ModuleState.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.ModuleState.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.ModuleState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.ModuleState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.ModuleState.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    slot: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stateDictList: jspb.Message.toObjectList(msg.getStateDictList(),
    proto.Aggregator.KV.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.ModuleState}
 */
proto.Aggregator.ModuleState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.ModuleState;
  return proto.Aggregator.ModuleState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.ModuleState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.ModuleState}
 */
proto.Aggregator.ModuleState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSlot(value);
      break;
    case 3:
      var value = new proto.Aggregator.KV;
      reader.readMessage(value,proto.Aggregator.KV.deserializeBinaryFromReader);
      msg.addStateDict(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.ModuleState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.ModuleState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.ModuleState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.ModuleState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSlot();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStateDictList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Aggregator.KV.serializeBinaryToWriter
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.Aggregator.ModuleState.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.ModuleState} returns this
 */
proto.Aggregator.ModuleState.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string slot = 2;
 * @return {string}
 */
proto.Aggregator.ModuleState.prototype.getSlot = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.ModuleState} returns this
 */
proto.Aggregator.ModuleState.prototype.setSlot = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated KV state_dict = 3;
 * @return {!Array<!proto.Aggregator.KV>}
 */
proto.Aggregator.ModuleState.prototype.getStateDictList = function() {
  return /** @type{!Array<!proto.Aggregator.KV>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Aggregator.KV, 3));
};


/**
 * @param {!Array<!proto.Aggregator.KV>} value
 * @return {!proto.Aggregator.ModuleState} returns this
*/
proto.Aggregator.ModuleState.prototype.setStateDictList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Aggregator.KV=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Aggregator.KV}
 */
proto.Aggregator.ModuleState.prototype.addStateDict = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Aggregator.KV, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Aggregator.ModuleState} returns this
 */
proto.Aggregator.ModuleState.prototype.clearStateDictList = function() {
  return this.setStateDictList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Aggregator.SatelliteState.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.SatelliteState.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.SatelliteState.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.SatelliteState} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.SatelliteState.toObject = function(includeInstance, msg) {
  var f, obj = {
    orientation: (f = msg.getOrientation()) && sgp_service_pb.Vector.toObject(includeInstance, f),
    rotationSpeed: (f = msg.getRotationSpeed()) && sgp_service_pb.Vector.toObject(includeInstance, f),
    outModuleStatesList: jspb.Message.toObjectList(msg.getOutModuleStatesList(),
    proto.Aggregator.ModuleState.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.SatelliteState}
 */
proto.Aggregator.SatelliteState.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.SatelliteState;
  return proto.Aggregator.SatelliteState.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.SatelliteState} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.SatelliteState}
 */
proto.Aggregator.SatelliteState.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgp_service_pb.Vector;
      reader.readMessage(value,sgp_service_pb.Vector.deserializeBinaryFromReader);
      msg.setOrientation(value);
      break;
    case 2:
      var value = new sgp_service_pb.Vector;
      reader.readMessage(value,sgp_service_pb.Vector.deserializeBinaryFromReader);
      msg.setRotationSpeed(value);
      break;
    case 3:
      var value = new proto.Aggregator.ModuleState;
      reader.readMessage(value,proto.Aggregator.ModuleState.deserializeBinaryFromReader);
      msg.addOutModuleStates(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.SatelliteState.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.SatelliteState.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.SatelliteState} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.SatelliteState.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrientation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgp_service_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getRotationSpeed();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sgp_service_pb.Vector.serializeBinaryToWriter
    );
  }
  f = message.getOutModuleStatesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.Aggregator.ModuleState.serializeBinaryToWriter
    );
  }
};


/**
 * optional SGP.Vector orientation = 1;
 * @return {?proto.SGP.Vector}
 */
proto.Aggregator.SatelliteState.prototype.getOrientation = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, sgp_service_pb.Vector, 1));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.Aggregator.SatelliteState} returns this
*/
proto.Aggregator.SatelliteState.prototype.setOrientation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.SatelliteState} returns this
 */
proto.Aggregator.SatelliteState.prototype.clearOrientation = function() {
  return this.setOrientation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.SatelliteState.prototype.hasOrientation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SGP.Vector rotation_speed = 2;
 * @return {?proto.SGP.Vector}
 */
proto.Aggregator.SatelliteState.prototype.getRotationSpeed = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, sgp_service_pb.Vector, 2));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.Aggregator.SatelliteState} returns this
*/
proto.Aggregator.SatelliteState.prototype.setRotationSpeed = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.SatelliteState} returns this
 */
proto.Aggregator.SatelliteState.prototype.clearRotationSpeed = function() {
  return this.setRotationSpeed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.SatelliteState.prototype.hasRotationSpeed = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated ModuleState out_module_states = 3;
 * @return {!Array<!proto.Aggregator.ModuleState>}
 */
proto.Aggregator.SatelliteState.prototype.getOutModuleStatesList = function() {
  return /** @type{!Array<!proto.Aggregator.ModuleState>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Aggregator.ModuleState, 3));
};


/**
 * @param {!Array<!proto.Aggregator.ModuleState>} value
 * @return {!proto.Aggregator.SatelliteState} returns this
*/
proto.Aggregator.SatelliteState.prototype.setOutModuleStatesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.Aggregator.ModuleState=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Aggregator.ModuleState}
 */
proto.Aggregator.SatelliteState.prototype.addOutModuleStates = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.Aggregator.ModuleState, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Aggregator.SatelliteState} returns this
 */
proto.Aggregator.SatelliteState.prototype.clearOutModuleStatesList = function() {
  return this.setOutModuleStatesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Aggregator.Frame.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.Frame.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.Frame.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.Frame} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.Frame.toObject = function(includeInstance, msg) {
  var f, obj = {
    coord: (f = msg.getCoord()) && sgp_service_pb.CoordECI.toObject(includeInstance, f),
    coordGeo: (f = msg.getCoordGeo()) && sgp_service_pb.CoordGeodetic.toObject(includeInstance, f),
    geomagneticField: (f = msg.getGeomagneticField()) && igrf_service_pb.igrf_computation_result.toObject(includeInstance, f),
    state: (f = msg.getState()) && proto.Aggregator.SatelliteState.toObject(includeInstance, f),
    messagesList: jspb.Message.toObjectList(msg.getMessagesList(),
    proto.Aggregator.LogMessage.toObject, includeInstance),
    time: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.Frame}
 */
proto.Aggregator.Frame.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.Frame;
  return proto.Aggregator.Frame.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.Frame} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.Frame}
 */
proto.Aggregator.Frame.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new sgp_service_pb.CoordECI;
      reader.readMessage(value,sgp_service_pb.CoordECI.deserializeBinaryFromReader);
      msg.setCoord(value);
      break;
    case 2:
      var value = new sgp_service_pb.CoordGeodetic;
      reader.readMessage(value,sgp_service_pb.CoordGeodetic.deserializeBinaryFromReader);
      msg.setCoordGeo(value);
      break;
    case 3:
      var value = new igrf_service_pb.igrf_computation_result;
      reader.readMessage(value,igrf_service_pb.igrf_computation_result.deserializeBinaryFromReader);
      msg.setGeomagneticField(value);
      break;
    case 4:
      var value = new proto.Aggregator.SatelliteState;
      reader.readMessage(value,proto.Aggregator.SatelliteState.deserializeBinaryFromReader);
      msg.setState(value);
      break;
    case 5:
      var value = new proto.Aggregator.LogMessage;
      reader.readMessage(value,proto.Aggregator.LogMessage.deserializeBinaryFromReader);
      msg.addMessages(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.Frame.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.Frame.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.Frame} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.Frame.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoord();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      sgp_service_pb.CoordECI.serializeBinaryToWriter
    );
  }
  f = message.getCoordGeo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      sgp_service_pb.CoordGeodetic.serializeBinaryToWriter
    );
  }
  f = message.getGeomagneticField();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      igrf_service_pb.igrf_computation_result.serializeBinaryToWriter
    );
  }
  f = message.getState();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.Aggregator.SatelliteState.serializeBinaryToWriter
    );
  }
  f = message.getMessagesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.Aggregator.LogMessage.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
};


/**
 * optional SGP.CoordECI coord = 1;
 * @return {?proto.SGP.CoordECI}
 */
proto.Aggregator.Frame.prototype.getCoord = function() {
  return /** @type{?proto.SGP.CoordECI} */ (
    jspb.Message.getWrapperField(this, sgp_service_pb.CoordECI, 1));
};


/**
 * @param {?proto.SGP.CoordECI|undefined} value
 * @return {!proto.Aggregator.Frame} returns this
*/
proto.Aggregator.Frame.prototype.setCoord = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.Frame} returns this
 */
proto.Aggregator.Frame.prototype.clearCoord = function() {
  return this.setCoord(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.Frame.prototype.hasCoord = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SGP.CoordGeodetic coord_geo = 2;
 * @return {?proto.SGP.CoordGeodetic}
 */
proto.Aggregator.Frame.prototype.getCoordGeo = function() {
  return /** @type{?proto.SGP.CoordGeodetic} */ (
    jspb.Message.getWrapperField(this, sgp_service_pb.CoordGeodetic, 2));
};


/**
 * @param {?proto.SGP.CoordGeodetic|undefined} value
 * @return {!proto.Aggregator.Frame} returns this
*/
proto.Aggregator.Frame.prototype.setCoordGeo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.Frame} returns this
 */
proto.Aggregator.Frame.prototype.clearCoordGeo = function() {
  return this.setCoordGeo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.Frame.prototype.hasCoordGeo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional IGRF.igrf_computation_result geomagnetic_field = 3;
 * @return {?proto.IGRF.igrf_computation_result}
 */
proto.Aggregator.Frame.prototype.getGeomagneticField = function() {
  return /** @type{?proto.IGRF.igrf_computation_result} */ (
    jspb.Message.getWrapperField(this, igrf_service_pb.igrf_computation_result, 3));
};


/**
 * @param {?proto.IGRF.igrf_computation_result|undefined} value
 * @return {!proto.Aggregator.Frame} returns this
*/
proto.Aggregator.Frame.prototype.setGeomagneticField = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.Frame} returns this
 */
proto.Aggregator.Frame.prototype.clearGeomagneticField = function() {
  return this.setGeomagneticField(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.Frame.prototype.hasGeomagneticField = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SatelliteState state = 4;
 * @return {?proto.Aggregator.SatelliteState}
 */
proto.Aggregator.Frame.prototype.getState = function() {
  return /** @type{?proto.Aggregator.SatelliteState} */ (
    jspb.Message.getWrapperField(this, proto.Aggregator.SatelliteState, 4));
};


/**
 * @param {?proto.Aggregator.SatelliteState|undefined} value
 * @return {!proto.Aggregator.Frame} returns this
*/
proto.Aggregator.Frame.prototype.setState = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.Aggregator.Frame} returns this
 */
proto.Aggregator.Frame.prototype.clearState = function() {
  return this.setState(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.Aggregator.Frame.prototype.hasState = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated LogMessage messages = 5;
 * @return {!Array<!proto.Aggregator.LogMessage>}
 */
proto.Aggregator.Frame.prototype.getMessagesList = function() {
  return /** @type{!Array<!proto.Aggregator.LogMessage>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Aggregator.LogMessage, 5));
};


/**
 * @param {!Array<!proto.Aggregator.LogMessage>} value
 * @return {!proto.Aggregator.Frame} returns this
*/
proto.Aggregator.Frame.prototype.setMessagesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.Aggregator.LogMessage=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Aggregator.LogMessage}
 */
proto.Aggregator.Frame.prototype.addMessages = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.Aggregator.LogMessage, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Aggregator.Frame} returns this
 */
proto.Aggregator.Frame.prototype.clearMessagesList = function() {
  return this.setMessagesList([]);
};


/**
 * optional uint64 time = 6;
 * @return {number}
 */
proto.Aggregator.Frame.prototype.getTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.Aggregator.Frame} returns this
 */
proto.Aggregator.Frame.prototype.setTime = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.Aggregator.Frames.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.Frames.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.Frames.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.Frames} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.Frames.toObject = function(includeInstance, msg) {
  var f, obj = {
    framesList: jspb.Message.toObjectList(msg.getFramesList(),
    proto.Aggregator.Frame.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.Frames}
 */
proto.Aggregator.Frames.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.Frames;
  return proto.Aggregator.Frames.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.Frames} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.Frames}
 */
proto.Aggregator.Frames.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.Aggregator.Frame;
      reader.readMessage(value,proto.Aggregator.Frame.deserializeBinaryFromReader);
      msg.addFrames(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.Frames.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.Frames.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.Frames} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.Frames.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFramesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.Aggregator.Frame.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Frame frames = 1;
 * @return {!Array<!proto.Aggregator.Frame>}
 */
proto.Aggregator.Frames.prototype.getFramesList = function() {
  return /** @type{!Array<!proto.Aggregator.Frame>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.Aggregator.Frame, 1));
};


/**
 * @param {!Array<!proto.Aggregator.Frame>} value
 * @return {!proto.Aggregator.Frames} returns this
*/
proto.Aggregator.Frames.prototype.setFramesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.Aggregator.Frame=} opt_value
 * @param {number=} opt_index
 * @return {!proto.Aggregator.Frame}
 */
proto.Aggregator.Frames.prototype.addFrames = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.Aggregator.Frame, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.Aggregator.Frames} returns this
 */
proto.Aggregator.Frames.prototype.clearFramesList = function() {
  return this.setFramesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.EndRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.EndRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.EndRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.EndRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    computationalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.EndRequest}
 */
proto.Aggregator.EndRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.EndRequest;
  return proto.Aggregator.EndRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.EndRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.EndRequest}
 */
proto.Aggregator.EndRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComputationalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.EndRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.EndRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.EndRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.EndRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComputationalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string computational_id = 1;
 * @return {string}
 */
proto.Aggregator.EndRequest.prototype.getComputationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Aggregator.EndRequest} returns this
 */
proto.Aggregator.EndRequest.prototype.setComputationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Aggregator.EndResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.Aggregator.EndResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Aggregator.EndResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.EndResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Aggregator.EndResponse}
 */
proto.Aggregator.EndResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Aggregator.EndResponse;
  return proto.Aggregator.EndResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Aggregator.EndResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Aggregator.EndResponse}
 */
proto.Aggregator.EndResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Aggregator.EndResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Aggregator.EndResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Aggregator.EndResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Aggregator.EndResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.Aggregator);
