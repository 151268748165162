import './Nav.css';
import NavElement from "./NavElement";
import {useEffect, useRef, useState} from "react";
import {observer} from "../index";
import React from "react";

function Nav({store, currentMenu, updateCurrentMenu, flag}) {

    let NavElementCurrent = useRef(null)
    let NavElementLine = useRef(null)
    let updateNavLine = (() => {
        let Menu = () => {
            let name2 = window.location.pathname
            switch (name2) {
                case '/':
                    return 0
                case '/vsvs':
                    return 1
                case '/ais_adsb/':
                    return 5
                case '/igrf':
                    return 2
                case '/emulator':
                    return 3
                case '/contacts':
                    return 4
            }
        }
        let poinT = Menu()
        setNavLine({
            width: NavElementCurrent.current.children[poinT].clientWidth + 'px',
            top: NavElementCurrent.current.children[poinT].offsetTop + 31 + 'px',
            left: NavElementCurrent.current.children[poinT].offsetLeft + 'px'
        })
    })

    let navEls = store.map((el, i) => <NavElement updateNavLine={updateNavLine} updateCurrentMenu={updateCurrentMenu}
                                                  iter={i} key={i} name={store[i]}></NavElement>)

    let [NavLine, setNavLine] = useState({
        width: '',
        top: '',
        left: ''
    })

    //console.log(NavLine.width)

    useEffect(() => {
        let pathh = window.location.pathname
        let numPathh
        switch (pathh) {
            case '/':
                numPathh = 0
                break
            case '/vsvs':
                numPathh = 1
                break
            case '/ais_adsb/':
                numPathh = 5
		break
            case '/igrf':
                numPathh = 2
                break
            case '/emulator':
                numPathh = 3
                break
            case '/contacts':
                numPathh = 4
                break
        }
        //console.log(currentMenu, '  ', numPathh, '  ', pathh)
        updateCurrentMenu(numPathh)
        currentMenu = numPathh
        setTimeout(() => {
            updateNavLine(currentMenu)
        }, 50)
        //console.log(NavLine)
        let obs = () => {
            observer()
            observer()
        }
        obs()
    }, [NavElementCurrent, currentMenu])

    window.addEventListener('resize', () => {
        if (document.body.clientWidth > 1099)
            NavElementLine.current.style.left = NavElementCurrent.current.children[currentMenu].offsetLeft + 'px'
    })

    if (flag)
        return (
            <div className="Nav">
                <div ref={NavElementCurrent} className="NavEls">
                    {navEls}
                </div>
                <div className="NavHorLine"></div>
                <div ref={NavElementLine}
                     style={{width: NavLine.width, left: NavLine.left, top: NavLine.top, height: '1px'}}
                     className="LineUnderOnePoint"></div>
            </div>
        );
    else
        return (
            <div className="Nav">
                <div ref={NavElementCurrent} className="NavEls">
                    {navEls}
                </div>
            </div>
        );
}

export default Nav;
