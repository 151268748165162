import './Hint.css';
import React from "react";

const Hint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} className="Hint">
            <div className="HintMain">Предупреждение: если не отправлен пользовательский код, симуляция будет проведена,
                но никаких действий аппарат осуществлять не будет. Для большего вовлечения в процесс, рекомендуем в
                дальнейшем отправлять код при работе с приложением.
            </div>
            <div className="HintDop"></div>
        </div>
    )
})

export default Hint;