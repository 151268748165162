import './Logo.css';
import logoImg from '../images/HSE2.svg'
import {Link} from "react-router-dom";
import {observer} from "../index";
import React from "react";

function Logo({updateCurrentMenu}) {

    let changeMenu = () => {
        updateCurrentMenu(0)
        observer()
    }


    return (
        <div className="Logo">
            <Link onClick={changeMenu} to={`/`} className="NavElementLink"><img src={logoImg} alt=""/></Link>
            <Link onClick={changeMenu} to={`/`} className="NavElementLink"><p className="LogoText">ВИРТУАЛЬНЫЙ
                КОСМОС</p></Link>
        </div>
    );
}

export default Logo;
