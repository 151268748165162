import './Input.css';
import React from "react";
import {useRef} from 'react'

function showname() {
    document.getElementById("file-selected").innerHTML = document.querySelector('.custom-file-upload>input').files.item(0).name;
};


function shownameEmu() {
    document.getElementById("file-selected").innerHTML = document.getElementById('file_input').files.item(0).name;
};


let Input = React.forwardRef(({
                                  classN,
                                  change,
                                  id,
                                  name,
                                  type,
                                  style,
                                  options,
                                  fileInput,
                                  gainrInput,
                                  elevationInput,
                                  gaintInput,
                                  frequencyInput,
                                  distanceInput,
                                  rateInput,
                                  errorInput,
                                  codeInput,
                                  polarizationInput,
                                  rainInput,
                                  transmitterInput,
                                  bandwidthInput,
                                  noiseInput
                              }, ref) => {

    let refInput = useRef()
    if (type == 'message')
        return (
            <textarea key={id} id={`Input${id}`} onChange={e => {
                change(e, id)
            }} style={style} placeholder={`${name}`} className={`Input ${classN}`}/>
        );
    else if (type == 'datetime-local')
        return (
            <input style={style} id={id}
                   defaultValue={(new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)).toISOString().slice(0, -5)}
                   className={`Input ${classN}`} type={type}/>
        );
    else if (type == 'file') {
        if (fileInput) {
            name = fileInput.name
        }
        if (id == 'code_file')
            return (
                <label key={id} ref={refInput} onMouseMove={(e) => {
                    if (e.target == refInput.current)
                        refInput.current.style.background = `linear-gradient(90deg, #005AAB ${e.nativeEvent.offsetX / refInput.current.offsetWidth * 100 - 15}%, #81BFF8 ${e.nativeEvent.offsetX / refInput.current.offsetWidth * 100}%, #005AAB ${e.nativeEvent.offsetX / refInput.current.offsetWidth * 100 + 10}%)`
                    else {
                        refInput.current.style.background = `linear-gradient(90deg, #005AAB ${(e.nativeEvent.offsetX + (refInput.current.offsetWidth - e.target.offsetWidth) / 2) / refInput.current.offsetWidth * 100 - 15}%, #81BFF8 ${(e.nativeEvent.offsetX + (refInput.current.offsetWidth - e.target.offsetWidth) / 2) / refInput.current.offsetWidth * 100}%, #005AAB ${(e.nativeEvent.offsetX + (refInput.current.offsetWidth - e.target.offsetWidth) / 2) / refInput.current.offsetWidth * 100 + 10}%)`
                    }
                }} onMouseLeave={() => {
                    refInput.current.style.background = `#EAEDF0`
                }} style={style} className={`custom-file-upload ${classN}`}>
                    <p id="file-selected">{`${name}`}</p>
                    <input id={id} onChange={e => {
                        showname();
                        change(e, id)
                    }} type={type} accept=".cpp, .c, .txt"/>
                </label>
            );
        return (
            <label key={id} id={`Input${id}`} style={style} ref={refInput} className={`custom-file-upload ${classN}`}
                   onMouseMove={(e) => {
                       if (e.target == refInput.current)
                           refInput.current.style.background = `linear-gradient(90deg, #005AAB ${e.nativeEvent.offsetX / refInput.current.offsetWidth * 100 - 15}%, #81BFF8 ${e.nativeEvent.offsetX / refInput.current.offsetWidth * 100}%, #005AAB ${e.nativeEvent.offsetX / refInput.current.offsetWidth * 100 + 10}%)`
                       else {
                           refInput.current.style.background = `linear-gradient(90deg, #005AAB ${(e.nativeEvent.offsetX + (refInput.current.offsetWidth - e.target.offsetWidth) / 2) / refInput.current.offsetWidth * 100 - 15}%, #81BFF8 ${(e.nativeEvent.offsetX + (refInput.current.offsetWidth - e.target.offsetWidth) / 2) / refInput.current.offsetWidth * 100}%, #005AAB ${(e.nativeEvent.offsetX + (refInput.current.offsetWidth - e.target.offsetWidth) / 2) / refInput.current.offsetWidth * 100 + 10}%)`
                       }
                   }} onMouseLeave={() => {
                refInput.current.style.background = `#EAEDF0`
            }}>
                <p id="file-selected">{`${name}`}</p>
                <input id={id} onChange={e => {
                    shownameEmu();
                    change(e, id)
                }} type={type} accept=".jpg, .jpeg, .png"/>
            </label>
        );
    } else if (type == 'select') {
        let value = ''
        if (id == 'error_input')
            value = errorInput
        if (id == 'code_input')
            value = codeInput

        return (
            <select key={id} id={id} defaultValue={value} onChange={e => {
                change(e, id)
            }} className={`custom-select ${classN}`} style={style}>
                {options.map((el) => (
                    <option key={id + el} value={el}>{el}</option>
                ))}
            </select>
        );
    } else {
        let defValue = ''
        let emu_flag = 0
        let cont_flag = 0
        if (classN == 'SpacecraftName')
            defValue = 'ISS'
        if (classN == 'TLEfirst')
            defValue = '1 25544U 98067A   21140.23141564  .00001480  00000-0  35000-4 0  9999'
        if (classN == 'TLEsecond')
            defValue = '2 25544  51.6437 120.9949 0003227  21.1871  75.5686 15.49025569284261'
        if (id == "gainr_input") {
            emu_flag = 1
            defValue = gainrInput
        }
        if (id == "elevation_input") {
            emu_flag = 1
            defValue = elevationInput
        }
        if (id == "gaint_input") {
            emu_flag = 1
            defValue = gaintInput
        }
        if (id == "frequency_input") {
            emu_flag = 1
            defValue = frequencyInput
        }
        if (id == "distance_input") {
            emu_flag = 1
            defValue = distanceInput
        }
        if (id == "rate_input") {
            emu_flag = 1
            defValue = rateInput
        }
        if (id == "polarization_input") {
            emu_flag = 1
            defValue = polarizationInput
        }
        if (id == "rain_input") {
            emu_flag = 1
            defValue = rainInput
        }
        if (id == "transmitter_input") {
            emu_flag = 1
            defValue = transmitterInput
        }
        if (id == "bandwidth_input") {
            emu_flag = 1
            defValue = bandwidthInput
        }
        if (id == "noise_input") {
            emu_flag = 1
            defValue = noiseInput
        }
        if (id == "fio_contacts" || id == "email_contacts") {
            cont_flag = 1
        }
        if (document.querySelector('.Igrf') || document.querySelector('.vsvs'))
            return (
                <div ref={refInput} key={id} className={`Input ${classN}`}
                     style={{...style, display: 'flex', alignItems: 'center', position: 'relative'}}>
                    <span style={{display: 'inline'}}>{name}</span>
                    <input id={id} ref={ref} autoComplete="off" style={{display: 'inline', width: '100%'}}
                           placeholder={`${name}`} defaultValue={defValue} type={type} onChange={() => {
                        refInput.current.style.background = '#EAEDF0';
                        refInput.current.children[1].style.background = '#EAEDF0'
                    }}/>
                    <span className="non-valid-input-span" style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '17px',
                        letterSpacing: '0.02em',
                        color: '#FB4B4B',
                        display: 'none',
                        position: 'absolute',
                        bottom: '-2px',
                        left: '0px',
                        transform: 'translate(0, 100%)'
                    }}>Поле обязательно для заполнения</span>
                </div>
            )
        if (!emu_flag && !cont_flag)
            return (
                <div key={id} className={`Input ${classN}`} style={{...style, display: 'flex', alignItems: 'center'}}>
                    <span style={{display: 'inline'}}>{name}</span>
                    <input id={id} style={{display: 'inline', width: '100%'}} placeholder={`${name}`}
                           defaultValue={defValue} type={type}/>
                </div>
            )
        else return (

            <div key={id} id={`Input${id}`} className={`Input ${classN}`}
                 style={{...style, display: 'flex', alignItems: 'center'}}>
                <span style={{display: 'inline'}}>{name}</span>
                <input onChange={e => {
                    change(e, id)
                }} id={id} className={'smallInputs'} style={{display: 'inline', width: '100%'}} placeholder={`${name}`}
                       defaultValue={defValue} type={type}/>
            </div>
        )
    }
})

export default Input;
