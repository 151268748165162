// source: inverter.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.Image', null, global);
goog.exportSymbol('proto.InformationPackage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.InformationPackage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.InformationPackage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.InformationPackage.displayName = 'proto.InformationPackage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.Image = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.Image, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.Image.displayName = 'proto.Image';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.InformationPackage.prototype.toObject = function(opt_includeInstance) {
  return proto.InformationPackage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.InformationPackage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InformationPackage.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    frequency: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    bandwidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    systemNoise: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    symbolRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    receiverGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    transmitterGain: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    transmitterPower: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    rainRate: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    elevation: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    polarizationAngle: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    code: jspb.Message.getFieldWithDefault(msg, 13, ""),
    errorCorrectingCode: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.InformationPackage}
 */
proto.InformationPackage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.InformationPackage;
  return proto.InformationPackage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.InformationPackage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.InformationPackage}
 */
proto.InformationPackage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setFrequency(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setBandwidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSystemNoise(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setSymbolRate(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setReceiverGain(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTransmitterGain(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setTransmitterPower(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setRainRate(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setElevation(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDistance(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setPolarizationAngle(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorCorrectingCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.InformationPackage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.InformationPackage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.InformationPackage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.InformationPackage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrequency();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getBandwidth();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
  f = message.getSystemNoise();
  if (f !== 0.0) {
    writer.writeFloat(
      4,
      f
    );
  }
  f = message.getSymbolRate();
  if (f !== 0.0) {
    writer.writeFloat(
      5,
      f
    );
  }
  f = message.getReceiverGain();
  if (f !== 0.0) {
    writer.writeFloat(
      6,
      f
    );
  }
  f = message.getTransmitterGain();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getTransmitterPower();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getRainRate();
  if (f !== 0.0) {
    writer.writeFloat(
      9,
      f
    );
  }
  f = message.getElevation();
  if (f !== 0.0) {
    writer.writeFloat(
      10,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeFloat(
      11,
      f
    );
  }
  f = message.getPolarizationAngle();
  if (f !== 0.0) {
    writer.writeFloat(
      12,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getErrorCorrectingCode();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.InformationPackage.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float frequency = 2;
 * @return {number}
 */
proto.InformationPackage.prototype.getFrequency = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setFrequency = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float bandwidth = 3;
 * @return {number}
 */
proto.InformationPackage.prototype.getBandwidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setBandwidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional float system_noise = 4;
 * @return {number}
 */
proto.InformationPackage.prototype.getSystemNoise = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setSystemNoise = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional float symbol_rate = 5;
 * @return {number}
 */
proto.InformationPackage.prototype.getSymbolRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setSymbolRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional float receiver_gain = 6;
 * @return {number}
 */
proto.InformationPackage.prototype.getReceiverGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setReceiverGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional float transmitter_gain = 7;
 * @return {number}
 */
proto.InformationPackage.prototype.getTransmitterGain = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setTransmitterGain = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float transmitter_power = 8;
 * @return {number}
 */
proto.InformationPackage.prototype.getTransmitterPower = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setTransmitterPower = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional float rain_rate = 9;
 * @return {number}
 */
proto.InformationPackage.prototype.getRainRate = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setRainRate = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional float elevation = 10;
 * @return {number}
 */
proto.InformationPackage.prototype.getElevation = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setElevation = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional float distance = 11;
 * @return {number}
 */
proto.InformationPackage.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional float polarization_angle = 12;
 * @return {number}
 */
proto.InformationPackage.prototype.getPolarizationAngle = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setPolarizationAngle = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional string code = 13;
 * @return {string}
 */
proto.InformationPackage.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string error_correcting_code = 14;
 * @return {string}
 */
proto.InformationPackage.prototype.getErrorCorrectingCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.InformationPackage} returns this
 */
proto.InformationPackage.prototype.setErrorCorrectingCode = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.Image.prototype.toObject = function(opt_includeInstance) {
  return proto.Image.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.Image} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Image.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.Image}
 */
proto.Image.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.Image;
  return proto.Image.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.Image} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.Image}
 */
proto.Image.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.Image.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.Image.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.Image} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.Image.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.Image.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.Image} returns this
 */
proto.Image.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


goog.object.extend(exports, proto);
