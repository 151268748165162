import closeImg from '../images/Close.svg'
import closeHoverImg from '../images/CloseHover.svg'
import './Modal.css'
import React, {useRef} from "react";

function fitImage(image) {
    const aspectRatio = image.naturalWidth / image.naturalHeight;

    // If image is landscape or square
    if (aspectRatio >= 1) {
        document.querySelector('.modal-wrap').classList.remove('wraphor');
        image.classList.remove('imgvert');
        image.classList.remove('imghor');

        image.classList.add('imghor');
        document.querySelector('.modal-wrap').classList.add('wraphor');
    }

    // If image is portrait
    else if (aspectRatio < 1) {
        document.querySelector('.modal-wrap').classList.remove('wraphor');
        image.classList.remove('imghor');
        image.classList.remove('imgvert');

        image.classList.add('imgvert');
    }
}

let Modal = React.forwardRef(({exit, title, first, second}, ref) => {

    let closeImgRef = useRef()
    let closeImgRef2 = useRef()

    const images = document.querySelectorAll('.modal-image');

    Array.from(images).forEach(image => {
        image.addEventListener('load', () => fitImage(image));

        if (image.complete && image.naturalWidth !== 0)
            fitImage(image);
    });

    return (
        <div ref={ref} className='modal-main'>
            <div className="modal-header">
                <h1 className="modal-title">{title}</h1>
                <img ref={closeImgRef} onMouseEnter={() => {
                    closeImgRef.current.src = closeHoverImg
                }} onMouseLeave={() => {
                    closeImgRef.current.src = closeImg
                }}
                     src={closeImg}
                     onClick={exit}
                     className="closebutton"></img>
            </div>
            <div className='modal-wrap'>
                <div>
                    <h2 className="modal-text">{first}</h2>
                    <img id="image1" className="modal-image"/>
                </div>
                <div>
                    <h2 className="modal-text">{second}</h2>
                    <img id="image2" className="modal-image"/>
                </div>
            </div>
            <img ref={closeImgRef2} onMouseEnter={() => {
                closeImgRef2.current.src = closeHoverImg
            }} onMouseLeave={() => {
                closeImgRef2.current.src = closeImg
            }}
                 src={closeImg}
                 onClick={exit}
                 className="closebutton2"></img>

        </div>
    );

})

export default Modal;
