/**
 * @fileoverview gRPC-Web generated client stub for Assembly
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.Assembly = require('./assembly_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Assembly.AssemblyServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Assembly.AssemblyServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Assembly.Empty,
 *   !proto.Assembly.ArchitecturesAndModules>}
 */
const methodDescriptor_AssemblyService_ReturnArchitecturesAndModules = new grpc.web.MethodDescriptor(
  '/Assembly.AssemblyService/ReturnArchitecturesAndModules',
  grpc.web.MethodType.UNARY,
  proto.Assembly.Empty,
  proto.Assembly.ArchitecturesAndModules,
  /**
   * @param {!proto.Assembly.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Assembly.ArchitecturesAndModules.deserializeBinary
);


/**
 * @param {!proto.Assembly.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Assembly.ArchitecturesAndModules)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Assembly.ArchitecturesAndModules>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Assembly.AssemblyServiceClient.prototype.returnArchitecturesAndModules =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Assembly.AssemblyService/ReturnArchitecturesAndModules',
      request,
      metadata || {},
      methodDescriptor_AssemblyService_ReturnArchitecturesAndModules,
      callback);
};


/**
 * @param {!proto.Assembly.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Assembly.ArchitecturesAndModules>}
 *     Promise that resolves to the response
 */
proto.Assembly.AssemblyServicePromiseClient.prototype.returnArchitecturesAndModules =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Assembly.AssemblyService/ReturnArchitecturesAndModules',
      request,
      metadata || {},
      methodDescriptor_AssemblyService_ReturnArchitecturesAndModules);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Assembly.Configuration,
 *   !proto.Assembly.Empty>}
 */
const methodDescriptor_AssemblyService_CheckingConfiguration = new grpc.web.MethodDescriptor(
  '/Assembly.AssemblyService/CheckingConfiguration',
  grpc.web.MethodType.UNARY,
  proto.Assembly.Configuration,
  proto.Assembly.Empty,
  /**
   * @param {!proto.Assembly.Configuration} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Assembly.Empty.deserializeBinary
);


/**
 * @param {!proto.Assembly.Configuration} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Assembly.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Assembly.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Assembly.AssemblyServiceClient.prototype.checkingConfiguration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Assembly.AssemblyService/CheckingConfiguration',
      request,
      metadata || {},
      methodDescriptor_AssemblyService_CheckingConfiguration,
      callback);
};


/**
 * @param {!proto.Assembly.Configuration} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Assembly.Empty>}
 *     Promise that resolves to the response
 */
proto.Assembly.AssemblyServicePromiseClient.prototype.checkingConfiguration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Assembly.AssemblyService/CheckingConfiguration',
      request,
      metadata || {},
      methodDescriptor_AssemblyService_CheckingConfiguration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Assembly.Configuration,
 *   !proto.Assembly.Empty>}
 */
const methodDescriptor_AssemblyService_RegisterConfiguration = new grpc.web.MethodDescriptor(
  '/Assembly.AssemblyService/RegisterConfiguration',
  grpc.web.MethodType.UNARY,
  proto.Assembly.Configuration,
  proto.Assembly.Empty,
  /**
   * @param {!proto.Assembly.Configuration} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Assembly.Empty.deserializeBinary
);


/**
 * @param {!proto.Assembly.Configuration} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Assembly.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Assembly.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Assembly.AssemblyServiceClient.prototype.registerConfiguration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Assembly.AssemblyService/RegisterConfiguration',
      request,
      metadata || {},
      methodDescriptor_AssemblyService_RegisterConfiguration,
      callback);
};


/**
 * @param {!proto.Assembly.Configuration} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Assembly.Empty>}
 *     Promise that resolves to the response
 */
proto.Assembly.AssemblyServicePromiseClient.prototype.registerConfiguration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Assembly.AssemblyService/RegisterConfiguration',
      request,
      metadata || {},
      methodDescriptor_AssemblyService_RegisterConfiguration);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Assembly.ConfigurationName,
 *   !proto.Assembly.Configuration>}
 */
const methodDescriptor_AssemblyService_SearchConfiguration = new grpc.web.MethodDescriptor(
  '/Assembly.AssemblyService/SearchConfiguration',
  grpc.web.MethodType.UNARY,
  proto.Assembly.ConfigurationName,
  proto.Assembly.Configuration,
  /**
   * @param {!proto.Assembly.ConfigurationName} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Assembly.Configuration.deserializeBinary
);


/**
 * @param {!proto.Assembly.ConfigurationName} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Assembly.Configuration)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Assembly.Configuration>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Assembly.AssemblyServiceClient.prototype.searchConfiguration =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Assembly.AssemblyService/SearchConfiguration',
      request,
      metadata || {},
      methodDescriptor_AssemblyService_SearchConfiguration,
      callback);
};


/**
 * @param {!proto.Assembly.ConfigurationName} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Assembly.Configuration>}
 *     Promise that resolves to the response
 */
proto.Assembly.AssemblyServicePromiseClient.prototype.searchConfiguration =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Assembly.AssemblyService/SearchConfiguration',
      request,
      metadata || {},
      methodDescriptor_AssemblyService_SearchConfiguration);
};


module.exports = proto.Assembly;

