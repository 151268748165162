const { zip } = require("lodash");

class Queue{
  constructor() {
    this._arr = [];
  }

  push(val) {
    this._arr.push(val);
  }

  pop() {
    if(this._arr.length == 0)throw new Error("No elements in array");
    if(this._arr.length != 1)this._arr.shift();
  }

  front(){
    if(this._arr.length == 0)throw new Error("No elements in array");
    return this._arr[0];
  }

  extend(arr){
    this._arr = this._arr.concat(arr);
  }

  size(){
    return this._arr.length;
  }

  clear(){
    this._arr = [];
  }
}



class AdaptableQueue{
  /* assuming fetcher to be a function of n and callback, where n is a number of calculations per package, 
   *  and callback is a function of arrays of args and results to call upon completion
   * assuming distance measure to be a function of A, B such that the lower the result, the closer the params are
   */
  constructor(fetcher, distance_measure, invalidation_distance_limit){
    this.queue_1 = new Queue();
    this.queue_2 = new Queue();
    this.invalidation_distance_limit = invalidation_distance_limit;
    this.limit_coefficient = 2;
    this.n_coefficient = 2;
    
    this.distance_measure = distance_measure;
    this.get_interval_timings = [new Date(), new Date()];
    this.n = 100;
    this.limit = 50;
    this.sz = 4;

    this.fetch_interval_timings = [new Date()];
    //this.queue_1.extend(this.fetcher(this.n));
    this.requesting = false;
    this.used_queue = 2;
    //console.log("here");
    this.fetcher = fetcher;
    this.fetcher(this.n, (args, results)=>{
      this._push(zip(args, results).map((el)=>{
        return {args: el[0], result: el[1]};
      }));
      this._swap();
      this.fetch_interval_timings.push(new Date());
      
    });
    //while(!this.started);
    
  }


  _update_times(){
    this.get_interval_timings.shift();
    this.get_interval_timings.push(new Date());
  }

  _push(vals){
    //console.log(vals);
    this.used_queue == 1? this.queue_2.extend(vals) : this.queue_1.extend(vals);
  }

  _clear(){
    this.used_queue == 1? this.queue_2.clear() : this.queue_1.clear();
  }

  _fetch(){
  
    let fetch_interval = this.fetch_interval_timings[1].getTime() - this.fetch_interval_timings[0].getTime();
    let get_interval = this.get_interval_timings[1].getTime() - this.get_interval_timings[0].getTime();
    let display_interval = get_interval;
    this.limit = Math.max(10, Math.ceil(fetch_interval/display_interval*this.limit_coefficient));
    this.n = Math.ceil(this.limit * this.n_coefficient);
    this.fetch_interval_timings[0] = new Date();
    this._clear();
    //console.log('requested n:', this.n);
    this.fetcher(this.n, (args, results)=>{
      this._push(zip(args, results).map((el)=>{
        return {args: el[0], result: el[1]};
      }));
      this._swap();
      this.requesting = false;
      //console.log('upd: ', this._size());
      this.fetch_interval_timings[1] = new Date();
    });
    
    
  }

  _check(distance){
    //console.log('limit:', this.limit);
    return (this._size()<this.limit || distance > this.invalidation_distance_limit)&& !this.requesting;
  }

  _swap(){
    this.used_queue = this.used_queue == 1? 2: 1;
  }

  _front(){
    return this.used_queue == 1? this.queue_1.front() : this.queue_2.front();
  }

  _pop(){
    this.used_queue == 1? this.queue_1.pop() : this.queue_2.pop();
  }

  _size(){
    return this.used_queue == 1? this.queue_1.size() : this.queue_2.size();
  }

  get(){
    this._update_times();

    //console.log('at get: ', this._size());
    if(this._size() == 0){
      
    }
    let current = this._front();
    this._pop();
    
    /* while(this._size() > 0 && this.distance_measure(args, this._front().args) <  this.distance_measure(args, current.args)){
      current = this._front();
      this._pop();
      ++sz
    } */
    //console.log('deleted: ', sz);
    //this.sz = Math.ceil(this.sz*0.7 + sz*0.3);
    this.sz++;
    //let distance = this.distance_measure(args, current.args);
    if(this._size() < this.n){
      //schedule fetch
      //console.log('requesting');
      this.requesting = true;
      this._fetch(this.n); 
    }
    return current.result;
  }

}

export {AdaptableQueue};