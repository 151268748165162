/**
 * @fileoverview gRPC-Web generated client stub for Aggregator
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var sgp_service_pb = require('./sgp_service_pb.js')

var igrf_service_pb = require('./igrf_service_pb.js')

var assembly_pb = require('./assembly_pb.js')
const proto = {};
proto.Aggregator = require('./aggregator_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Aggregator.AggregatorServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.Aggregator.AggregatorServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Aggregator.Parameters,
 *   !proto.Aggregator.ConstructResponse>}
 */
const methodDescriptor_AggregatorService_construct = new grpc.web.MethodDescriptor(
  '/Aggregator.AggregatorService/construct',
  grpc.web.MethodType.UNARY,
  proto.Aggregator.Parameters,
  proto.Aggregator.ConstructResponse,
  /**
   * @param {!proto.Aggregator.Parameters} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Aggregator.ConstructResponse.deserializeBinary
);


/**
 * @param {!proto.Aggregator.Parameters} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Aggregator.ConstructResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Aggregator.ConstructResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Aggregator.AggregatorServiceClient.prototype.construct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Aggregator.AggregatorService/construct',
      request,
      metadata || {},
      methodDescriptor_AggregatorService_construct,
      callback);
};


/**
 * @param {!proto.Aggregator.Parameters} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Aggregator.ConstructResponse>}
 *     Promise that resolves to the response
 */
proto.Aggregator.AggregatorServicePromiseClient.prototype.construct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Aggregator.AggregatorService/construct',
      request,
      metadata || {},
      methodDescriptor_AggregatorService_construct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Aggregator.ComputationRequest,
 *   !proto.Aggregator.Frames>}
 */
const methodDescriptor_AggregatorService_compute = new grpc.web.MethodDescriptor(
  '/Aggregator.AggregatorService/compute',
  grpc.web.MethodType.UNARY,
  proto.Aggregator.ComputationRequest,
  proto.Aggregator.Frames,
  /**
   * @param {!proto.Aggregator.ComputationRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Aggregator.Frames.deserializeBinary
);


/**
 * @param {!proto.Aggregator.ComputationRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Aggregator.Frames)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Aggregator.Frames>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Aggregator.AggregatorServiceClient.prototype.compute =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Aggregator.AggregatorService/compute',
      request,
      metadata || {},
      methodDescriptor_AggregatorService_compute,
      callback);
};


/**
 * @param {!proto.Aggregator.ComputationRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Aggregator.Frames>}
 *     Promise that resolves to the response
 */
proto.Aggregator.AggregatorServicePromiseClient.prototype.compute =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Aggregator.AggregatorService/compute',
      request,
      metadata || {},
      methodDescriptor_AggregatorService_compute);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.Aggregator.EndRequest,
 *   !proto.Aggregator.EndResponse>}
 */
const methodDescriptor_AggregatorService_end = new grpc.web.MethodDescriptor(
  '/Aggregator.AggregatorService/end',
  grpc.web.MethodType.UNARY,
  proto.Aggregator.EndRequest,
  proto.Aggregator.EndResponse,
  /**
   * @param {!proto.Aggregator.EndRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.Aggregator.EndResponse.deserializeBinary
);


/**
 * @param {!proto.Aggregator.EndRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.Aggregator.EndResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.Aggregator.EndResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.Aggregator.AggregatorServiceClient.prototype.end =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/Aggregator.AggregatorService/end',
      request,
      metadata || {},
      methodDescriptor_AggregatorService_end,
      callback);
};


/**
 * @param {!proto.Aggregator.EndRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.Aggregator.EndResponse>}
 *     Promise that resolves to the response
 */
proto.Aggregator.AggregatorServicePromiseClient.prototype.end =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/Aggregator.AggregatorService/end',
      request,
      metadata || {},
      methodDescriptor_AggregatorService_end);
};


module.exports = proto.Aggregator;

