import './Navbar.css';
import Logo from "./Logo";
import Nav from "./Nav";
import burger from "../images/burger.svg"
import React from "react";

function Navbar({store, oneLine, currentMenu, updateCurrentMenu, burgerMenu}) {

    let flag = 1

    return (
        <div className="Navbar">
            <div className="logoImgPlusText">
                <img onClick={burgerMenu} src={burger} className="burger"></img>
                <Logo updateCurrentMenu={updateCurrentMenu}></Logo>
            </div>
            <Nav flag={flag} store={store} oneLine={oneLine} currentMenu={currentMenu}
                 updateCurrentMenu={updateCurrentMenu}></Nav>
        </div>
    );
}

export default Navbar;
