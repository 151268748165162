// source: sgp_service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.SGP.CloseRequest', null, global);
goog.exportSymbol('proto.SGP.CloseResponse', null, global);
goog.exportSymbol('proto.SGP.CoordECI', null, global);
goog.exportSymbol('proto.SGP.CoordGeodetic', null, global);
goog.exportSymbol('proto.SGP.CoordType', null, global);
goog.exportSymbol('proto.SGP.SGPComputeRequest', null, global);
goog.exportSymbol('proto.SGP.SGPComputeResponse', null, global);
goog.exportSymbol('proto.SGP.SGPConstructRequest', null, global);
goog.exportSymbol('proto.SGP.SGPConstructResponse', null, global);
goog.exportSymbol('proto.SGP.SolarPosition', null, global);
goog.exportSymbol('proto.SGP.Vector', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.CloseRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SGP.CloseRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.CloseRequest.displayName = 'proto.SGP.CloseRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.CloseResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SGP.CloseResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.CloseResponse.displayName = 'proto.SGP.CloseResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.SGPConstructRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SGP.SGPConstructRequest.repeatedFields_, null);
};
goog.inherits(proto.SGP.SGPConstructRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.SGPConstructRequest.displayName = 'proto.SGP.SGPConstructRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.SGPConstructResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SGP.SGPConstructResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.SGPConstructResponse.displayName = 'proto.SGP.SGPConstructResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.SGPComputeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SGP.SGPComputeRequest.repeatedFields_, null);
};
goog.inherits(proto.SGP.SGPComputeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.SGPComputeRequest.displayName = 'proto.SGP.SGPComputeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.CoordGeodetic = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SGP.CoordGeodetic, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.CoordGeodetic.displayName = 'proto.SGP.CoordGeodetic';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.Vector = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SGP.Vector, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.Vector.displayName = 'proto.SGP.Vector';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.CoordECI = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SGP.CoordECI, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.CoordECI.displayName = 'proto.SGP.CoordECI';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.SolarPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SGP.SolarPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.SolarPosition.displayName = 'proto.SGP.SolarPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SGP.SGPComputeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SGP.SGPComputeResponse.repeatedFields_, null);
};
goog.inherits(proto.SGP.SGPComputeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SGP.SGPComputeResponse.displayName = 'proto.SGP.SGPComputeResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.CloseRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.CloseRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.CloseRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.CloseRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    computationalId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.CloseRequest}
 */
proto.SGP.CloseRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.CloseRequest;
  return proto.SGP.CloseRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.CloseRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.CloseRequest}
 */
proto.SGP.CloseRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComputationalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.CloseRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.CloseRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.CloseRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.CloseRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComputationalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string computational_id = 2;
 * @return {string}
 */
proto.SGP.CloseRequest.prototype.getComputationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SGP.CloseRequest} returns this
 */
proto.SGP.CloseRequest.prototype.setComputationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.CloseResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.CloseResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.CloseResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.CloseResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.CloseResponse}
 */
proto.SGP.CloseResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.CloseResponse;
  return proto.SGP.CloseResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.CloseResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.CloseResponse}
 */
proto.SGP.CloseResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.CloseResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.CloseResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.CloseResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.CloseResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SGP.SGPConstructRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.SGPConstructRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.SGPConstructRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.SGPConstructRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SGPConstructRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    first: jspb.Message.getFieldWithDefault(msg, 2, ""),
    second: jspb.Message.getFieldWithDefault(msg, 3, ""),
    observerLocationsList: jspb.Message.toObjectList(msg.getObserverLocationsList(),
    proto.SGP.CoordGeodetic.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.SGPConstructRequest}
 */
proto.SGP.SGPConstructRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.SGPConstructRequest;
  return proto.SGP.SGPConstructRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.SGPConstructRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.SGPConstructRequest}
 */
proto.SGP.SGPConstructRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirst(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecond(value);
      break;
    case 4:
      var value = new proto.SGP.CoordGeodetic;
      reader.readMessage(value,proto.SGP.CoordGeodetic.deserializeBinaryFromReader);
      msg.addObserverLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.SGPConstructRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.SGPConstructRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.SGPConstructRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SGPConstructRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirst();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSecond();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getObserverLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.SGP.CoordGeodetic.serializeBinaryToWriter
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.SGP.SGPConstructRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SGP.SGPConstructRequest} returns this
 */
proto.SGP.SGPConstructRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string first = 2;
 * @return {string}
 */
proto.SGP.SGPConstructRequest.prototype.getFirst = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.SGP.SGPConstructRequest} returns this
 */
proto.SGP.SGPConstructRequest.prototype.setFirst = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string second = 3;
 * @return {string}
 */
proto.SGP.SGPConstructRequest.prototype.getSecond = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.SGP.SGPConstructRequest} returns this
 */
proto.SGP.SGPConstructRequest.prototype.setSecond = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated CoordGeodetic observer_locations = 4;
 * @return {!Array<!proto.SGP.CoordGeodetic>}
 */
proto.SGP.SGPConstructRequest.prototype.getObserverLocationsList = function() {
  return /** @type{!Array<!proto.SGP.CoordGeodetic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SGP.CoordGeodetic, 4));
};


/**
 * @param {!Array<!proto.SGP.CoordGeodetic>} value
 * @return {!proto.SGP.SGPConstructRequest} returns this
*/
proto.SGP.SGPConstructRequest.prototype.setObserverLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.SGP.CoordGeodetic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SGP.CoordGeodetic}
 */
proto.SGP.SGPConstructRequest.prototype.addObserverLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.SGP.CoordGeodetic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SGP.SGPConstructRequest} returns this
 */
proto.SGP.SGPConstructRequest.prototype.clearObserverLocationsList = function() {
  return this.setObserverLocationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.SGPConstructResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.SGPConstructResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.SGPConstructResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SGPConstructResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    computationalId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.SGPConstructResponse}
 */
proto.SGP.SGPConstructResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.SGPConstructResponse;
  return proto.SGP.SGPConstructResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.SGPConstructResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.SGPConstructResponse}
 */
proto.SGP.SGPConstructResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComputationalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.SGPConstructResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.SGPConstructResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.SGPConstructResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SGPConstructResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComputationalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string computational_id = 1;
 * @return {string}
 */
proto.SGP.SGPConstructResponse.prototype.getComputationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SGP.SGPConstructResponse} returns this
 */
proto.SGP.SGPConstructResponse.prototype.setComputationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SGP.SGPComputeRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.SGPComputeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.SGPComputeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.SGPComputeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SGPComputeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    computationalId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    encodedTimeList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    coordType: jspb.Message.getFieldWithDefault(msg, 3, 0),
    useNoise: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.SGPComputeRequest}
 */
proto.SGP.SGPComputeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.SGPComputeRequest;
  return proto.SGP.SGPComputeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.SGPComputeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.SGPComputeRequest}
 */
proto.SGP.SGPComputeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setComputationalId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addEncodedTime(values[i]);
      }
      break;
    case 3:
      var value = /** @type {!proto.SGP.CoordType} */ (reader.readEnum());
      msg.setCoordType(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUseNoise(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.SGPComputeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.SGPComputeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.SGPComputeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SGPComputeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getComputationalId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEncodedTimeList();
  if (f.length > 0) {
    writer.writePackedUint64(
      2,
      f
    );
  }
  f = message.getCoordType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getUseNoise();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string computational_id = 1;
 * @return {string}
 */
proto.SGP.SGPComputeRequest.prototype.getComputationalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SGP.SGPComputeRequest} returns this
 */
proto.SGP.SGPComputeRequest.prototype.setComputationalId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated uint64 encoded_time = 2;
 * @return {!Array<number>}
 */
proto.SGP.SGPComputeRequest.prototype.getEncodedTimeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.SGP.SGPComputeRequest} returns this
 */
proto.SGP.SGPComputeRequest.prototype.setEncodedTimeList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SGP.SGPComputeRequest} returns this
 */
proto.SGP.SGPComputeRequest.prototype.addEncodedTime = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SGP.SGPComputeRequest} returns this
 */
proto.SGP.SGPComputeRequest.prototype.clearEncodedTimeList = function() {
  return this.setEncodedTimeList([]);
};


/**
 * optional CoordType coord_type = 3;
 * @return {!proto.SGP.CoordType}
 */
proto.SGP.SGPComputeRequest.prototype.getCoordType = function() {
  return /** @type {!proto.SGP.CoordType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.SGP.CoordType} value
 * @return {!proto.SGP.SGPComputeRequest} returns this
 */
proto.SGP.SGPComputeRequest.prototype.setCoordType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional bool use_noise = 4;
 * @return {boolean}
 */
proto.SGP.SGPComputeRequest.prototype.getUseNoise = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SGP.SGPComputeRequest} returns this
 */
proto.SGP.SGPComputeRequest.prototype.setUseNoise = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.CoordGeodetic.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.CoordGeodetic.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.CoordGeodetic} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.CoordGeodetic.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    lon: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    alt: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    encodedTime: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.CoordGeodetic}
 */
proto.SGP.CoordGeodetic.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.CoordGeodetic;
  return proto.SGP.CoordGeodetic.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.CoordGeodetic} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.CoordGeodetic}
 */
proto.SGP.CoordGeodetic.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLon(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAlt(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEncodedTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.CoordGeodetic.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.CoordGeodetic.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.CoordGeodetic} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.CoordGeodetic.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getLon();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getAlt();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getEncodedTime();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional double lat = 1;
 * @return {number}
 */
proto.SGP.CoordGeodetic.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SGP.CoordGeodetic} returns this
 */
proto.SGP.CoordGeodetic.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double lon = 2;
 * @return {number}
 */
proto.SGP.CoordGeodetic.prototype.getLon = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SGP.CoordGeodetic} returns this
 */
proto.SGP.CoordGeodetic.prototype.setLon = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double alt = 3;
 * @return {number}
 */
proto.SGP.CoordGeodetic.prototype.getAlt = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SGP.CoordGeodetic} returns this
 */
proto.SGP.CoordGeodetic.prototype.setAlt = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional uint64 encoded_time = 4;
 * @return {number}
 */
proto.SGP.CoordGeodetic.prototype.getEncodedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SGP.CoordGeodetic} returns this
 */
proto.SGP.CoordGeodetic.prototype.setEncodedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.Vector.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.Vector.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.Vector} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.Vector.toObject = function(includeInstance, msg) {
  var f, obj = {
    x: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    y: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    z: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.Vector}
 */
proto.SGP.Vector.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.Vector;
  return proto.SGP.Vector.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.Vector} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.Vector}
 */
proto.SGP.Vector.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setX(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setY(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setZ(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.Vector.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.Vector.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.Vector} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.Vector.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getX();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getY();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getZ();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
};


/**
 * optional double x = 1;
 * @return {number}
 */
proto.SGP.Vector.prototype.getX = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SGP.Vector} returns this
 */
proto.SGP.Vector.prototype.setX = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double y = 2;
 * @return {number}
 */
proto.SGP.Vector.prototype.getY = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SGP.Vector} returns this
 */
proto.SGP.Vector.prototype.setY = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double z = 3;
 * @return {number}
 */
proto.SGP.Vector.prototype.getZ = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.SGP.Vector} returns this
 */
proto.SGP.Vector.prototype.setZ = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.CoordECI.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.CoordECI.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.CoordECI} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.CoordECI.toObject = function(includeInstance, msg) {
  var f, obj = {
    encodedTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    position: (f = msg.getPosition()) && proto.SGP.Vector.toObject(includeInstance, f),
    velocity: (f = msg.getVelocity()) && proto.SGP.Vector.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.CoordECI}
 */
proto.SGP.CoordECI.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.CoordECI;
  return proto.SGP.CoordECI.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.CoordECI} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.CoordECI}
 */
proto.SGP.CoordECI.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEncodedTime(value);
      break;
    case 2:
      var value = new proto.SGP.Vector;
      reader.readMessage(value,proto.SGP.Vector.deserializeBinaryFromReader);
      msg.setPosition(value);
      break;
    case 3:
      var value = new proto.SGP.Vector;
      reader.readMessage(value,proto.SGP.Vector.deserializeBinaryFromReader);
      msg.setVelocity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.CoordECI.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.CoordECI.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.CoordECI} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.CoordECI.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEncodedTime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getPosition();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.SGP.Vector.serializeBinaryToWriter
    );
  }
  f = message.getVelocity();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.SGP.Vector.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint64 encoded_time = 1;
 * @return {number}
 */
proto.SGP.CoordECI.prototype.getEncodedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SGP.CoordECI} returns this
 */
proto.SGP.CoordECI.prototype.setEncodedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Vector position = 2;
 * @return {?proto.SGP.Vector}
 */
proto.SGP.CoordECI.prototype.getPosition = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, proto.SGP.Vector, 2));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.SGP.CoordECI} returns this
*/
proto.SGP.CoordECI.prototype.setPosition = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SGP.CoordECI} returns this
 */
proto.SGP.CoordECI.prototype.clearPosition = function() {
  return this.setPosition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SGP.CoordECI.prototype.hasPosition = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector velocity = 3;
 * @return {?proto.SGP.Vector}
 */
proto.SGP.CoordECI.prototype.getVelocity = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, proto.SGP.Vector, 3));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.SGP.CoordECI} returns this
*/
proto.SGP.CoordECI.prototype.setVelocity = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SGP.CoordECI} returns this
 */
proto.SGP.CoordECI.prototype.clearVelocity = function() {
  return this.setVelocity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SGP.CoordECI.prototype.hasVelocity = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.SolarPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.SolarPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.SolarPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SolarPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    encodedTime: jspb.Message.getFieldWithDefault(msg, 1, 0),
    vectorToSun: (f = msg.getVectorToSun()) && proto.SGP.Vector.toObject(includeInstance, f),
    vectorToSunFromSat: (f = msg.getVectorToSunFromSat()) && proto.SGP.Vector.toObject(includeInstance, f),
    isSunVisible: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.SolarPosition}
 */
proto.SGP.SolarPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.SolarPosition;
  return proto.SGP.SolarPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.SolarPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.SolarPosition}
 */
proto.SGP.SolarPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setEncodedTime(value);
      break;
    case 2:
      var value = new proto.SGP.Vector;
      reader.readMessage(value,proto.SGP.Vector.deserializeBinaryFromReader);
      msg.setVectorToSun(value);
      break;
    case 3:
      var value = new proto.SGP.Vector;
      reader.readMessage(value,proto.SGP.Vector.deserializeBinaryFromReader);
      msg.setVectorToSunFromSat(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSunVisible(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.SolarPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.SolarPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.SolarPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SolarPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEncodedTime();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getVectorToSun();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.SGP.Vector.serializeBinaryToWriter
    );
  }
  f = message.getVectorToSunFromSat();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.SGP.Vector.serializeBinaryToWriter
    );
  }
  f = message.getIsSunVisible();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional uint64 encoded_time = 1;
 * @return {number}
 */
proto.SGP.SolarPosition.prototype.getEncodedTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.SGP.SolarPosition} returns this
 */
proto.SGP.SolarPosition.prototype.setEncodedTime = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Vector vector_to_sun = 2;
 * @return {?proto.SGP.Vector}
 */
proto.SGP.SolarPosition.prototype.getVectorToSun = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, proto.SGP.Vector, 2));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.SGP.SolarPosition} returns this
*/
proto.SGP.SolarPosition.prototype.setVectorToSun = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SGP.SolarPosition} returns this
 */
proto.SGP.SolarPosition.prototype.clearVectorToSun = function() {
  return this.setVectorToSun(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SGP.SolarPosition.prototype.hasVectorToSun = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Vector vector_to_sun_from_sat = 3;
 * @return {?proto.SGP.Vector}
 */
proto.SGP.SolarPosition.prototype.getVectorToSunFromSat = function() {
  return /** @type{?proto.SGP.Vector} */ (
    jspb.Message.getWrapperField(this, proto.SGP.Vector, 3));
};


/**
 * @param {?proto.SGP.Vector|undefined} value
 * @return {!proto.SGP.SolarPosition} returns this
*/
proto.SGP.SolarPosition.prototype.setVectorToSunFromSat = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SGP.SolarPosition} returns this
 */
proto.SGP.SolarPosition.prototype.clearVectorToSunFromSat = function() {
  return this.setVectorToSunFromSat(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SGP.SolarPosition.prototype.hasVectorToSunFromSat = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool is_sun_visible = 4;
 * @return {boolean}
 */
proto.SGP.SolarPosition.prototype.getIsSunVisible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.SGP.SolarPosition} returns this
 */
proto.SGP.SolarPosition.prototype.setIsSunVisible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SGP.SGPComputeResponse.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SGP.SGPComputeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SGP.SGPComputeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SGP.SGPComputeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SGPComputeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    coordType: jspb.Message.getFieldWithDefault(msg, 1, 0),
    geodeticList: jspb.Message.toObjectList(msg.getGeodeticList(),
    proto.SGP.CoordGeodetic.toObject, includeInstance),
    eciList: jspb.Message.toObjectList(msg.getEciList(),
    proto.SGP.CoordECI.toObject, includeInstance),
    solarPositionList: jspb.Message.toObjectList(msg.getSolarPositionList(),
    proto.SGP.SolarPosition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SGP.SGPComputeResponse}
 */
proto.SGP.SGPComputeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SGP.SGPComputeResponse;
  return proto.SGP.SGPComputeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SGP.SGPComputeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SGP.SGPComputeResponse}
 */
proto.SGP.SGPComputeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.SGP.CoordType} */ (reader.readEnum());
      msg.setCoordType(value);
      break;
    case 2:
      var value = new proto.SGP.CoordGeodetic;
      reader.readMessage(value,proto.SGP.CoordGeodetic.deserializeBinaryFromReader);
      msg.addGeodetic(value);
      break;
    case 3:
      var value = new proto.SGP.CoordECI;
      reader.readMessage(value,proto.SGP.CoordECI.deserializeBinaryFromReader);
      msg.addEci(value);
      break;
    case 4:
      var value = new proto.SGP.SolarPosition;
      reader.readMessage(value,proto.SGP.SolarPosition.deserializeBinaryFromReader);
      msg.addSolarPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SGP.SGPComputeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SGP.SGPComputeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SGP.SGPComputeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SGP.SGPComputeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCoordType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getGeodeticList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.SGP.CoordGeodetic.serializeBinaryToWriter
    );
  }
  f = message.getEciList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.SGP.CoordECI.serializeBinaryToWriter
    );
  }
  f = message.getSolarPositionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.SGP.SolarPosition.serializeBinaryToWriter
    );
  }
};


/**
 * optional CoordType coord_type = 1;
 * @return {!proto.SGP.CoordType}
 */
proto.SGP.SGPComputeResponse.prototype.getCoordType = function() {
  return /** @type {!proto.SGP.CoordType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.SGP.CoordType} value
 * @return {!proto.SGP.SGPComputeResponse} returns this
 */
proto.SGP.SGPComputeResponse.prototype.setCoordType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * repeated CoordGeodetic geodetic = 2;
 * @return {!Array<!proto.SGP.CoordGeodetic>}
 */
proto.SGP.SGPComputeResponse.prototype.getGeodeticList = function() {
  return /** @type{!Array<!proto.SGP.CoordGeodetic>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SGP.CoordGeodetic, 2));
};


/**
 * @param {!Array<!proto.SGP.CoordGeodetic>} value
 * @return {!proto.SGP.SGPComputeResponse} returns this
*/
proto.SGP.SGPComputeResponse.prototype.setGeodeticList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.SGP.CoordGeodetic=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SGP.CoordGeodetic}
 */
proto.SGP.SGPComputeResponse.prototype.addGeodetic = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.SGP.CoordGeodetic, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SGP.SGPComputeResponse} returns this
 */
proto.SGP.SGPComputeResponse.prototype.clearGeodeticList = function() {
  return this.setGeodeticList([]);
};


/**
 * repeated CoordECI eci = 3;
 * @return {!Array<!proto.SGP.CoordECI>}
 */
proto.SGP.SGPComputeResponse.prototype.getEciList = function() {
  return /** @type{!Array<!proto.SGP.CoordECI>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SGP.CoordECI, 3));
};


/**
 * @param {!Array<!proto.SGP.CoordECI>} value
 * @return {!proto.SGP.SGPComputeResponse} returns this
*/
proto.SGP.SGPComputeResponse.prototype.setEciList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.SGP.CoordECI=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SGP.CoordECI}
 */
proto.SGP.SGPComputeResponse.prototype.addEci = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.SGP.CoordECI, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SGP.SGPComputeResponse} returns this
 */
proto.SGP.SGPComputeResponse.prototype.clearEciList = function() {
  return this.setEciList([]);
};


/**
 * repeated SolarPosition solar_position = 4;
 * @return {!Array<!proto.SGP.SolarPosition>}
 */
proto.SGP.SGPComputeResponse.prototype.getSolarPositionList = function() {
  return /** @type{!Array<!proto.SGP.SolarPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.SGP.SolarPosition, 4));
};


/**
 * @param {!Array<!proto.SGP.SolarPosition>} value
 * @return {!proto.SGP.SGPComputeResponse} returns this
*/
proto.SGP.SGPComputeResponse.prototype.setSolarPositionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.SGP.SolarPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SGP.SolarPosition}
 */
proto.SGP.SGPComputeResponse.prototype.addSolarPosition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.SGP.SolarPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SGP.SGPComputeResponse} returns this
 */
proto.SGP.SGPComputeResponse.prototype.clearSolarPositionList = function() {
  return this.setSolarPositionList([]);
};


/**
 * @enum {number}
 */
proto.SGP.CoordType = {
  ECI: 0,
  GEODETIC: 1
};

goog.object.extend(exports, proto.SGP);
