import './Contacts.css';
import Input from "./Input";
import Button from "./Button";
import {useEffect, useState} from "react";
import {observer} from "../index";
import React from "react";

function Contacts({create}) {
    let inputsInfo = [{
        id: 'fio_contacts',
        style: {
            width: 400,
            height: 32
        },
        name: 'Ваше ФИО:',
        class: 'smallInp',
        type: 'text'
    }, {
        id: 'email_contacts',
        style: {
            width: 400,
            height: 32
        },
        name: 'Ваш E-Mail:',
        class: 'smallInp',
        type: 'email'
    }, {
        id: 'message_contacts',
        style: {
            width: 400,
            height: 160
        },
        name: 'Ваше сообщение:',
        class: 'bigInp',
        type: 'message'
    }]

    let [fioInput, setFioInput] = useState()
    let [emailInput, setEmailInput] = useState()
    let [messageInput, setMessageInput] = useState()
    let [checkboxInput, setCheckboxInput] = useState(false)

    let contactsChange = (event, id) => {
        if (id == 'fio_contacts') {
            if (event.target.value) {
                document.querySelector(`#${id}`).classList.remove('input-bg-error')
                document.querySelector(`#Input${id}`).classList.remove('input-bg-error')
            }
            setFioInput(event.target.value)
        }
        if (id == 'email_contacts') {
            if (event.target.value) {
                document.querySelector(`#${id}`).classList.remove('input-bg-error')
                document.querySelector(`#Input${id}`).classList.remove('input-bg-error')
            }
            setEmailInput(event.target.value)
        }
        if (id == 'message_contacts') {
            if (event.target.value) {
                document.getElementById('Inputmessage_contacts').classList.remove('input-bg-error')
            }
            setMessageInput(event.target.value)
        }
	if (id == 'checkAgreement') {
            if (event.target.checked) {
                document.querySelector('#agreeSpan').style.border = '2px solid #fff'
            }
            setCheckboxInput(event.target.checked)
        }

    }

    function ValidateEmail(input) {
        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
        if (input.match(validRegex))
            return true
        return false
    }

    useEffect(() => {
        observer()
    }, [])

    let inputs = inputsInfo.map((el, i) => (
            <div key={`div${el.id}`} id={`div${el.id}`} className="divInput">
                <Input id={el.id} change={contactsChange} style={el.style} type={el.type} classN={el.class} key={i}
                       name={el.name}></Input>
                <p key={`p${el.id}`} id={`error_text${el.id}`} className={"error_text"}> Поле обязательно для заполнения</p>
            </div>
        )
    )

    return (
        <div className="Contacts">
            <p className="ContactsText1">КОНТАКТЫ</p>
            <p className="ContactsText2">Связь с нами</p>
            <div className="ContactsTextAndForm">
                <div className="ContactsText">
                    <p className="ContactsText3">Наши контакты:</p>
                    <p className="ContactsText4">
                        <div>
                            Адрес:<br/>123458, Москва, ул. Таллинская, д.34
                        </div>
                        <span className='nth'>
                            Почта:<br/>dabrameshin@hse.ru
                        </span>
                        <span className="nth">
                            Контактный телефон:<br/>+7(495)772-95-90 *15130
                        </span>
                    </p>
                </div>
                <form className="ContactsForm">
                    <p className="ContactsFormText">Связаться с нами:</p>
                    {inputs}
	            <div className="divCheck">
                        <input className="checkbox-effect checkbox-effect-5" type="checkbox" id="checkAgreement"
                            onChange={e => { contactsChange(e, "checkAgreement") }} name="checkAgreement"></input>
                        <label className="AgreeLabel ContactsText4" htmlFor="checkAgreement"><span id="agreeSpan"></span>Даю согласие на обработку <a target="_blank" href="https://www.hse.ru/data_protection_regulation">персональных данных.</a></label>
                    </div>
                    {(fioInput && emailInput && messageInput && checkboxInput && ValidateEmail(emailInput))
                        ? <Button validate={true} create={() => {
                            create()
			    document.getElementById('requestDiv').style.background = 'transparent'
                            Array.from(document.getElementsByClassName('smallInputs')).map((i) => {
                                document.querySelector(`#${i.id}`).classList.remove('input-bg-error')
                                document.querySelector(`#Input${i.id}`).classList.remove('input-error')
                                document.querySelector(`#Input${i.id}`).classList.remove('input-bg-error')
                                document.querySelector(`#Input${i.id}`).style.marginBottom = '20px'
                                document.querySelector(`#error_text${i.id}`).style.display = 'none'
                            })
                            document.getElementById('Inputmessage_contacts').classList.remove('input-error')
                            document.getElementById('Inputmessage_contacts').classList.remove('input-bg-error')
                            document.getElementById('Inputmessage_contacts').style.marginBottom = '20px'
                            document.querySelector('#error_textmessage_contacts').style.display = 'none'

                        }
                        } id="contact_button" name="ОТПРАВИТЬ"></Button>
                        : <Button validate={false} validateError={() => {
                            Array.from(document.getElementsByClassName('smallInputs')).map((i) => {
                                if (!(i.value) || (i.id == 'email_contacts' && !ValidateEmail(i.value))) {
                                    document.querySelector(`#${i.id}`).classList.add('input-bg-error')
                                    document.querySelector(`#Input${i.id}`).classList.add('input-error')
                                    document.querySelector(`#Input${i.id}`).classList.add('input-bg-error')
                                    document.querySelector(`#Input${i.id}`).style.marginBottom = '0px'
                                    document.querySelector(`#error_text${i.id}`).style.display = 'block'
                                } else {
                                    document.querySelector(`#${i.id}`).classList.remove('input-bg-error')
                                    document.querySelector(`#Input${i.id}`).classList.remove('input-error')
                                    document.querySelector(`#Input${i.id}`).classList.remove('input-bg-error')
                                    document.querySelector(`#Input${i.id}`).style.marginBottom = '20px'
                                    document.querySelector(`#error_text${i.id}`).style.display = 'none'
                                }
                                if (i.id == 'email_contacts' && !ValidateEmail(i.value))
                                    if (i.value)
                                        document.querySelector(`#error_text${i.id}`).innerHTML = 'Пожалуйста, введите корректный email-адрес'
                                    else
                                        document.querySelector(`#error_text${i.id}`).innerHTML = 'Поле обязательно для заполнения'
                            })
                            if (!document.getElementById('Inputmessage_contacts').value) {
                                document.getElementById('Inputmessage_contacts').classList.add('input-error')
                                document.getElementById('Inputmessage_contacts').classList.add('input-bg-error')
                                document.getElementById('Inputmessage_contacts').style.marginBottom = '0px'
                                document.querySelector('#error_textmessage_contacts').style.display = 'block'
                            } else {
                                document.getElementById('Inputmessage_contacts').classList.remove('input-error')
                                document.getElementById('Inputmessage_contacts').classList.remove('input-bg-error')
                                document.getElementById('Inputmessage_contacts').style.marginBottom = '20px'
                                document.querySelector('#error_textmessage_contacts').style.display = 'none'
                            }

		            if (!document.querySelector('#checkAgreement').checked) {
                                document.querySelector('#agreeSpan').style.border = '2px solid #FB4B4B'
                            }
                            else {
                                document.querySelector('#agreeSpan').style.border = '2px solid #fff'
                            }

                        }} id="contact_button" name="ОТПРАВИТЬ"></Button>
                    }

                </form>
            </div>
        </div>
    );
}

export default Contacts;
