import './EmulatorButton.css';
import React, {useRef} from "react";

function EmulatorButton({create, curr, switchPoint}) {

    let addConfig = useRef()

    if (curr == 0)
        return (
            <div ref={addConfig} onMouseDown={() => {
                addConfig.current.style.background = '#084479'
            }} onMouseUp={() => {
                addConfig.current.style.background = '#005AAB'
            }} onMouseMove={(e) => {
                if (e.target == addConfig.current)
                    addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100}%, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 + 10}%)`
                else {
                    addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100}%, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 + 10}%)`
                }
            }} onMouseLeave={() => {
                addConfig.current.style.background = `#005AAB`
            }} id="continue_button" onClick={switchPoint} className="EmulatorButton">
                <div className="ButtonText">
                    ПРОДОЛЖИТЬ
                </div>
            </div>
        )
    else
        return (
            <div ref={addConfig} onMouseDown={() => {
                addConfig.current.style.background = '#084479'
            }} onMouseUp={() => {
                addConfig.current.style.background = '#005AAB'
            }} onMouseMove={(e) => {
                if (e.target == addConfig.current)
                    addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100}%, #005AAB ${e.nativeEvent.offsetX / addConfig.current.offsetWidth * 100 + 10}%)`
                else {
                    addConfig.current.style.background = `linear-gradient(90deg, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 - 15}%, #81BFF8 ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100}%, #005AAB ${(e.nativeEvent.offsetX + (addConfig.current.offsetWidth - e.target.offsetWidth) / 2) / addConfig.current.offsetWidth * 100 + 10}%)`
                }
            }} onMouseLeave={() => {
                addConfig.current.style.background = `#005AAB`
            }} id="b64" onClick={create} className="EmulatorButton">
                <div className="ButtonText">
                    ОТПРАВИТЬ
                </div>
            </div>
        );
}


export default EmulatorButton;
