import './SideMenu.css';
import SideMenuPoint from "./SideMenuPoint";
import homeImg from '../images/home.svg'
import vsvsImg from '../images/planet.svg'
import calcImg from '../images/calc.svg'
import emulImg from '../images/emul.svg'
import contImg from '../images/contact.svg'
import HSE from '../images/HSE2.svg'
import exitImg from '../images/exitSideMenu.svg'
import React from "react";

let SideMenu = React.forwardRef(({exit, currentMenu}, ref) => {

        let points = ['Главная', 'ВКВС', 'AIS-ADSB', 'Расчёты', 'Эмулятор', 'Контакты']

        let swipeInfo = {
            x: '',
            y: '',
            time: ''
        }

        let onTouchStart = (e) => {
            swipeInfo.x = e.touches[0].screenX
            swipeInfo.y = e.touches[0].screenY
            swipeInfo.time = e.timeStamp
            //console.log(e)
        }

        let onTouchEnd = (e) => {
            let x = swipeInfo.x - e.changedTouches[0].screenX
            let y = Math.abs(swipeInfo.y - e.changedTouches[0].screenY) * 2
            let ti = e.timeStamp - swipeInfo.time
            //console.log(e)
            if (x > 50 && x > y && ti < 500) {
                exit(currentMenu)
            }
            //console.log(x, y, ti)
        }

        return (
            <div ref={ref} style={{}} onTouchStart={onTouchStart} onTouchEnd={onTouchEnd}
                 className="SideMenu">
                <img className="SideMenuHSE" src={HSE} alt=""/>
                <p className="SideMenuText">ВИРТУАЛЬНЫЙ КОСМОС</p>
                <SideMenuPoint click={exit} num={0} cur={currentMenu} pathName="" img={homeImg}
                               name={points[0]}></SideMenuPoint>
                ////
                <SideMenuPoint click={exit} num={5} cur={currentMenu} pathName="ais_adsb/" img={homeImg}
                               name={points[5]}></SideMenuPoint>
                
                ////
                <SideMenuPoint click={exit} num={1} cur={currentMenu} pathName="vsvs" img={vsvsImg}
                               name={points[1]}></SideMenuPoint>
                <SideMenuPoint click={exit} num={2} cur={currentMenu} pathName="igrf" img={calcImg}
                               name={points[2]}></SideMenuPoint>
                <SideMenuPoint click={exit} num={3} cur={currentMenu} pathName="emulator" img={emulImg}
                               name={points[3]}></SideMenuPoint>
                <SideMenuPoint click={exit} num={4} cur={currentMenu} pathName="contacts" img={contImg}
                               name={points[4]}></SideMenuPoint>
                <img onClick={exit} className="exitSideMenu" src={exitImg} alt=""/>
            </div>
        );
    }
)

export default SideMenu;
